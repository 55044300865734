import { useState } from 'react';
import { useNavigate } from 'react-router';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import OptionsForMcq from './OptionsForMcq.component';

const PreviewTrueFalse = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = (e) => {
    e.preventDefault();
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={(e) => handleGoToEditClick(e)}
      handleBackToEdit={handleBackToEditClick}
    >
      <OptionsForMcq
        data = {currentQuestion}
        showAnswer={showAnswer || localShowAnswer}
        multiResponse={false}
      />
    </PreviewWrapper>
  );
};

export default PreviewTrueFalse;
