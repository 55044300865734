import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { ROLE } from '../utils/roles';
import CreateTagHierarchy from '../pages/Tags/CreateTagHierarchy';
import { NotFound } from '../pages/NotFound';
import EditQuestion from '../pages/questions/EditQuestion';
import Loading from '../components/Loading';

const CreateUser = lazy(() => import('../pages/CreateUser'));
const UserManagement = lazy(() => import('../pages/UserManagement'));
const EditUser = lazy(() => import('../pages/CreateUser/EditUser'));

const NewPassword = lazy(() => import('../pages/NewPassword'));

const ForgetPassword = lazy(() => import('../pages/ForgetPassword'));

const ClientAdminDashboard = lazy(
  () => import('../pages/clientAdmin/ClientAdminDashboard')
);
const AuthorDashboard = lazy(
  () => import('../pages/Author.js/AuthorDashboard')
);

const WelcomeBack = lazy(() => import('../pages/clientAdmin/WelcomeBack'));
const QuestionsDelivered = lazy(
  () => import('../pages/questions/QuestionsDelivered')
);
const QuestionListing = lazy(
  () => import('../pages/questions/QuestionListing')
);

const InsightsQuestions = lazy(
  () => import('../pages/insights/InsightsQuestions')
);
const InsightsAssessments = lazy(
  () => import('../pages/insights/InsightsAssessments')
);
const GradeListing = lazy(() => import('../pages/grade/GradeListing'));
const CreateGrade = lazy(() => import('../pages/grade/CreateGrade'));
const EditGrade = lazy(() => import('../pages/grade/EditGrade'));
// const BulkUpload = lazy(() => import('../pages/grade/BulkUpload'));

const SubjectListing = lazy(() => import('../pages/subject/SubjectListing'));
const UploadSubjectStatus = lazy(
  () => import('../pages/subject/UploadSubjectStatus')
);
const CreateSubject = lazy(() => import('../pages/subject/CreateSubject'));
const EditSubject = lazy(() => import('../pages/subject/EditSubject'));

const AddQuestionBank = lazy(
  () => import('../pages/AuthorQuestion/AddQuestionBank')
);
const AddQuestion = lazy(
  () => import('../pages/AuthorQuestion/AddQuestion/AddQuestion')
);
const UploadGradeStatus = lazy(
  () => import('../pages/grade/UploadGradeStatus')
);
const AuthorQuestionListing = lazy(
  () => import('../pages/AuthorQuestion/QuestionListing')
);

const Assessments = lazy(() => import('../pages/Assessments'));
const AssessmentDelivered = lazy(
  () => import('../pages/Assessments/AssessmentDelivered')
);
const AssessmentListing = lazy(
  () => import('../pages/Assessments/AssessmentListing')
);
const EditAssessment = lazy(
  () => import('../pages/Assessments/EditAssessment')
);

const PreviewAssessment = lazy(
  () => import('../pages/Assessments/PreviewAssessment/PreviewAssessment')
);

const TagsTypes = lazy(() => import('../pages/Tags/TagsTypes'));
const CreateTagType = lazy(() => import('../pages/Tags/CreateTagType'));
const TagsHierarchy = lazy(() => import('../pages/Tags/TagsHierarchy'));
const EditTagType = lazy(() => import('../pages/Tags/EditTagType'));
const EditTagHierarchy = lazy(() => import('../pages/Tags/EditTagHierarchy'));
const Reports = lazy(() => import('../pages/Reports'));
const ScoringModule = lazy(() => import('../pages/ScoringModule'));
const DocumentInventory = lazy(() => import('../pages/DocumentInventory'));
const UserProfile = lazy(() => import('../pages/UserProfil'));
const EditProfile = lazy(() => import('../pages/UserProfil/EditProfile'));
const AccessDenied = lazy(() => import('../pages/AccessDenied'));
const Login = lazy(() => import('../pages/Login'));
const BulkUpload = lazy(() => import('../pages/BulkUpload/BulkUpload'));
const DragDropCloze = lazy(
  () => import('../pages/AuthorQuestion/DragDropCloze')
);
const EditSubQuestion = lazy(
  () => import('../pages/EditSubQuestion/EditSubQuestion')
);
const Organisations = lazy(() => import('../pages/organisations/index'));

{/* Obs-Service Routes */}

const AssessmentsPreview = lazy(() => import('../obsService/AssessmentsPreview'));
const CurrentPreview = lazy(() => import('../obsService/CurrentPreview'));
const PlayerPreview = lazy(() => import('../obsService/PlayerPreview'));
const ObsReports = lazy(() => import('../obsService/Reports'));
const StudentReports = lazy(() => import('../obsService/StudentReports'));
const TeacherReports = lazy(() => import('../obsService/TeacherReports'));
const ObsAssessments = lazy(() => import('../obsService/Assessments'));
const ObeCreateQuestion = lazy(() => import('../pages/AuthorQuestion/AddQuestion/ObeCreateQuestion'));


const AppRoutes = () => {
  // @ts-ignore
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path='/organisations' element={
            <Organisations />
          } />

        {/* For Client Admin */}
        <Route
          path="/client-dashboard"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <ClientAdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute roles={[ROLE.Teacher, ROLE.Admin, ROLE.Editor]}>
              <AuthorDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-assessment"
          element={
            <ProtectedRoute roles={[ROLE.Teacher, ROLE.Admin, ROLE.Editor]}>
              <Assessments mode={'create'} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-assessment/:assessmentId"
          element={
            <ProtectedRoute roles={[ROLE.Teacher, ROLE.Admin, ROLE.Editor]}>
              <EditAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment/:assessmentId"
          element={
            <ProtectedRoute roles={[ROLE.Teacher, ROLE.Admin, ROLE.Editor]}>
              <PreviewAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/welcome"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <WelcomeBack />
            </ProtectedRoute>
          }
        />
        {/* Questions */}
        <Route
          path="/questions-delivered"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <QuestionsDelivered />
            </ProtectedRoute>
          }
        />
        <Route
          path="/questions-listing"
          element={
            <ProtectedRoute roles={[ROLE.Admin]}>
              <QuestionListing />
            </ProtectedRoute>
          }
        />
        {/* Assessment */}
        <Route
          path="/assessments-delivered"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <AssessmentDelivered />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assessment-listing"
          element={
            <ProtectedRoute roles={[ROLE.Teacher, ROLE.Admin, ROLE.Editor]}>
              <AssessmentListing />
            </ProtectedRoute>
          }
        />
        {/* Insights */}
        <Route path="/insights-questions" element={<InsightsQuestions />} />
        <Route path="/insights-assessments" element={<InsightsAssessments />} />
        {/* Grade */}
        <Route path="/grade-listing" element={<GradeListing />} />
        <Route
          path="/create-grade"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <CreateGrade />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-grade/:id"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <EditGrade />
            </ProtectedRoute>
          }
        />
        {/* <Route path="/bulk-upload" element={<BulkUpload />} /> */}
        <Route path="/upload-grade-status" element={<UploadGradeStatus />} />
        {/* Subject */}
        <Route path="/subject-listing" element={<SubjectListing />} />
        <Route
          path="/upload-subject-status"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <UploadSubjectStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-subject"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <CreateSubject />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-subject/:id"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <EditSubject />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/subject-bulk-upload"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <SubjectBulkUpload />
            </ProtectedRoute>
          }
        /> */}

        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/password/reset/:token" element={<NewPassword />} />
        <Route
          path="/create-user"
          element={
            <ProtectedRoute roles={[ROLE.Manager, ROLE.Admin]}>
              <CreateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route path="/edit-user/:id" element={<EditUser />} />

        {/* Question */}
        <Route
          path="/add-question-bank"
          element={
            <ProtectedRoute roles={[ROLE.Teacher, ROLE.Admin, ROLE.Editor]}>
              <AddQuestionBank />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-question"
          element={
            <ProtectedRoute roles={[ROLE.Teacher, ROLE.Admin, ROLE.Editor]}>
              <AddQuestion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/author-question-listing"
          element={<AuthorQuestionListing />}
        />

        <Route
          path="/edit-question/:id"
          element={
            <ProtectedRoute roles={[ROLE.Admin]}>
              <EditQuestion />
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-subquestion/:id"
          element={
            <ProtectedRoute roles={[ROLE.Admin]}>
              <EditSubQuestion />
            </ProtectedRoute>
          }
        />

        {/* For Tags */}
        <Route
          path="/tags-types"
          element={
            <ProtectedRoute roles={[ROLE.Admin, ROLE.Teacher, ROLE.Editor]}>
              <TagsTypes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-tag-type"
          element={
            <ProtectedRoute roles={[ROLE.Admin, ROLE.Teacher, ROLE.Editor]}>
              <CreateTagType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tags-hierarchy"
          element={
            <ProtectedRoute roles={[ROLE.Admin, ROLE.Teacher, ROLE.Editor]}>
              <TagsHierarchy />
            </ProtectedRoute>
          }
        />

        <Route
          path="/create-tag-hierarchy"
          element={
            <ProtectedRoute roles={[ROLE.Admin, ROLE.Teacher, ROLE.Editor]}>
              <CreateTagHierarchy />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit-tag-type/:id"
          element={
            <ProtectedRoute roles={[ROLE.Admin, ROLE.Teacher, ROLE.Editor]}>
              <EditTagType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-tag-hierarchy/:id"
          element={
            <ProtectedRoute roles={[ROLE.Admin, ROLE.Teacher, ROLE.Editor]}>
              <EditTagHierarchy />
            </ProtectedRoute>
          }
        />

        {/* For Reports */}
        <Route
          path="/reports"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <Reports />
            </ProtectedRoute>
          }
        />
        {/* For Scoring Module */}
        <Route
          path="/scoring-module"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <ScoringModule />
            </ProtectedRoute>
          }
        />
        {/* For Scoring Module */}
        <Route
          path="/document-inventory"
          element={
            <ProtectedRoute roles={[ROLE.Manager]}>
              <DocumentInventory />
            </ProtectedRoute>
          }
        />
        {/* User profile*/}
        <Route
          path="/user-profile"
          element={
            <ProtectedRoute
              roles={[ROLE.Manager, ROLE.Teacher, ROLE.Admin, ROLE.Editor]}
            >
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <ProtectedRoute
              roles={[ROLE.Manager, ROLE.Teacher, ROLE.Admin, ROLE.Editor]}
            >
              <EditProfile />
            </ProtectedRoute>
          }
        />

        {/* test login single  screen*/}
        <Route path="/manager-login" element={<Login />} />
        <Route path="/admin-login" element={<Login />} />
        <Route path="/superadmin-login" element={<Login />} />

        <Route path="/" element={<Navigate to="/admin-login" />} />
        <Route path="null-login" element={<Navigate to="/admin-login" />} />

        <Route path="/access-denied" element={<AccessDenied />} />
        {/* Bulk Upload */}
        <Route path="/bulk-upload/:type" element={<BulkUpload />} />
        {/* Wrong path */}
        <Route path="*" element={<NotFound />} />
        <Route path="/not-found" element={<NotFound />} />

        <Route path="/fill-blanks" element={<DragDropCloze />} />


          {/* Obs-Service Routes */}
          <Route
              path="/obs-service/views/:id"
              element={
                  <AssessmentsPreview />
              }
          />
          <Route
              path="/obs-service/views"
              element={
                  <AssessmentsPreview />
              }
          />
          <Route
              path="/obs-service/current-preview"
              element={
                  <CurrentPreview />
              }
          />
          <Route
              path="/obs-service/player/:id"
              element={
                  <PlayerPreview />
              }
          />
          <Route
              path="/obs-service/create-assessments"
              element={
                  <ObsAssessments />
              }
          />
          <Route
              path="/obs-service/obe-question"
              element={
                  <ObeCreateQuestion />
              }
          />
          <Route
              path="/obs-service/reports/:id"
              element={
                  <ObsReports />
              }
          />
          <Route
              path="/obs-service/student-reports/:id"
              element={
                  <StudentReports />
              }
          />
          <Route
              path="/obs-service/teacher-reports/:id"
              element={
                  <TeacherReports />
              }
          />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
