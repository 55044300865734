import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import {
  fetchAssessmentFailure,
  fetchAssessmentRequest,
  fetchAssessmentSuccess, fetchReportsFailure, fetchReportsRequest, fetchReportsSuccess, submitAssessmentFailure,
  submitAssessmentRequest, submitAssessmentSuccess
} from "../../obsService/actions";

interface IPreview {
  assessment: Array<any>;
  reportsData: Array<any>;
  allAnswers?: Array<{
    subQuestionId: number;
    selectedAnswer?: any;
    valid_response?: { value: { selectedAnswer: string | null | Array<any> | boolean } };
  }>;
  isLoading?: boolean;
  time: string | number;
  isTimerActive?: boolean;
  displayPreview: boolean; // Added displayPreview state
  currentlySelectedQuestions: any[];
}

const initialState: IPreview = {
  assessment: [],
  reportsData: [],
  isLoading: false,
  time: null,
  isTimerActive: false,
  displayPreview: false, // Initialize displayPreview
  currentlySelectedQuestions: [],
};

const PreviewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    setTimer: (state, action) => {
      state.time = action.payload;
    },
    startTimer: (state) => {
      state.isTimerActive = true;
    },
    pauseTimer: (state) => {
      state.isTimerActive = false;
    },
    getAssessmentIsloading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAssessment: (state, action) => {
      state.assessment = action.payload;
    },
    cleanAssessment: (state) => {
      state.assessment = [];
    },
    cleanAllAnswers: (state) => {
      console.log('[previewslice.ts] clean all answers')
      state.allAnswers = [];
      state.isTimerActive = false;
    },
    cleanReports: (state) => {
      state.reportsData = [];
    },
    setCurrentSelectedQuestions: (state, action) => {
      // action.payload;
      state.currentlySelectedQuestions = [...action.payload];
    },
    removeCurrentSelectedQuestions: (state, action) => {
      const {questionIdToBeRemoved} = action.payload;
      state.currentlySelectedQuestions = state.currentlySelectedQuestions.filter((question) => question.id != questionIdToBeRemoved)
    },
    resetCurrentSelectedQuestions: (state) => {
      state.currentlySelectedQuestions = [];
    },
    setMultipleSubQuestionAnswer: (state, action) => {
      const { subQuestionId, selectedAnswer } = action.payload;
      if (!state.allAnswers) {
        state.allAnswers = [];
      }
      const existingAnswerIndex = state.allAnswers.findIndex(
          (answer) => answer.subQuestionId === subQuestionId
      );
      if (existingAnswerIndex !== -1) {
        // Do nothing for now
      } else {
        state.allAnswers.push({ subQuestionId, valid_response: { value: selectedAnswer } });
      }
    },
    setNewValueForMathAnswers: (state, action) => {
      const { newlyAddedAnswer, subQuestionId } = action.payload;
      const existingAnswer = state.allAnswers?.find(
          (answer) => answer.subQuestionId === subQuestionId
      );

      if (existingAnswer) {
        state.allAnswers = state.allAnswers.map((ans) =>
            ans?.subQuestionId === existingAnswer.subQuestionId
                ? {
                  ...ans,
                  // @ts-ignore
                  valid_response: ans.valid_response?.filter((v: any) => v?.id !== newlyAddedAnswer.id).concat({ ...newlyAddedAnswer }),
                }
                : ans
        );
      } else {
        state.allAnswers = state.allAnswers || [];
        // @ts-ignore
        state.allAnswers.push({ subQuestionId, valid_response: [newlyAddedAnswer] });
      }
    },
    setSubQuestionAnswer: (state, action) => {
      const { subQuestionId, selectedAnswer } = action.payload;
      if (!state.allAnswers) {
        state.allAnswers = [];
      }
      const existingAnswerIndex = state.allAnswers.findIndex(
          (answer) => answer.subQuestionId === subQuestionId
      );
      if (existingAnswerIndex !== -1) {
        state.allAnswers[existingAnswerIndex] = {
          ...state.allAnswers[existingAnswerIndex],
          valid_response: {
            value: selectedAnswer,
          },
        };
      } else {
        state.allAnswers.push({ subQuestionId, valid_response: { value: selectedAnswer } });
      }
    },
    setSubQuestionAnswerOld: (state, action) => {
      const { subQuestionId, selectedAnswer } = action.payload;
      // console.log('from preview slice', subQuestionId, selectedAnswer);

      if (!state.allAnswers) {
        state.allAnswers = [];
      }

      const existingAnswerIndex = state.allAnswers.findIndex(
          (answer) => answer.subQuestionId === subQuestionId
      );

      if (existingAnswerIndex !== -1) {
        // Modify the draft directly, don't return a new object
        state.allAnswers[existingAnswerIndex].selectedAnswer = selectedAnswer;
      } else {
        // Modify the draft directly, don't return a new object
        state.allAnswers.push({ subQuestionId, selectedAnswer });
      }
    },
    setDisplayPreview: (state) => {
      state.displayPreview = true;
    },
    setClosePreview: (state) => {
      state.displayPreview = false;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAssessmentRequest, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchAssessmentSuccess, (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.assessment = action.payload;
        })
        .addCase(fetchAssessmentFailure, (state) => {
          state.isLoading = false;
        })
        .addCase(submitAssessmentRequest, (state) => {
          state.isLoading = true;
        })
        .addCase(submitAssessmentSuccess, (state) => {
          state.isLoading = false;
        })
        .addCase(submitAssessmentFailure, (state) => {
          state.isLoading = false;
        })
        .addCase(fetchReportsRequest, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchReportsSuccess, (state, action: PayloadAction<any>) => {
          state.reportsData = action.payload;
          state.isLoading = false;
        })
        .addCase(fetchReportsFailure, (state) => {
          state.isLoading = false;
          state.reportsData = [];
        });
  },
});

export const {
  setTimer,
  startTimer,
  pauseTimer,
  getAssessmentIsloading,
  setAssessment,
  cleanAssessment,
  setSubQuestionAnswer,
  setNewValueForMathAnswers,
  setSubQuestionAnswerOld,
  setMultipleSubQuestionAnswer,
  cleanAllAnswers,
  cleanReports,
  setDisplayPreview,
  setClosePreview,
  setCurrentSelectedQuestions,
  removeCurrentSelectedQuestions,
  resetCurrentSelectedQuestions,
} = PreviewSlice.actions;

export default PreviewSlice.reducer;
