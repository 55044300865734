import React from 'react';

interface RadioInputProps {
  isCheckBox?: boolean;
  isActive: boolean;
  handleItemClicked: (id: string) => void;
  item: any;
  children: React.ReactNode;
  otherStyle?: React.CSSProperties;
  label?: string;
}

const RadioInput: React.FC<RadioInputProps> = ({
  isCheckBox = false, 
  isActive,
  handleItemClicked,
  item,
  children,
  otherStyle = {},
  label,
}) => {
  return (
    <div className="flex items-center">
      {label && (
        <div
          className={`h-[60%] w-12 rounded-md flex items-center justify-center 
                    border-r-gray-500 text-white
                    ${isActive ? 'bg-[#de2128] opacity-90' : 'bg-gray-500'}
                    `}
        >
          {label}
        </div>
      )}
      <div
        id="radio"
        className="mt-5 mb-5 p-5 flex items-center gap-3"
        style={{
          minHeight: '45px',
          border: '1px solid #eee',
          borderRadius: '6px',
          ...otherStyle,
        }}
      >
        <input
          className="bg-[#DE2128]"
          type={isCheckBox ? 'checkbox' : 'radio'}
          checked={isActive}
          onChange={() => handleItemClicked(item.id)} // simplified onChange
        />
        {children}
      </div>
    </div>
  );
};

export default RadioInput;
