import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {FaCheck, FaTimes} from 'react-icons/fa';
import {ShadingCell} from './ShadingCell';
import {ShadingGridProps, GridContainerProps} from './types';
import {toast} from "react-toastify";
import {
    isShaded,
    isPossibleResponse,
    isCorrectByLocation,
    isCorrectByCount,
    getCorrectCellsByCount,
    isSelected
} from "../../../AuthorQuestion/HighlightDrawing/Shading/utils/gridHelpers";

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.$columnCount}, 1fr);
  width: fit-content;
  margin: 0 auto;
  background-color: #35363a;
  ${props => props.$style && `
        border-left: ${props.$style.left?.border_width}px ${props.$style.left?.border_style} ${props.$style.left?.border_color};
        border-top: ${props.$style.top?.border_width}px ${props.$style.top?.border_style} ${props.$style.top?.border_color};
        border-right: ${props.$style.right?.border_width}px ${props.$style.right?.border_style} ${props.$style.right?.border_color};
        border-bottom: ${props.$style.bottom?.border_width}px ${props.$style.bottom?.border_style} ${props.$style.bottom?.border_color};
    `}
`;

const ShadingGrid: React.FC<ShadingGridProps> = ({
                                                     rowCount,
                                                     columnCount,
                                                     cellWidth,
                                                     cellHeight,
                                                     shadedCells,
                                                     hideCells,
                                                     lockShadedCells,
                                                     possibleResponses,
                                                     correctAnswer,
                                                     showAnswer,
                                                     image,
                                                     title,
                                                     canvasStyle,
                                                     onCellClick,
                                                     isHoverState,
                                                     maxSelection
                                                 }) => {

    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', correctAnswer, hideCells)
    console.log(rowCount,
        columnCount,
        cellWidth,
        cellHeight,
        shadedCells,
        lockShadedCells,
        possibleResponses,
        correctAnswer,
        showAnswer,
        image,
        canvasStyle,
        onCellClick,
        isHoverState,
        maxSelection)
    const [selectedCells, setSelectedCells] = useState<[number, number][]>(possibleResponses);
    const [localPossibleResponses, setLocalPossibleResponses] = useState<[number, number][]>(
        possibleResponses
    );

    // Set selected cells when showAnswer is true
    useEffect(() => {
        if (showAnswer && correctAnswer) {
            if ('method' in correctAnswer && correctAnswer.method === 'byLocation') {
                setSelectedCells(correctAnswer.value as [number, number][]);
            } else if ('method' in correctAnswer && correctAnswer.method === 'byCount') {
                const targetCount = parseInt(correctAnswer.value as string, 10);
                setSelectedCells(selectedCells.slice(0, targetCount));
            } else if (Array.isArray(correctAnswer)) {
                setSelectedCells(correctAnswer);
            }
        }
    }, [showAnswer, correctAnswer]);

    const isShaded = (row: number, col: number) =>
        shadedCells.some(([r, c]) => r === row && c === col);

    const isPossibleResponse = (row: number, col: number) =>
        localPossibleResponses.some(([r, c]) => r === row && c === col);

    // Handle both byLocation and byCount methods
    // Correct cells dynamically calculated for byCount
    const correctCellsByCount = useMemo(() => {
        if (correctAnswer && 'method' in correctAnswer && correctAnswer.method === 'byCount') {
            const targetCount = parseInt(correctAnswer.value as string, 10);
            return getCorrectCellsByCount(rowCount, columnCount, targetCount, []);
        }
        return [];
    }, [correctAnswer, rowCount, columnCount]);

    // Preserve selected cells and determine correctness
    useEffect(() => {
        if (showAnswer && correctAnswer) {
            if ('method' in correctAnswer && correctAnswer.method === 'byLocation') {
                setSelectedCells(correctAnswer.value as [number, number][]);
            } else if ('method' in correctAnswer && correctAnswer.method === 'byCount') {
                setSelectedCells(correctCellsByCount);
            }
        }
    }, [showAnswer, correctAnswer, correctCellsByCount]);

    // Determine if a cell is correct
    const isCorrect = useMemo(() => (row: number, col: number): boolean => {
        if (!correctAnswer) return false;

        if ('method' in correctAnswer && correctAnswer.method === 'byLocation') {
            return correctAnswer.value.some(([r, c]) => r === row && c === col);
        } else if ('method' in correctAnswer && correctAnswer.method === 'byCount') {
            return correctCellsByCount.some(([r, c]) => r === row && c === col);
        }
        return false;
    }, [correctAnswer, correctCellsByCount]);

    const isSelected = (row: number, col: number) =>
        selectedCells.some(([r, c]) => r === row && c === col);

    const handleItemClick = (row: number, column: number) => {
        if (showAnswer) return;

        const isShaded = shadedCells.some(([r, c]) => r === row && c === column);
        const isLocked = isShaded && lockShadedCells; // Locked shaded cells
        // Check if the clicked cell is in the initial possible responses
        const isInPossibleResponses = localPossibleResponses.some(([r, c]) => r === row && c === column);


        // Handle locked shaded cells: Allow toggling selection but don't change shading
        if (isLocked) {
            console.log(`Cell at (${row}, ${column}) is locked and cannot be unshaded.`);
            setSelectedCells(prev => {
                const alreadySelected = prev.some(([r, c]) => r === row && c === column);
                if (alreadySelected) {
                    // Deselect the cell
                    return prev.filter(([r, c]) => !(r === row && c === column));
                } else {
                    // Select the cell
                    return [...prev, [row, column]];
                }
            });
            return; // Exit early without modifying the shading state
        }
        // Handle shaded and unlocked cells (this is the case where the user clicks a shaded but unlocked cell)
        if (isShaded && !isLocked) {
            console.log('Shaded cell clicked, toggling selection...');
            if (isInPossibleResponses) {
                setSelectedCells(prev => {
                    const alreadySelected = prev.some(([r, c]) => r === row && c === column);
                    if (alreadySelected) {
                        // Deselect the cell (remove it from the selection)
                        const updatedSelection = [...prev];
                        const index = updatedSelection.findIndex(([r, c]) => r === row && c === column);
                        updatedSelection.splice(index, 1); // Remove the cell
                        return updatedSelection;
                    } else {
                        // Select the cell (add it to the selection)
                        return [...prev, [row, column]]; // Add the cell
                    }
                });
            }
        }

        // For non-locked or unshaded cells, proceed with the normal selection toggling
        const indexPair: [number, number] = [row, column];
        setSelectedCells(prev => {
            const alreadySelected = prev.some(([r, c]) => r === row && c === column);
            if (alreadySelected) {
                // Deselect the cell
                console.log(`Cell at (${row}, ${column}) deselected.`);
                return prev.filter(([r, c]) => !(r === row && c === column));
            } else {
                console.log(prev.length , maxSelection)
                if (typeof maxSelection === 'number' && prev.length >= maxSelection) {
                    console.log('Maximum selection limit reached.');
                    toast.warning('Maximum selection limit reached.!');
                    return prev; // Do not allow more selections
                }
                console.log(`Cell at (${row}, ${column}) selected.`);
                return [...prev, indexPair];
            }
        })

        if (onCellClick) {
            onCellClick(row, column);
        }
    };

    console.log(selectedCells, localPossibleResponses)

    return (
        <GridContainer
            $columnCount={columnCount}
            $style={canvasStyle}
        >
            {Array.from({length: rowCount * columnCount}).map((_, index) => {
                const row = Math.floor(index / columnCount);
                const col = index % columnCount;

                // If cell is in hideCells, render it with white background and disable click
                const isHidden = hideCells?.some(([r, c]) => r === row && c === col);

                const shaded = isShaded(row, col);
                const selected = isPossibleResponse(row, col) || isSelected(row, col);
                const correct = showAnswer && isCorrect(row, col);
                const incorrect = showAnswer && selected && !correct; // Fixed incorrect logic here
                const isLocked = shaded && lockShadedCells;
                const shouldShowImage = image && (isLocked || isShaded || isSelected || (isCorrect && showAnswer));

                return (
                    <ShadingCell
                        key={index}
                        width={cellWidth}
                        height={cellHeight}
                        isShaded={shaded}
                        isSelected={selected}
                        isLocked={isLocked}
                        isHidden={isHidden}
                        isHoverState={isHoverState}
                        showAnswer={showAnswer}
                        isCorrect={correct}
                        isIncorrect={incorrect}
                        onClick={() => !isHidden && handleItemClick(row, col)}
                    >
                        {shouldShowImage && (
                            <img
                                title={title}
                                src={image}
                                alt=""
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                        )}
                        {showAnswer && (
                            correct ? (
                                <FaCheck style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '30px',
                                    height: '30px',
                                    color: 'green'
                                }}/>
                            ) : (
                                incorrect && (
                                    <FaTimes style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '30px',
                                        height: '30px',
                                        color: 'red'
                                    }}/>
                                )
                            )
                        )}
                    </ShadingCell>
                );
            })}
        </GridContainer>
    );
};

export default ShadingGrid;
