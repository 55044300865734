import { Action, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from '../reducers/rootReducer';
// import { createLogger } from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';
import { ThunkAction } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["selectedQuestions"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const logger = createLogger({
//   // Configure logger options here
// });

export function makeStore() {
  const middleware = [
    thunk,
    //   logger
  ];
  const storeEnhancers = composeWithDevTools(applyMiddleware(...middleware));

  return createStore(persistedReducer, storeEnhancers);
}

const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
