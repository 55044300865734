import { ValidResponse } from '../types';

export const isValidResponse = (answer: any): answer is ValidResponse => {
    return answer &&
        typeof answer === 'object' &&
        typeof answer.value === 'string' &&
        typeof answer.score === 'string' &&
        typeof answer.method === 'string';
};

export const checkCorrectAnswer = (
    selectedCells: [number, number][],
    correctAnswer?: ValidResponse
): boolean => {
    if (!correctAnswer || !isValidResponse(correctAnswer)) return false;

    if (correctAnswer.method === 'byCount') {
        const targetCount = parseInt(correctAnswer.value, 10);
        return selectedCells.length === targetCount;
    }

    return false;
};

export const isShaded = (shadedCells: [number, number][], row: number, col: number): boolean =>
    shadedCells.some(([r, c]) => r === row && c === col);

export const isPossibleResponse = (possibleResponses: [number, number][], row: number, col: number): boolean =>
    possibleResponses.some(([r, c]) => r === row && c === col);

export const isCorrectByLocation = (correctAnswer: [number, number][], row: number, col: number): boolean =>
    correctAnswer.some(([r, c]) => r === row && c === col);

export const isCorrectByCount = (selectedCount: number, targetCount: number): boolean =>
    selectedCount === targetCount;

export const getCorrectCellsByCount = (
    rowCount: number,
    columnCount: number,
    targetCount: number,
    selectedCells: [number, number][]
): [number, number][] => {
    if (selectedCells.length === targetCount) {
        return selectedCells;
    }

    // If not enough cells are selected, return first N cells where N is targetCount
    const result: [number, number][] = [];
    let count = 0;

    for (let row = 0; row < rowCount && count < targetCount; row++) {
        for (let col = 0; col < columnCount && count < targetCount; col++) {
            result.push([row, col]);
            count++;
        }
    }

    return result;
};

export const isSelected = (selectedCells: [number, number][], row: number, col: number): boolean =>
    selectedCells.some(([r, c]) => r === row && c === col);
