import { IoIosClose } from "react-icons/io";

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  onDelete: (e: React.MouseEvent) => void;
  tabIndex: number;
}

export const Tab: React.FC<TabProps> = ({
  label,
  isActive,
  onClick,
  onDelete,
  tabIndex
}) => (
  <div className="flex items-center">
    <div
      id="choice"
      onClick={onClick}
      className={`px-5 py-2.5 cursor-pointer bg-transparent text-sm font-medium
      ${
        isActive ? "text-[#DE2128] border-b-2 border-red-500" : "text-[#2C2C2C]"
      }`}
    >
      {label}
    </div>
    {tabIndex > 0 && <button
      className="ml-2 text-gray-500 hover:text-red-500"
      onClick={(e) => {
        e.stopPropagation(); // Prevent event propagation to the parent div
        onDelete(e);
      }}
    >
      <IoIosClose className="text-2xl" />
    </button>}
  </div>
);
