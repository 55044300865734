import React, { useEffect, useRef, useState } from 'react';
import { QuestionContent } from './styles';

import Button from '../../../components/Button';

import DOMPurify from 'dompurify';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import QuestiontypeModal from '../../QuestiontypeModal';

type PreviewWrapperProps = {
  currentQuestion: any;
  showAnswer: boolean;
  setShowAnswers: any;
  parentMode: string;
  editMode: boolean;
  children: React.ReactNode;
  handleBackToEdit?: () => void;
  handleGoToEdit?: (e: React.MouseEvent<HTMLButtonElement>) => void; // Modified type
  handleShowAnswer?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /*handleDelete?:(e:React.MouseEvent<HTMLButtonElement>)=>void;*/
};

const PreviewWrapper = ({
  currentQuestion,
  showAnswer,
  setShowAnswers,
  parentMode,
  editMode,
  children,
  handleBackToEdit,
  handleGoToEdit,
  handleShowAnswer,
}: PreviewWrapperProps) => {
  const { t } = useTranslation();
  const questionRef = useRef(null);
  const instructorStimulusRef = useRef<HTMLDivElement>(null);
  const [getScore, setGetScore] = useState<number>(0);
  const [highestScore, setHighestScore] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const instant_feedback = currentQuestion?.more_options?.ui_style?.instant_feedback ?? false;
  const fontsize = currentQuestion?.more_options?.ui_style?.fontsize ?? 'medium';
  const feedback_attempts = currentQuestion?.more_options?.validation?.feedback_attempts ?? 0;
  const instructor_stimulus = currentQuestion?.more_options?.metadata?.instructor_stimulus ?? currentQuestion?.more_options?.extras?.instructor_stimulus;
    // console.log("showAnswer",showAnswer)
  const handleShowAnswerButton = (e) => {
    e.preventDefault();
    handleShowAnswer ?? setShowAnswers(!showAnswer);
  };
console.log('this is preview wrapper', currentQuestion);
  // const checkAnswerButton = () => {
  //   if (attempts < feedback_attempts) {
  //     setShowAnswers(!showAnswer)
  //     setAttempts((prev) => prev + 1);
  //   }
  // };
  const checkAnswerButton = () => {
    if (attempts < feedback_attempts) {
      if (getScore === highestScore) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }
      setAttempts((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (instructorStimulusRef.current && instructor_stimulus) {
      instructorStimulusRef.current.innerHTML = DOMPurify.sanitize(instructor_stimulus);
    }
  }, [instructor_stimulus]);

    const fontSizeMapping = {
      small: "12px",
      medium: "14px",
      large: "17px",
      "x-large": "20px",
      "xx-large": "24px",
    };
    const mappedFontSize = fontSizeMapping[fontsize] || "14px";
  // parse an html string/*
  useEffect(() => {
   /* const cleanHTML = DOMPurify.sanitize(currentQuestion?.question, {
      ALLOWED_TAGS: ['div', 'span', 'script', 'math'], // Add MathJax-related tags if needed
      ALLOWED_ATTR: ['class', 'style', 'data-mathjax'] // Add MathJax-related attributes if needed
    });
    questionRef.current.innerHTML = cleanHTML;
    window.MathJax.typesetPromise([questionRef.current]); */

    questionRef.current.innerHTML = DOMPurify.sanitize(
      currentQuestion?.question
    );
    if (window.MathJax && window.MathJax.typesetPromise) {
      window.MathJax.typesetPromise([questionRef.current])
        .then(() => {
          console.log('MathJax typesetting completed');
        })
        .catch((err: Error) => {
          console.error('MathJax typesetting failed:', err);
        });
    }
    //window.MathJax.typesetPromise(questionRef.current);
  }, [questionRef, currentQuestion?.question]);

  return (
    <QuestionContent parentMode={parentMode === 'question'} style={{ fontSize: mappedFontSize }}>
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
        {instructor_stimulus &&  (
            <div className="bg-blue-100 p-4 mb-4 rounded-lg">
              <div ref={instructorStimulusRef}></div>
            </div>
          )}
                  <div
          className="bg-light-accent flex p-5 gap-3 rounded-lg"
          style={{
            fontSize:
              currentQuestion?.more_options?.layout?.fontSize ?? 
              currentQuestion?.more_options?.ui_style?.fontsize ?? 
              mappedFontSize 
          }}
        >
          <p
            className="font-bold"
            style={{
              fontSize:
                currentQuestion?.more_options?.layout?.fontSize ??
                currentQuestion?.more_options?.ui_style?.fontsize ??
                mappedFontSize 
            }}
          >
            {t('question')}:&nbsp;
          </p>
          <div className="question-preview" ref={questionRef} />
        </div>
        </div>

        <div>
          {parentMode === 'question' && !editMode && (
            <>
              <Button
                type="button"
                margin
                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                onClick={handleShowAnswerButton}
              />

              <Button title={'Back to edit'} onClick={handleBackToEdit} />
            </>
          )}

          {parentMode === 'preview-assessment' && (
            <>
              <Button
                margin
                type="button"
                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                onClick={handleShowAnswerButton}
              />
            </>
          )}

          {editMode && (
            <div className="flex gap-4">
              <Button
                type="button"
                title={t('btn.go_to_edit')}
                onClick={(e) => handleGoToEdit && handleGoToEdit(e)}
              />

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              >
                {t('btn.delete')}
              </Button>
            </div>
          )}

          {isModalOpen && (
            <QuestiontypeModal
              setIsModalOpen={setIsModalOpen}
              id={currentQuestion?.id}
              isModalOpen={isModalOpen}
            />
          )}
        </div>
      </div>

      {children}
      {instant_feedback && !editMode && (
        <div className="mt-6 flex justify-end">
          {/* <button
            disabled={attempts > 0 && attempts >= feedback_attempts}
            onClick={checkAnswerButton}
            className={`text-right font-semibold py-2 px-4 border rounded transition duration-300 ${
              attempts > 0 && attempts >= feedback_attempts
                ? "bg-[#aaaaaa] text-[#717171] cursor-not-allowed "
                : "bg-transparent hover:bg-accent text-gray-700 hover:text-white border hover:border-transparent"
            }`}
          >
            Check Answer
          </button> */}
        </div>
      )}
    </QuestionContent>
  );
};

export default PreviewWrapper;

// Set default props
PreviewWrapper.defaultProps = {
  parentMode: 'assessment',
  editMode: false,
};
