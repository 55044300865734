import React, { useEffect, useState, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import { TbPoint } from "react-icons/tb";
import { IoMdMove } from "react-icons/io";
import { FaRegCircle } from "react-icons/fa";
import ResponseTabs from "./components/ResponseTabs";
import Controls from "./components/Controls";
import { setKeyValue } from "redux/slices/QuestionSlice";
import { GraphOptions, GraphData } from "./SubChildren/GraphingUnit";
import useBoard from "./useBoard";
import useBoardEvents from "./useBoardEvents";
import { EditSubQues } from "redux/slices/EditSubQuestionSlice";
import { IoIosUndo, IoIosRedo, IoIosClose } from "react-icons/io";
import { RxReset } from "react-icons/rx";
import uuid from "react-uuid";
import { debounce } from "utils/generalUtils";
import { getNewLabelObjectState } from "./utils/boardUtils";

interface INewGraphProps {
  currentQuestion: any | undefined;
  optionItems?: Partial<GraphOptions> | GraphOptions | any;
  questiontype: "add" | "edit" | "preview" | "shaded";
  data?: Partial<GraphData> | GraphData | undefined;
  showAnswer?: boolean;
}

interface GraphFormData {
  correct_answer: {
    valid_response: {
      value: any[];
      score: number;
      tools: any[];
    };
    alt_responses: Array<{
      value: any[];
      score: number;
      tools: any[];
      tabId?: string;
    }>;
  };
}

export default function NewGraph({
  currentQuestion,
  optionItems,
  questiontype,
  data,
  showAnswer,
}: INewGraphProps) {
  const dispatch = useDispatch();
  const customGraphData = data;
  const [graphSettings, setGraphSettings] = useState(data);
  const DEFAULT_ALT_ANSWER = { tabId: uuid(), value: [], score: 0, tools: [] };
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [buttonOption, setButtonOption] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [defaultTool, setDefaultTool] = useState(
    data?.options?.toolbar?.default_tool
  );
  const [selectedButton, setSelectedButton] = useState(
    graphSettings?.options?.toolbar?.default_tool
  );
  const [customGraphOptions, setCustomGraphOptions] = useState<any[]>(() => {
    if (questiontype === "edit") {
      if (
        currentQuestion?.correct_answer?.valid_response.shapes.length > 0 ||
        currentQuestion?.correct_answer?.alt_responses.length > 0
      ) {
        const validShapes =
          currentQuestion?.correct_answer?.valid_response.shapes;
        const altShapes =
          currentQuestion?.correct_answer?.alt_responses.flatMap(
            (response) => response.shapes
          );
        return [...validShapes, ...altShapes];
      }
    } else {
      return [];
    }
  });
  const [customGraphOptionsIndex, setCustomGraphOptionsIndex] = useState(null);
  const [redoData, setRedoData] = useState<any[]>([]);
  const [labelValue, setLabelValue] = useState("");
  const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
  const [activeShape, setActiveShape] = useState(false);
  const [labelState, setLabelState] = useState({
    element: null,
    x: 0,
    y: 0,
    selectedObject: null,
    isChild: false,
  });
  const [userFinishedDrawing, setUserFinishedDrawing] =
    useState<boolean>(false);
  const boardRef = useRef<HTMLDivElement | null>(null);
  const [hasGraphOptions, setHasGraphOptions] = useState(false);
  const graphPoints = useRef<any[]>([]);
  const [deleteStart, setDeleteStart] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState<any>([]);
  const [boardResetting, setBoardResetting] = useState(false);
  const [deletedElements, setDeletedElements] = useState<any[]>([]);

  const [graphData, setGraphData] = useState<any>(
    customGraphData || {
      correct_answer: {
        valid_response: {
          value: [],
          score: 0,
          tools: [],
        },
        alt_responses: [],
      },
    }
  );

  const { register, control, setValue, watch } = useForm<GraphFormData>({
    defaultValues: graphData,
  });
  const { fields, remove } = useFieldArray({
    control,
    name: "correct_answer.alt_responses",
  });
  const watchedFields = watch("correct_answer.alt_responses");

  const graphRef = useRef<(HTMLDivElement | null)[]>([]);
  const [altTabs, setAltTabs] = useState(fields);

  useEffect(() => {
    boardRef.current = graphRef.current[activeTabIndex];
    return () => {
      boardRef.current = null;
    };
  }, [activeTabIndex]);
  useEffect(() => {
    setSelectedButton(data?.options?.toolbar?.default_tool);
    setDefaultTool(data?.options?.toolbar?.default_tool);
    return () => {
      setSelectedButton("");
    }
  }, [optionItems?.toolbar?.default_tool]);

  useEffect(() => {
    if (data) {
      setGraphSettings(data);
    }
    if (currentQuestion) {
      setGraphSettings(currentQuestion);
    }
    return () => {
      setGraphSettings({});
    };
  }, [data, currentQuestion]);

  useEffect(() => {
    setSelectedButton(optionItems?.toolbar?.default_tool);
    return () => {
      setSelectedButton("");
    };
  }, [optionItems?.toolbar?.default_tool]);

  const board = useBoard(
    boardRef,
    graphSettings,
    optionItems,
    customGraphOptions,
    activeTabIndex,
    graphPoints,
    correctAnswer,
    showAnswer
  );

  useBoardEvents(
    board,
    selectedButton,
    setCustomGraphOptions,
    activeTabIndex,
    setLabelState,
    userFinishedDrawing,
    graphPoints,
    deleteStart,
    setDeleteStart,
    questiontype,
    graphSettings,
    boardResetting,
    setBoardResetting,
    deletedElements,
    setDeletedElements,
    setActiveShape
  ); // Use the custom hook for event handling
  useEffect(() => {
    if (labelState.element) {
      const x = labelState?.x;
      const y = labelState?.y;
      setLabelPosition({ x, y });
      setActiveShape(true);
    }
  }, [labelState.element]);

  useEffect(() => {
    if (questiontype !== "add" && currentQuestion) {
      dispatch(setKeyValue({ key: currentQuestion, value: graphData }));
    }
  }, [graphData, questiontype, currentQuestion]);

  useEffect(() => {
    setHasGraphOptions(customGraphOptions.length > 0);

    if (questiontype === "preview") return;

    const storeData = {
      ...data,
      correct_answer: {
        valid_response: {
          ...graphData?.correct_answer?.valid_response,
          tools: optionItems?.toolbar?.tools,
          shapes: customGraphOptions.filter((o) => o.tab === 0),
        },
        alt_responses: graphData?.correct_answer?.alt_responses
          ?.map((res, i) => ({
            ...res,
            tools: optionItems?.toolbar?.tools,
            shapes: customGraphOptions.filter((o) => o.tab === i + 1),
          }))
          .filter((o) => o.shapes.length > 0),
      },
      options: {
        canvas: optionItems?.canvas,
        toolbar: {
          ...optionItems?.toolbar,
          tools: optionItems?.toolbar?.tools,
          controls: data?.options?.toolbar?.controls,
        },
      },
      more_options: {
        ...data?.more_options,
        background_shapes:
          currentQuestion?.more_options?.background_shapes ||
          data?.more_options?.background_shapes,
      },
    };
    // @ts-ignore
    if (questiontype === "add") {
      dispatch(
        setKeyValue({ key: storeData.type, subKey: "", value: storeData })
      );
      return;
    } else if (questiontype === "edit") {
      dispatch(EditSubQues(storeData));
      return;
    } else if (questiontype === "shaded") {
      const objectData = {
        ...currentQuestion,
        more_options: {
          ...data?.more_options,
          background_shapes: customGraphOptions,
        },
        options: {
          canvas: optionItems?.canvas,
          toolbar: {
            ...optionItems?.toolbar,
            tools: data?.options?.toolbar?.tools,
            controls: data?.options?.toolbar?.controls,
          },
        },
      };
      dispatch(
        setKeyValue({ key: objectData.type, subKey: "", value: objectData })
      );
    }
  }, [customGraphOptions, dispatch, questiontype, data]);

  useEffect(() => {
    if (questiontype === "preview" && board) {
      if (!showAnswer) {
        correctAnswer.forEach((option) => board.removeObject(option.drawKey));
        setCorrectAnswer([]);
        return;
      }
      const src = currentQuestion ?? data;
      // console.log(src, 'correct answer');
      if (
        !src?.correct_answer?.valid_response.shapes ||
        !src?.correct_answer?.alt_responses
      )
        return;
      if (
        src?.correct_answer?.valid_response.shapes.length > 0 ||
        src?.correct_answer?.alt_responses.length > 0
      ) {
        const validShapes = src?.correct_answer?.valid_response.shapes;
        const altShapes = src?.correct_answer?.alt_responses.flatMap(
          (response) => response.shapes
        );
        const allShapes = [...validShapes, ...altShapes].filter(
          (shape) => !shape.hasParent
        );
        setCorrectAnswer(
          allShapes.map((shape) => ({
            ...shape,
            options: { ...shape.options, strokeColor: "green" },
          }))
        );
      }
    }
  }, [showAnswer, questiontype]);

  useEffect(() => {
    if (deletedElements.length > 0) {
      const debouncedUpdate = debounce(() => {
        setCustomGraphOptions((prevData) => {
          const filteredData = prevData.filter(
            (element) => !deletedElements.includes(element.id)
          );
          return filteredData;
        });
        board.update();
      }, 300);
      debouncedUpdate();
      setSelectedButton(
        data?.options?.toolbar?.default_tool ||
          currentQuestion?.options?.toolbar?.default_tool
      );
      setCustomGraphOptionsIndex(null);
      // setDeleteStart(false);
    }
    return () => {
      setDeletedElements([]);
    };
  }, [deletedElements]);

  useEffect(() => {
    if (altTabs.length > watchedFields.length) {
      //@ts-nocheck
      const deletedIndex = altTabs.findIndex(
        (tab) => !watchedFields.find((field) => field.tabId === tab.tabId)
      );
      if (deletedIndex !== -1) {
        setCustomGraphOptions((prevData) => {
          const filteredData = prevData
            .filter((o) => o.tab !== deletedIndex + 1)
            .map((o) => {
              return {
                ...o,
                tab:
                  o.tab === 0
                    ? 0
                    : o.tab >= deletedIndex + 1
                    ? o.tab - 1
                    : o.tab,
              } as any;
            });
          return filteredData;
        });
      }
    }
  }, [watchedFields]);

  const handleAddingNewAlternateAnswer = () => {
    setValue("correct_answer.alt_responses", [...fields, DEFAULT_ALT_ANSWER]);
    setGraphData({
      ...graphData,
      correct_answer: {
        ...graphData.correct_answer,
        alt_responses: [
          ...graphData.correct_answer.alt_responses,
          DEFAULT_ALT_ANSWER,
        ],
      },
    });
    //@ts-ignore
    setAltTabs([...fields, DEFAULT_ALT_ANSWER]);
  };

  const handlePointsChange = (value: string, index: number) => {
    const numericScore = parseFloat(value);
    if (isNaN(numericScore)) return;

    const newGraphData = JSON.parse(JSON.stringify(graphData));
    if (index === 0) {
      newGraphData.correct_answer.valid_response.score = numericScore;
      setValue("correct_answer.valid_response.score", numericScore);
    } else if (index <= newGraphData.correct_answer.alt_responses.length) {
      newGraphData.correct_answer.alt_responses[index - 1].score = numericScore;
      setValue(`correct_answer.alt_responses.${index - 1}.score`, numericScore);
    }
    setGraphData(newGraphData);
  };

  const getScoringValue = (index: number): number => {
    if (index === 0) {
      return graphData.correct_answer.valid_response.score;
    }
    return graphData.correct_answer.alt_responses?.[index - 1]?.score ?? 0;
  };
  const handleMovePoints = () => {};
  const handleUndo = () => {
    if (customGraphOptions.length > 0) {
      const lastItem = customGraphOptions.at(-1); // Get the last item from customGraphOptions
      setRedoData((prevRedoData) => [...prevRedoData, lastItem]); // Add it to redoData

      let undoId;
      for (let i = customGraphOptions.length - 1; i >= 0; i--) {
        const item = customGraphOptions[i];
        if (item.tab === activeTabIndex) {
          undoId = item.id;
          break;
        }
      }

      setCustomGraphOptions(
        (prevData) => prevData.filter((o) => o.id !== undoId) // Remove the item from customGraphOptions
      );
    }
  };

  const handleRedo = () => {
    if (redoData.length > 0) {
      const lastRedoItem = redoData.at(-1);
      setCustomGraphOptions((prevData) => [...prevData, lastRedoItem]);
      setRedoData((prevRedoData) => prevRedoData.slice(0, -1));
    }
  };

  const handleReset = () => {
    setBoardResetting(true);
    setCustomGraphOptions((prevState) => {
      // setBoardResetting(false)
      const filteredData = prevState.filter(
        (option) => option.tab !== activeTabIndex
      );
      return filteredData;
    });
    board.update();
    setRedoData([]);
    setUserFinishedDrawing(true);
    graphPoints.current = [];
    setActiveShape(false);
  };
  const handleDelete = () => {
    // setDeleteStart(!deleteStart);
    setDeleteStart((prev) => !prev);
  };
  useEffect(() => {
    setHasGraphOptions(data?.more_options?.controls?.length > 0);
  }, [data?.more_options?.controls]);

  const handleSettingLabel = (e: any) => {
    if (e.key === "Enter" || e.type === "blur") {
      if (labelState?.element) {
        labelState.element.setAttribute({ name: labelValue });
      }
      setCustomGraphOptions((prevData) => {
        const objInBoard = prevData.find(
          (element) => element.id === labelState.selectedObject.itemId
        );
        const newObject = getNewLabelObjectState(
          objInBoard.type,
          labelState.isChild,
          objInBoard,
          labelState.element,
          labelValue
        );
        const filteredData = prevData.filter(
          (element) => element.id !== labelState.selectedObject.itemId
        );
        // console.log(newObject, "objectSent");
        return [...filteredData, newObject];
      });
      setLabelValue("");
      setActiveShape(false);
    }
  };

  const buttons = [
    {
      label: "Undo",
      icon: <IoIosUndo className="mr-1" />,
      action: handleUndo,
      isDisabled: !hasGraphOptions,
    },
    {
      label: "Redo",
      icon: <IoIosRedo className="mr-1" />,
      action: handleRedo,
      isDisabled: !redoData?.length,
    },
    {
      label: "Reset",
      icon: <RxReset className="mr-1" />,
      action: handleReset,
      isDisabled: !hasGraphOptions,
    },
    {
      label: "Delete",
      icon: <IoIosClose className="mr-1" />,
      action: handleDelete,
      isDisabled: !hasGraphOptions,
    },
  ];

  return (
    <div className="w-full">
      {questiontype !== "preview" && questiontype !== "shaded" && (
        <ResponseTabs
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          fields={fields}
          onAddAlternative={handleAddingNewAlternateAnswer}
          getScoringValue={getScoringValue}
          onScoreChange={handlePointsChange}
          remove={remove}
        />
      )}
      <div className="flex flex-col items-start justify-between mb-5 rounded-md gap-y-1 w-[800px]">
        {graphSettings?.more_options?.annotation?.title && (
          <div className="text-center text-lg font-medium mt-2 mb-1">
            {graphSettings?.more_options?.annotation?.title}
          </div>
        )}
        <div className="mt-5 flex items-center p-2 justify-between bg-[#eee] rounded-md flex-wrap min-w-full gap-2">
          <div className="flex space-x-2">
            {optionItems?.toolbar?.tools?.map((tool, index) => (
              <button
                key={`${tool}-${index}`}
                type="button"
                className={`mr-2 flex p-[5px] ${
                  customGraphOptionsIndex === index
                    ? "rounded-sm border-[#fa9b31]"
                    : ""
                } border-2`}
                onClick={() => {
                  setSelectedButton(tool);
                  setCustomGraphOptionsIndex(index);
                  handleMovePoints();
                  if (isLocked) setIsLocked(!isLocked);
                }}
              >
                {tool === "Point" && <TbPoint className="mr-1" />}
                {tool === "Move" && <IoMdMove className="mr-1" />}
                {tool === "Circle" && <FaRegCircle className="mr-1" />}
                {tool}
              </button>
            ))}
          </div>
          <Controls
            buttons={buttons.filter((btn) =>
              graphSettings?.options?.toolbar?.controls?.includes(btn.label)
            )}
          />
        </div>
        <div
          className="flex items-center justify-between mb-5 rounded-md relative"
          onMouseLeave={() => {
            setUserFinishedDrawing(true);
          }}
          onMouseEnter={() => {
            setUserFinishedDrawing(false);
          }}
        >
          <div
            style={{
              margin: `${graphSettings?.more_options?.layout?.margin || 0}px`,
            }}
          >
            {graphSettings?.more_options?.annotation?.label_top && (
              <div className="text-center font-2 mt-2 mb-1">
                {graphSettings?.more_options?.annotation?.label_top}
              </div>
            )}
            <div className="basis-full">
              {graphSettings?.more_options?.annotation?.label_left && (
                <div className="text-center font-2 mt-2 mb-1 rotate-90 origin-top-left pr-40">
                  {graphSettings?.more_options?.annotation?.label_left}
                </div>
              )}
              <div
                ref={(el) => {
                  graphRef.current[activeTabIndex] = el; // Correctly assign the current div
                }}
                className="jxgbox border"
                style={{
                  height: `${
                    graphSettings?.more_options?.layout?.height || 800
                  }px`,
                  width: `${
                    graphSettings?.more_options?.layout?.width || 800
                  }px`,
                }}
              ></div>
              {graphSettings?.more_options?.annotation?.label_right && (
                <div className="text-center font-2 mt-4 mb-4 rotate-90 origin-top-right pl-40 translate-x-4">
                  {graphSettings?.more_options?.annotation?.label_right}
                </div>
              )}
            </div>
            {graphSettings?.more_options?.annotation?.label_bottom && (
              <div className="text-center font-2 mt-2 mb-1">
                {graphSettings?.more_options?.annotation?.label_bottom}
              </div>
            )}
          </div>
          {activeShape && (
            <input
              type="text"
              value={labelValue}
              onChange={(e) => setLabelValue(e.target.value)}
              style={{
                position: "absolute",
                top: `${labelPosition.y}px`,
                left: `${labelPosition.x}px`,
                display: labelState.element ? "block" : "none",
              }}
              onKeyDown={(e) => {
                handleSettingLabel(e);
              }}
              onBlur={(e) => handleSettingLabel(e)}
              placeholder="Type then press enter"
              className="border border-gray-300 rounded-md p-2"
            />
          )}
        </div>
      </div>
    </div>
  );
}
