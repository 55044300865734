import { useEffect, useRef, useState } from 'react';
import { QuestionContent } from './styled';
import Button from '../../../components/Button';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import QuestiontypeModal from 'components/QuestiontypeModal';
import './customForMath.css'
import DOMPurify from 'dompurify';
import { getLabelByStemNumeration, getResponseFontSize } from 'utils/generalUtils';
import './customForMath.css'
import { MathJaxContext } from 'better-react-mathjax';
import { ChemistryTypes } from 'pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants';
import CustomJoditEditor from 'components/CustomJoditEditor';


// const sanitizeHTMLWithMath = (htmlString) => {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlString, 'text/html');

//   // Sanitize each node
//   Array.from(doc.body.childNodes).forEach((node) => {
//     if (node.nodeType === Node.ELEMENT_NODE) {
//       const element: any = node;
//       if (!element.classList.contains('math-expression')) {
//         // Sanitize non-math content
//         const sanitized = DOMPurify.sanitize(element.outerHTML);
//         element.outerHTML = sanitized;
//       }
//     }
//   });

//   return doc.body.innerHTML; // Return sanitized content
// };

const mathJaxConfig = {
  loader: { load: ['input/tex', 'output/svg'] },
};


const PreviewChemistryFormula = ({
    currentQuestion, 
    showAnswer = false,
    setShowAnswers = null, 
    parentMode = 'assessment',
    editMode = false,
}) =>{

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [elements, setElements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const validResponse = currentQuestion?.correct_answer?.valid_response;
  const more_options = currentQuestion?.more_options;
  const resMinWidth = more_options && more_options?.layout?.response_min_width ? more_options && more_options?.layout?.response_min_width : 0;
  const resGlobalWidth = more_options && more_options?.response_options?.width ? more_options?.response_options?.width : 0;
  const resGlobalHeight = more_options && more_options?.response_options?.height ? more_options?.response_options?.height : 0;
  const resIndividaulOptions = more_options && Array.isArray(more_options?.response_options_individaul) ? more_options && more_options?.response_options_individaul : [];


  function insertResponse(array, insertObject) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      result.push(array[i]);
      if (i < array.length - 1) {
        result.push(insertObject);
      }
    }
    return result;
  }

  // New code to maintain Response properly in the table format
  useEffect(() => {
    let inputString = currentQuestion.template_response;
    if (inputString) {
      const regex = "<strong>Response</strong>";
      const parts = inputString.split(regex);
      let T = [];
      for (let i = 0; i < parts.length; i++) {
        if (parts[i] !== undefined && parts[i] !== '') {
          if (parts[i] === '<strong>Response</strong>') {
            T.push({ type: 'Res', content: 'Response' });
          } else {
            T.push({ type: 'txt', content: parts[i] });
          }
        }
      }

      T = insertResponse(T, { type: 'Res', content: 'Response' });
      setElements(T);

      const replaceResponseWithMathlive = () => {
        const container = document.getElementById(`display-content-${currentQuestion?.id}`);
        const firsParagraphEl = container?.querySelector('p');
        if(firsParagraphEl)firsParagraphEl.style.display = 'block';
        if (container) {
          const responseSpans = container.querySelectorAll('span#btnresponse');
          if(responseSpans.length > 0) {
            responseSpans.forEach((span, index: number) => {
            // const mathfield = new MathfieldElement();
            const mathInput = document.createElement('math-field');
             // @ts-ignore
            mathInput.mathModeSpace = '\\:';
            // @ts-ignore
            if(editMode)mathInput.setAttribute('readonly', true)
            mathInput.style.border = '1px solid black';
            mathInput.style.padding = '5px';
            mathInput.style.margin = '5px 0';
            mathInput.style.minHeight = '44px';
            mathInput.style.minWidth = '40px';
         //   mathInput.style.width = "20%";
            mathInput.style.fontSize =more_options && more_options?.layout.fontSize ?
              more_options.layout.fontSize  : '12px';
            if(resMinWidth) {
              mathInput.style.minWidth = `${resMinWidth}px`;
            }
            if(resGlobalWidth && Number(resMinWidth) < Number(resGlobalWidth)) {
              mathInput.style.width = `${resGlobalWidth}px`;
            }
            if(resGlobalHeight) {
              mathInput.style.height = `${resGlobalHeight}px`;
            }
            if(resIndividaulOptions.length > 0) {
              const itemExist = resIndividaulOptions.find((item, idx) => idx == index );
              if(itemExist && Number(resMinWidth) < Number(itemExist?.width)) {
                if(itemExist.width) {
                  mathInput.style.minWidth = `${itemExist.width}px`;
                }
                if(itemExist.height) {
                  mathInput.style.minHeight = `${itemExist.height}px`;
                }
              }
            }
            mathInput.style.fontSize = getResponseFontSize(more_options);
            span.replaceWith(mathInput);
          });
        }else {
          // const mathInput = document.createElement('math-field');
          // // @ts-ignore
          // mathInput.mathModeSpace = '\\:';
          // // @ts-ignore
          // mathInput.style.width = '100%';
          // // @ts-ignore
          // if(editMode)mathInput.setAttribute('readonly', true);
          // // @ts-ignore
          // // mathInput.append(sanitizeHTML2(currentQuestion?.template_response));
          // const sanitizedContent = sanitizeHTMLWithMath(currentQuestion?.template_response);
          // mathInput.innerHTML = sanitizedContent;
          // // console.log('inside else sanitized content =', sanitizedContent)
          //   // mathInput.innerHTML = currentQuestion.template_response;
          // container.replaceWith(mathInput)
        }
        }
      };
      replaceResponseWithMathlive();
    }
  }, []);

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode({type: ChemistryTypes.CHEM_FORMULA, id: currentQuestion?.id}));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const correctTextRef = useRef<Array<HTMLSpanElement | null>>([]);

  const getTheContent = (content: string) => {
    let newContent = content;
    const regex = /<p(?![^<]*<\/p>)/g;
    newContent = newContent.replace(regex, '<span');
    return newContent
  }

  useEffect(() => {
    elements.forEach((item, i) => {
      if (correctTextRef.current[i]) {
        const content = getTheContent(item?.content);
        correctTextRef.current[i].innerHTML = item?.content == "Response" ?
          "" :
          DOMPurify.sanitize(content);
      }
    });
  }, [elements]);


  const handleResponseStyle = (moreOptions, correctAnswers, index) => {
    let style: string = 'border: 1px solid black; padding: 0 8px; height: 100%;'
    const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
    if (moreOptions) {
      if (moreOptions?.response_options?.width) {
        style += `width: ${moreOptions.response_options.width}px; `
      }
      if (moreOptions?.response_options?.height) {
        style += `height: ${moreOptions.response_options.height}px; `
      }
      if (moreOptions?.layout?.response_min_width) {
        style += `min-width: ${moreOptions?.layout?.response_min_width}px;`
      }
      if (moreOptions?.layout?.fontSize) {
        style += `font-size: ${moreOptions?.layout?.fontSize}; `
      }
    }
    if (moreOptions && resOptionsIndividaul.length > 0) {
      // we collect all responses first
      const allRes = correctAnswers.filter(ca => ca?.type == 'Res');
      const currentResFromcorrectanswers = correctAnswers[index];
      const currentResIndex = allRes.findIndex((res, idx) => currentResFromcorrectanswers === res);
      if (currentResIndex > -1) {
        const currentResOptions = resOptionsIndividaul.find((_item, idx) => idx == currentResIndex);
        if (currentResOptions) {
          if (currentResOptions?.width) {
            style += `width: ${currentResOptions.width}px; `
          }
          if (currentResOptions?.height) {
            style += `height: ${currentResOptions.height}px; `
          }
        }
      }
    }
    return style;
  }


  return (
    <MathJaxContext config={mathJaxConfig}>
      <QuestionContent parentMode={parentMode === 'question'}>
      {
        more_options?.extras?.instructor_stimulus && !editMode &&
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {more_options?.extras?.instructor_stimulus}
        </div>
      }
        <div className="flex flex-wrap gap-5 justify-between mb-5">
          <div className="w-full lg:w-[70%]">
            <div 
              className="bg-light-accent flex p-5 gap-3 rounded-lg"
              style={more_options && more_options?.layout.fontSize ? 
                {fontSize: more_options.layout.fontSize}: 
                {fontSize: 'medium'}
              }
            >
              <p className="font-bold">Question :&nbsp;</p>
              <span
                dangerouslySetInnerHTML={{
                  __html: ` ${currentQuestion?.question}`,
                }}
              />
            </div>
          </div>

          <div className="flex gap-4">
            {editMode ? (
              <>
                <Button
                  margin
                  title={'Go to edit'}
                  onClick={() => handleEditClick()}
                />
                <Button
                  title={'Delete'}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                />

                {isModalOpen && (
                  <QuestiontypeModal
                    // @ts-ignore
                    setIsModalOpen={setIsModalOpen}
                    id={currentQuestion?.id}
                    isModalOpen={isModalOpen}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  margin
                  title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                  onClick={() => {
                    if (setShowAnswers) setShowAnswers(!showAnswer);
                  }}
                />

                <Button
                  title={'Back to edit'}
                  onClick={() => {
                    dispatch(setClosePreview());
                  }}
                />
              </>
            )}
          </div>
        </div>

        <div style={{
          border:  more_options?.scoring && more_options?.scoring?.transparent_background ?
            'none' : '1px solid #D9D9D9',
          padding: '24px',
          marginBottom: '10px',
          // backgroundColor: '#f0f0f0
          backgroundColor: more_options?.scoring && more_options?.scoring?.transparent_background ?
          'transparent' : '#f0f0f0',
          overflowX: 'scroll'
        }}>
          <div 
            id={`display-content-${currentQuestion?.id}`} 
            className="display-content overflow-x-visible" 
            style={{fontSize: getResponseFontSize(more_options)}}  
            dangerouslySetInnerHTML={{ __html: currentQuestion?.template_response }} 
          />
        </div>

        {
          elements.length <= 1 && !editMode &&
          <CustomJoditEditor 
            label=""
            value= {currentQuestion?.template_response || ''}
            onBlur={(value) => {}}
            allconfig={true}
            questionconfig={true}
          />
        }
        {showAnswer && (
          <ul className="w-full flex flex-col p-2">
            <p 
              className="font-semibold"
              style={{fontSize: getResponseFontSize(more_options,true)}}  
            >
              Answers: 
            </p>

            {Array.isArray(validResponse?.value) &&
              validResponse?.value.map((vr, index) => (
                <li 
                  className="mt-4" 
                  key={index}
                  style={{fontSize: getResponseFontSize(more_options,true)}}  
                >
                  <span className="px-2 bg-slate-500 text-white">
                  {getLabelByStemNumeration(more_options, index)}
                  </span>{' '}
                  {/* @ts-expect-error */}
                  <math-field readonly={true}>
                    {vr?.value || ''}
                    {/* @ts-expect-error */}
                  </math-field>
                </li>
              ))}
          </ul>
        )}
      </QuestionContent>
    </MathJaxContext>
  );
};


export default PreviewChemistryFormula;



// const MathInputComponent = ({ currentQuestion, editMode }) => {
//   const containerRef = useRef(null);

//   useEffect(() => {
//     if (!containerRef.current) return;

//     // Create a math-field element
//     const mathInput = document.createElement('math-field');
//     // @ts-ignore
//     mathInput.mathModeSpace = '\\:';
//     mathInput.style.width = '100%';
    
//     if (editMode) {
//       // @ts-ignore
//       mathInput.setAttribute('readonly', true);
//     }

//     // Sanitize and set content
//     const sanitizedContent = sanitizeHTMLWithMath(currentQuestion.template_response);
//     mathInput.innerHTML = sanitizedContent;

//     // Replace the container's content with the math-field
//     containerRef.current.innerHTML = '';
//     containerRef.current.appendChild(mathInput);

//     // Trigger MathJax rendering
//     // if (window.MathJax) {
//     //   window.MathJax.startup.promise.then(() => {
//     //     window.MathJax.typesetPromise([mathInput]).catch((err) =>
//     //       console.error('MathJax rendering error:', err)
//     //     );
//     //   });
//     // } else {
//     //   console.error('MathJax is not loaded');
//     // }
//   }, [currentQuestion, editMode]);

//   return <div ref={containerRef} style={{ width: '100%' }}></div>;
// };


