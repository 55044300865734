export const shapeMover = (board, startPoint, endPoint, deltaX, deltaY) => {
  startPoint.moveTo([startPoint.X() + deltaX, startPoint.Y() + deltaY]);
  endPoint.moveTo([endPoint.X() + deltaX, endPoint.Y() + deltaY]);

  // Update the board
  board.update();
};
export const getSnappingValue = (data) => {
  let result = { snapToGrid: false, snapSizeX: 1, snapSizeY: 1 };
  const snapping = data?.layout?.snapto;
  if (snapping === "Grid" || snapping === "grid") {
    result = {
      snapToGrid: true,
      snapSizeX: data?.grid?.x_distance,
      snapSizeY: data?.grid?.y_distance,
    };
  } else if (snapping === "Ticks" || snapping === "ticks") {
    result = {
      snapToGrid: true,
      snapSizeX:
        Number(data?.axis_x?.ticks_distance) ||
        data?.axis_x?.ticks_distance ||
        1,
      snapSizeY: Number(data?.axis_y?.ticks_distance) || 1,
    };
  } else {
    result = {
      snapToGrid: true,
      snapSizeX: Number(snapping),
      snapSizeY: Number(snapping),
    };
  }
  return result;
};

export const getNewCoordsValueAfterSnapping = (value, condition, snap) => {
  return condition ? Math.round(value / snap) * snap : value;
};

export const drawPoint = (
  board: any,
  x: number,
  y: number,
  options: {
    color?: string;
    snapToGrid?: any;
    visible?: boolean;
    name?: string;
    fixed?: boolean;
    draggable?: boolean;
    snapSizeX?: number;
    snapSizeY?: number;
    snapping?: any;
  } = {}
) => {
  const point = board.create("point", [x, y], {
    name: options.name || "",
    size: 2,
    color: options.color || "blue",
    fixed: options.fixed || false,
    visible: true,
    draggable: options.draggable || true,
    ...options,
    ...getSnappingValue(options.snapping),
  });
  return point;
};

export const drawLine = (
  board: any,
  coords: any,
  options: { color?: string; visible?: boolean; strokeColor?: string } = {}
) => {
  const [startCoords, endCoords] = coords;
  const line = board.create(
    "line",
    [
      [startCoords[0], startCoords[1]],
      [endCoords[0], endCoords[1]],
    ],
    {
      strokeWidth: 2,
      strokeColor: options.color || "blue",
      lastArrow: true,
      firstArrow: true,
      fixed: false,
      visible: options.visible || true,
      draggable: true,
      ...options,
    }
  );
  return line;
};

export const drawCircle = (
  board: any,
  coords: any,
  options: { color?: string; visible?: boolean; strokeColor?: string } = {}
) => {
  const [startCoords, endCoords] = coords;
  const pointA = board.create("point", [startCoords[0], startCoords[1]], {
    name: "A",
    size: 2,
    fixed: false,
    visible: true,
    draggable: true,
    ...options,
  });
  const pointB = board.create("point", [endCoords[0], endCoords[1]], {
    name: "B",
    size: 2,
    fixed: false,
    visible: true,
    draggable: true,
    ...options,
  });
  const newPoints = [pointA, pointB];
  const circle = board.create("circle", newPoints, {
    strokeWidth: 2,
    strokeColor: options.strokeColor || "blue",
    fixed: false,
    visible: options.visible || true,
    draggable: true,
    ...options,
  });
  return circle;
};

let pointALabel = "";
let pointBLabel = "";
let shapeLabel = "";
export function* generateTwoPointsShape(
  board,
  mode = "draw",
  snapping,
  visibility
) {
  // Wait for startCoords
  const startCoords = yield;
  if (
    !startCoords ||
    typeof startCoords.x !== "number" ||
    typeof startCoords.y !== "number"
  ) {
    throw new Error("Invalid startCoords passed to generator");
  }
  // console.log(pointALabel, "labelInGenerator");
  pointALabel = startCoords.label;
  const pointA = board.create("point", [startCoords.x, startCoords.y], {
    name: pointALabel,
    withLabel: pointALabel ? true : false,
    size: 3,
    visible: visibility,
    draggable: mode === "draw" ? true : false,
    color: mode === "draw" ? "blue" : mode === "shaded" ? "gray" : "green",
    fixed: mode !== "draw" ? true : false,
    ...getSnappingValue(snapping),
  });
  yield pointA;

  // Wait for endCoords
  const endCoords = yield;
  if (
    !endCoords ||
    typeof endCoords.x !== "number" ||
    typeof endCoords.y !== "number"
  ) {
    throw new Error("Invalid endCoords passed to generator");
  }
  pointBLabel = endCoords.label;
  const pointB = board.create("point", [endCoords.x, endCoords.y], {
    name: pointBLabel,
    withLabel: pointBLabel ? true : false,
    size: 3,
    visible: visibility,
    draggable: mode === "draw" ? true : false,
    color: mode === "draw" ? "blue" : mode === "shaded" ? "gray" : "green",
    fixed: mode !== "draw" ? true : false,
    ...getSnappingValue(snapping),
  });
  yield pointB;

  // Wait for shapeType
  const shapeDetails = yield;

  if (!shapeDetails) {
    throw new Error("Invalid shapeType passed to generator");
  }
  let shape;
  switch (shapeDetails.type) {
    case "line":
    case "segment":
    case "ray":
    case "circle":
    case "arrow":
    case "vector":
      shapeLabel = shapeDetails.options.name;
      shape = board.create(shapeDetails.type, [pointA, pointB], {
        strokeWidth: 2,
        strokeColor:
          mode === "draw" ? "blue" : mode === "shaded" ? "gray" : "green",
        name: shapeLabel || "",
        withLabel: shapeLabel ? true : false,
        label: {
          fontSize: 14,
          fontWeight: "bold",
          fontFamily: "Arial",
          visible: true,
          textAlign: "center",
          textBaseline: "middle",
          opacity: 0.8,
          anchorX: "middle",
          anchorY: "middle",
          position: "top",
          distance: 2,
          autoPosition: true,
          autoPositionMaxDistance: true,
          autoPositionMinDistance: true,
        },
        ...shapeDetails.options,
      });
      break;

    case "parabola":
      // Implement specific logic for parabolas
      shapeLabel = shapeDetails.options.name;
      const a = () =>
        (pointB.Y() - pointA.Y()) / Math.pow(pointB.X() - pointA.X(), 2);
      shape = board.create(
        "functiongraph",
        [(x) => a() * Math.pow(x - pointA.X(), 2) + pointA.Y()],
        {
          strokeWidth: 2,
          strokeColor: mode === "draw" ? "blue" : "green",
          fixed: mode !== "draw" ? true : false,
          visible: true,
          draggable: mode === "draw" ? true : false,
          name: shapeLabel || "",
          withLabel: shapeLabel ? true : false,
          label: {
            fontSize: 14,
            fontWeight: "bold",
            fontFamily: "Arial",
            visible: true,
            textAlign: "center",
            textBaseline: "middle",
            opacity: 0.8,
            anchorX: "middle",
            anchorY: "middle",
            position: "top",
            distance: 2,
            autoPosition: true,
            autoPositionMaxDistance: true,
            autoPositionMinDistance: true,
          },
          ...shapeDetails.options,
        }
      );
      break;

    case "sine":
      // Implement specific logic for sine waves
      shapeLabel = shapeDetails.options.name;
      const updateSine = () => {
        const x1 = pointA.X();
        const y1 = pointA.Y();
        const x2 = pointB.X();
        const y2 = pointB.Y();

        const period = 2 * Math.abs(x2 - x1); // Set period proportional to the x-distance
        const frequency = (2 * Math.PI) / period;
        const phaseShift = (x1 + x2) / 2; // Use the midpoint of x-coordinates as the phase shift
        const amplitude = Math.abs(y2 - y1) / 2; // Half the vertical difference
        const verticalShift = (y1 + y2) / 2; // Midpoint of y-coordinates

        // Sine function
        return function (x) {
          return (
            amplitude * Math.sin(frequency * (x - phaseShift)) + verticalShift
          );
        };
      };
      let sineFunction = updateSine();

      shape = board.create("functiongraph", [sineFunction], {
        strokeWidth: 2,
        strokeColor: mode === "draw" ? "blue" : "green",
        fixed: mode !== "draw" ? true : false,
        visible: true,
        draggable: mode === "draw" ? true : false,
        name: shapeLabel || "",
        withLabel: shapeLabel ? true : false,
        label: {
          fontSize: 14,
          fontWeight: "bold",
          fontFamily: "Arial",
          visible: true,
          textAlign: "center",
          textBaseline: "middle",
          opacity: 0.8,
          anchorX: "middle",
          anchorY: "middle",
          position: "top",
          autoPosition: true,
          autoPositionMaxDistance: true,
          autoPositionMinDistance: true,
        },
        ...shapeDetails.options,
      });
      break;

    default:
      throw new Error(`Unsupported shape type: ${shapeDetails.type}`);
  }

  yield shape; // Yield the created shape
}

export const drawVector = (
  board: any,
  coords: any,
  options: { color?: string; visible?: boolean; strokeColor?: string } = {}
) => {
  const [startCoords, endCoords] = coords;
  const pointA = [startCoords[0], startCoords[1]];
  const pointB = [endCoords[0], endCoords[1]];
  const vector = board.create("arrow", [pointA, pointB], {
    strokeWidth: 2,
    strokeColor: options.color || "blue",
    fixed: false,
    visible: options.visible || true,
    draggable: true,
    ...options,
  });
  return vector;
};

export const drawRay = (
  board: any,
  coords: any,
  options: { color?: string; visible?: boolean; strokeColor?: string } = {}
) => {
  const [startCoords, endCoords] = coords;
  const newPoints = [
    [startCoords[0], startCoords[1]],
    [endCoords[0], endCoords[1]],
  ];
  const line = board.create("line", newPoints, {
    strokeWidth: 2,
    strokeColor: options.color || "blue",
    lastArrow: true,
    straightFirst: false, // Do not extend in the negative direction
    straightLast: true,
    fixed: false,
    visible: options.visible || true,
    draggable: true,
    ...options,
  });
  return line;
};

export const drawSegment = (
  board: any,
  coords: any,
  options: { color?: string; visible?: boolean; strokeColor?: string } = {}
) => {
  const [startCoords, endCoords] = coords;
  const segment = board.create(
    "segment",
    [
      [startCoords[0], startCoords[1]],
      [endCoords[0], endCoords[1]],
    ],
    {
      strokeWidth: 2,
      strokeColor: options.color || "blue",
      fixed: false,
      visible: options.visible || true,
      draggable: true,
      ...options,
    }
  );
  segment.on("drag", () => {});
  // return {startPoint, endPoint, segment};
  return segment;
};

export const drawParabola = (
  board: any,
  coords: any,
  options: { color?: string; visible?: boolean; strokeColor?: string } = {}
) => {
  const [startCoords, endCoords] = coords;
  const vertex = board.create("point", [startCoords[0], startCoords[1]], {
    visible: false,
  });
  const pointOnParabola = board.create("point", [endCoords[0], endCoords[1]], {
    visible: false,
  });
  const a = () =>
    (pointOnParabola.Y() - vertex.Y()) /
    Math.pow(pointOnParabola.X() - vertex.X(), 2);
  const parabola = board.create(
    "functiongraph",
    [(x) => a() * Math.pow(x - vertex.X(), 2) + vertex.Y()],
    {
      strokeWidth: 2,
      strokeColor: options.color || "blue",
      fixed: false,
      visible: options.visible || true,
      ...options,
    }
  );
  return parabola;
};

export const drawSine = (
  board: any,
  coords: any,
  options: { color?: string; visible?: boolean; strokeColor?: string } = {}
) => {
  const [startCoords, endCoords] = coords;
  const p1 = board.create("point", [startCoords[0], startCoords[1]], {
    name: "Point",
    visible: false,
    fixed: false,
  });
  const p2 = board.create("point", [endCoords[0], endCoords[1]], {
    name: "Point",
    visible: false,
    fixed: false,
  });

  // Calculate sine parameters
  const updateSine = () => {
    const x1 = p1.X();
    const y1 = p1.Y();
    const x2 = p2.X();
    const y2 = p2.Y();

    const period = 2 * Math.abs(x2 - x1); // Set period proportional to the x-distance
    const frequency = (2 * Math.PI) / period;
    const phaseShift = (x1 + x2) / 2; // Use the midpoint of x-coordinates as the phase shift
    const amplitude = Math.abs(y2 - y1) / 2; // Half the vertical difference
    const verticalShift = (y1 + y2) / 2; // Midpoint of y-coordinates

    // Sine function
    return function (x) {
      return amplitude * Math.sin(frequency * (x - phaseShift)) + verticalShift;
    };
  };
  let sineFunction = updateSine();

  const sineGraph = board.create("functiongraph", [sineFunction], {
    strokeWidth: 2,
    strokeColor: "blue",
    fixed: false,
    visible: options.visible || true,
    draggable: true,
    ...options,
  });

  board.on("update", () => {
    sineFunction = updateSine();
    sineGraph.Y = sineFunction;
    p1.setPosition(JXG.COORDS_BY_USER, [Math.cos(p1.X()), Math.sin(p1.X())]);
    p2.setPosition(JXG.COORDS_BY_USER, [p2.X(), Math.sin(p2.X())]);
  });

  return sineGraph;
};

let polygonPoints = []; // Store JSXGraph points of the polygon
let firstPoint = null; // Reference to the first point
let segments = []; // Store segments between points
let polygonShape = null; // Reference to the created polygon
let polygonState = false; // Track if the polygon is finalized

export const drawPolygon = (board, coords, options) => {
  const { x, y } = coords;
  resetDrawing();
  polygonState = false;

  // Snap the input coordinates to the grid
  const snapToGrid = (value, snapSize) =>
    Math.round(value / snapSize) * snapSize;
  const snappedX = snapToGrid(x, options.snapSizeX || 1);
  const snappedY = snapToGrid(y, options.snapSizeY || 1);

  // Check if the first point is clicked again to close the polygon
  if (
    firstPoint &&
    Math.abs(firstPoint.X() - snappedX) < 0.1 &&
    Math.abs(firstPoint.Y() - snappedY) < 0.1
  ) {
    if (polygonPoints.length > 2) {
      polygonShape = board.create("polygon", polygonPoints, {
        fillColor: "rgba(0, 100, 250, 0.3)",
        borders: { strokeColor: "blue", strokeWidth: 2 },
        hasLabel: false,
      });
    }
    polygonState = true;
    return { polygonState, polygonShape, polygonPoints };
  }

  const newPoint = board.create("point", [snappedX, snappedY], {
    name: "",
    color: polygonPoints.length === 0 ? "orange" : "blue",
    fixed: false,
    visible: true,
    snapToGrid: options.snapToGrid,
    snapSizeX: options.snapSizeX,
    snapSizeY: options.snapSizeY,
    hasLabel: false,
  });

  // Add the point to the polygon points list
  polygonPoints.push(newPoint);

  // Draw a segment between the last two points
  if (polygonPoints.length > 1) {
    const lastPoint = polygonPoints[polygonPoints.length - 2];
    if (lastPoint.board.id === newPoint.board.id) {
      const segment = board.create("segment", [lastPoint, newPoint], {
        strokeColor: "blue",
        strokeWidth: 2,
        fixed: false,
        visible: true,
        draggable: true,
      });
      segments.push(segment);
    } else {
      resetForNewPolygon(board, newPoint, options);
    }
  }

  // Save the first point reference
  if (polygonPoints.length === 1) {
    firstPoint = newPoint;
  }

  return {
    polygonState,
    polygonShape,
    polygonPoints,
  };
};

export const resetDrawing = () => {
  if (polygonShape && polygonState) {
    polygonPoints = [];
    firstPoint = null;
    segments = [];
    polygonShape = null;
    polygonState = false;
  }
};
export const resetForNewPolygon = (board, point, options) => {
  polygonPoints.forEach((point) => point.board.removeObject(point));
  segments.forEach((segment) => segment.board.removeObject(segment));
  polygonPoints = [];
  firstPoint = null;
  segments = [];
  polygonShape = null;
  polygonState = false;
  drawPolygon(board, { x: point.X(), y: point.Y() }, options);
};
let polygonLabel = "";
export const shapesRenderer = (
  board,
  shapes,
  isUpdatePoints,
  graphPoints,
  mode = "draw",
  snapping,
  renderOptions
) => {
  // if (isUpdatePoints) graphPoints.current = [];
  // console.log("shapesRenderer", shapes);
  shapes.forEach((option) => {
    switch (option.type) {
      case "Point":
        const point = drawPoint(board, option.coords.x, option.coords.y, {
          ...option.options,
          ...renderOptions,
          color:
            mode === "draw" ? "blue" : mode === "shaded" ? "gray" : "green",
          fixed: mode === "draw" ? false : true,
          draggable: mode === "draw" ? true : false,
          size: 3
        });
        // setGraphPoints((prevPoints) => [...prevPoints, point]);
        if (isUpdatePoints)
          graphPoints.current.push({ itemId: option.id, shapeId: point.id });
        board.update();
        break;
      case "Polygon":
        polygonLabel = option.options.name || renderOptions.name;
        const polygonPoints = option.subElementIds.points.map((point) => {
          return {
            points: [point.x, point.y],
            label: point.label,
          };
        });
        const drawnPoints = polygonPoints.map((coords) =>
          board.create("point", coords.points, {
            fixed: mode === "shaded" ? true : false,
            visible: mode === "shaded" ? renderOptions.visibilityOnBoard : true,
            name: coords.label || "",
            hasLabel: coords.label ? true : false,
            color:
              mode === "draw" ? "blue" : mode === "shaded" ? "gray" : "green",
          })
        );
        const polygon = board.create("polygon", drawnPoints, {
          fillColor: "rgba(0, 100, 250, 0.3)",
          hasInnerPoints: true,
          draggable: mode === "draw" ? true : false,
          fixed: mode === "draw" ? false : true,
          name: polygonLabel,
          withLabel: polygonLabel ? true : false,
          borders: {
            strokeColor:
              mode === "draw" ? "blue" : mode === "shaded" ? "gray" : "green",
            strokeWidth: 2,
            fixed: true,
            highlight: false,
            visible: true,
            draggable:  false,
            hasInnerPoints: true,
          },
          ...renderOptions,
          ...option.options,
        }) as any;
        // setGraphPoints((prevPoints) => [...prevPoints, polygon]);
        if (isUpdatePoints) {
          graphPoints.current.push({
            itemId: option.id,
            shapeId: polygon.id,
            hasParent: false,
            parentId: null,
          });
          drawnPoints.forEach((point, index) => {
            graphPoints.current.push({
              itemId: point.id,
              shapeId: point.id,
              hasParent: true,
              parentId: polygon.id,
            });
          });
        }
        board.update();
        break;
      default:
        if (Object.keys(buttonsSettings).includes(option.type)) {
          const drawer = generateTwoPointsShape(
            board,
            mode,
            snapping,
            renderOptions.visibilityOnBoard
          );
          drawer.next();
          const pointA = drawer.next({
            x: option.subElementIds.startPoint.x,
            y: option.subElementIds.startPoint.y,
            label: option.subElementIds.startPoint.label,
          }).value;

          drawer.next();
          const pointB = drawer.next({
            x: option.subElementIds.endPoint.x,
            y: option.subElementIds.endPoint.y,
            label: option.subElementIds.endPoint.label,
          }).value;

          drawer.next();
          const shapeDetails = {
            type: buttonsSettings[option.type].shape,
            options: {
              ...buttonsSettings[option.type].options,
              ...option.options,
              ...renderOptions,
            },
          };
          const shape = drawer.next(shapeDetails).value;
          // setGraphPoints((prevPoints) => [...prevPoints, {itemId: option.id, shapeId: shape.id}]);
          if (isUpdatePoints) {
            graphPoints.current.push({
              itemId: option.id,
              shapeId: shape.id,
              hasParent: false,
              parentId: null,
            });
            graphPoints.current.push({
              itemId: pointA.id,
              shapeId: pointA.id,
              hasParent: true,
              parentId: shape.id,
            });
            graphPoints.current.push({
              itemId: pointB.id,
              shapeId: pointB.id,
              hasParent: true,
              parentId: shape.id,
            });
          }
          board.update();
        }
        break;
    }
  });
};
export const buttonsSettings = {
  Line: {
    max: 2,
    draw: drawLine,
    shape: "line",
    options: {
      lastArrow: true,
      firstArrow: true,
    },
  },
  Ray: {
    max: 2,
    draw: drawRay,
    shape: "line",
    options: {
      lastArrow: true,
      straightFirst: false, // Do not extend in the negative direction
      straightLast: true,
    },
  },
  Segment: {
    max: 2,
    draw: drawSegment,
    shape: "segment",
    options: {},
  },
  Vector: {
    max: 2,
    draw: drawVector,
    shape: "arrow",
    options: {},
  },
  Circle: {
    max: 2,
    draw: drawCircle,
    shape: "circle",
    options: {},
  },
  Parabola: {
    max: 2,
    draw: drawParabola,
    shape: "parabola",
    options: {},
  },
  Sine: {
    max: 2,
    draw: drawSine,
    shape: "sine",
    options: {},
  },
  Polygon: {
    max: Infinity,
    draw: drawPolygon,
    shape: "polygon",
    options: {},
  },
};
