import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { BsUpload } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { CiText } from "react-icons/ci";

import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { FileuploadButton } from './styled';
import { acceptedFileTypes } from '../../../utils/data';


const PreviewOtherFileUpload = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {

  console.log(currentQuestion, " Current Question");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const more_options = currentQuestion?.more_options;

  const supportformats = acceptedFileTypes.map((fileType, index) => {
    if (currentQuestion?.options && currentQuestion?.options?.some(item => item.hasOwnProperty(fileType.label))) {
      return fileType.value
    }
    return null;
  })
    .filter(Boolean)
    .join(',');

  //console.log(currentQuestion.settings);
  const maxFileUploadSetting = currentQuestion?.settings?.find(item => item.hasOwnProperty('max_file_upload'));
  //const maxFileUploadSetting = 0;
  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };
 

  const handleGoToEditClick = () => {
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <div className='flex flex-row items-center p-6 mb-2.5 border border-solid border-[#D9D9D9]'   style={more_options && more_options?.layout?.fontSize ? {fontSize:more_options?.layout?.fontSize, width: '100%' } : 
            {width: '100%'}}>
        <div className='border border-solid border-[#D9D9D9] flex-row w-full block '>
          <div className='w-full h-8 block bg-[#D9D9D9] opacity-40' style={
            more_options && more_options?.layout?.fontSize === 'x-large' ? {height:"40px"} : more_options?.layout?.fontSize === 'xx-large' ? {height:"50px"}:{height:"30px"}}  >

            <button className='p-[5px] m-[3px]' disabled><BsUpload /></button>
            <button className='p-[5px] m-[3px]' disabled><CiText /></button>
            <button className='p-[5px] m-[3px]' disabled><MdDelete /></button>
          </div>
          <div className="text-center pt-[52px] pl-[42px] pr-[42px] pb-[42px] ">
            <div>
              <span className='text-center bg-[#1877b1] text-white p-[10px] opacity-40'>
                Uploaded Files</span></div>
            <div className="mt-[16px] pt-[10px]" style={
            more_options && more_options?.layout?.fontSize === 'x-large' ? {paddingTop:"15px"} : more_options?.layout?.fontSize === 'xx-large' ? {paddingTop:"20px"}:{paddingTop:"10px"}} >Supported file formats: {supportformats}</div>

          </div>
          <div className="bg-[#f0f0f0] h-[30px] text-right" style={
            more_options && more_options?.layout?.fontSize === 'x-large' ? {height:"40px"} : more_options?.layout?.fontSize === 'xx-large' ? {height:"50px"}:{height:"30px"}}>
            {maxFileUploadSetting ? (
              <span className="block pt-[8px] pr-[10px]"  style={
                more_options && more_options?.layout?.fontSize === 'x-large' ? {paddingTop:"13px"} : more_options?.layout?.fontSize === 'xx-large' ? {paddingTop:"17px"}:{paddingTop:"8px"}}>0/{maxFileUploadSetting.max_file_upload}  File limit</span>
            ) : (
              <span className="block pt-[8px] pr-[10px]" style={
                more_options && more_options?.layout?.fontSize === 'x-large' ? {paddingTop:"13px"} : more_options?.layout?.fontSize === 'xx-large' ? {paddingTop:"17px"}:{paddingTop:"8px"}}>0/0 File limit</span>
            )}

          </div>

        </div>
      </div>

    </PreviewWrapper>
  )
};

export default PreviewOtherFileUpload;