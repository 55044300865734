import styled from 'styled-components';
import { CellProps } from './types';

export const ShadingCell = styled.div<CellProps>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => {
    if (props.isShaded && props.isLocked) return '#cccccc';
    if (props.isSelected || (props.isShaded && !props.isLocked)) return '#ffffff';
    if (props.isHidden) return '#ffffff';
    return '#007bff';
  }};
  
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.isLocked ? 'not-allowed' : 'pointer'};
  border: ${props => {
    if (props.isHidden) return 'none';
    return '2px solid black';
  }};
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;

  &:hover {
    // When the cell is not locked or is hover state is true, apply opacity
    ${props => (!props.isLocked && !props.isHidden && !props.isHoverState) && `
      opacity: 0.3;
    `}

            // If the cell is hidden, no hover effect is applied, even if it is locked
    ${props => (props.isHidden && !props.isHoverState) && `
      opacity: 1;
    `}

      // If isHoverState is true, no hover effect is applied at all
    ${props => props.isHoverState && `
      opacity: 1;
    `}
  }

  ${props => props.showAnswer && `
        ${props.isCorrect && `
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 255, 0, 0.2);
            }
        `}
        ${props.isIncorrect && `
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(255, 0, 0, 0.2);
            }
        `}
    `}
`;
