import React from 'react';
import DOMPurify from 'dompurify';
import { useDrop } from 'react-dnd';
import { ItemTypes } from 'types';

type DropZoneProps = {
  id: number;
  onDrop?: (id: number, word: string) => void;
  filledWord?: string;
  onRemove?: (id: number) => void; // Add this prop
};

type DragItem = {
  label: string;
};


const DropZone = ({ id, onDrop, filledWord, onRemove }: DropZoneProps) => {
  const textRef = React.useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MATCH,
    drop: (item: DragItem) => {
      if (onDrop) {
        onDrop(id, item.label);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // Sanitize and update the inner HTML when `filledWord` changes
  React.useEffect(() => {
    if (textRef.current) {
      textRef.current.innerHTML = DOMPurify.sanitize(filledWord || ""); 
    }
  }, [filledWord]);

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        background: filledWord ? '#F0F8ED' : '#fff',
        border: '1px solid #ccc',
        borderRadius: '6px',
        minWidth: '300px',
        minHeight: '48px',
        padding: '8px',
        margin: '16px 0',
      }}
    >
      <div
        style={{
          background: filledWord ? '#F0F8ED' : '#fff',
        }}
      >
        <span ref={textRef} />
      </div>

      {filledWord && (
        <button
          onClick={() => onRemove && onRemove(id)} // Call onRemove when clicked
          style={{
            position: 'absolute',
            top: '4px',
            right: '4px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: '#ff0000',
          }}
        >
          × {/* You can replace this with an icon */}
        </button>
      )}

      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'rgb(255, 102, 1)',
            borderRadius: '6px',
          }}
        />
      )}
    </div>
  );
};

export default DropZone;