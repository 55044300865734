import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { acceptedFileTypes } from '../../utils/data'
import {ChartQuestionTypes, ChemistryTypes, DrawingTypes} from "../../pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants";
import {handleImageUploadQuestion, handleGridQuestion} from "../../utils/questionUtils";

interface IQuestion {
  name: string;
  description: string;
  grade_id: string;
  subject_id: string;
  score_id: string;
  difficult_id: string;
  notes: string;
  source: string;
  acknowledge: string;
  layout: {};
  tags: object[];
  status: boolean | number | string;
  access: boolean | number | string;
  sub_questions: object[];

  subQuestions: object;
}

const initialState: IQuestion = {
  name: '',
  description: '',
  grade_id: '',
  subject_id: '',
  score_id: '',
  difficult_id: '',
  notes: '',
  source: '',
  acknowledge: '',
  layout: null,
  tags: [],
  status: false,
  access: false,
  sub_questions: [],
  subQuestions: {},
};

const QuestionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setVal: (state, action) => {
      const { key, val } = action.payload;
      state[key] = val;
    },
    removeQuestionType: (state, action) => {
      const { type } = action.payload;
      // Create a new state object without the specified type

      if (type) {
        // Remove type from subQuestions
        const subQuestionsCopy = { ...state.subQuestions };
        Object.keys(subQuestionsCopy).forEach((key) => {
          if (subQuestionsCopy[key].type === type) {
            delete subQuestionsCopy[key];
          }
        });

        // Remove type from sub_questions
        const subQuestionsListCopy = state.sub_questions.filter(
            // @ts-ignore
            (item) => item.type !== type
        );

        return {
          ...state,
          subQuestions: subQuestionsCopy,
          sub_questions: subQuestionsListCopy,
        };
      }
    },
    resetData: () => {
      return initialState;
    },
    resetSubQuestions: (state, action) => {
      return {...state, subQuestions: {}, sub_questions: []}
    },
    setKeyValue: (state, action) => {
      const { key, subKey, value } = action.payload;

      // Check if the 'subQuestions' object exists in the state
      if (!state.subQuestions) {
        state.subQuestions = initialState.subQuestions;
      }
      // Check if the 'key' exists in 'subQuestions'
      if (!state.subQuestions[key]) {
        // If 'key' does not exist, create a new object with the 'key' and 'subKey'
        state.subQuestions[key] = { [subKey]: value };
      }
      if (
          key === ChemistryTypes.CHEM_FORMULA ||
          key === 'clozechemistry' ||
          key === 'ClozeMath' ||
          key === 'ClozeMathWImage'
      ) {
        state.subQuestions[key] = value;
      }
      // Add type "cwdad" when the key is "clozeDragDrop"
      if (key === 'cwdad') {
        state.subQuestions[key].type = 'cwdad';
        state.subQuestions[key][subKey] = value;
      }

      // Add type "cwdad" when the key is "clozeDropDown"
      if (key === 'clozeDropDown') {
        state.subQuestions[key].type = 'cwdd';
        state.subQuestions[key][subKey] = value;
        if (subKey === 'options') {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            [subKey]: JSON.parse(value),
          };
        }
      }
      // Add type "cwdad" when the key is "clozeWithText"
      if (key === 'clozeWithText') {
        state.subQuestions[key].type = 'cwt';
        state.subQuestions[key][subKey] = value;
      }
      if (key === 'liwdd') {
        state.subQuestions[key].type = 'liwdd';

        if (['textHover', 'width', 'alt', 'source', 'height'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else if (['duplicate'].includes(subKey)) {
          if (!state.subQuestions[key].settings) {
            state.subQuestions[key].settings = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].settings = {
              ...state.subQuestions[key].settings,
              [subKey]: value,
            }
          }
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'liwt' || key === 'liwddo') {
        state.subQuestions[key].type = key;
        if (['textHover', 'width', 'alt', 'source', "height"].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'imup') {
        handleImageUploadQuestion(state, key, subKey, value);
        return;
      }


      if (key === 'shading') {
        if (!state.subQuestions[key].type) {
          state.subQuestions[key].type = 'shad';
        }

        if ([
          'row_count',
          'column_count',
          'cell_width',
          'cell_height',
          'shaded',
          'canvas_container_style',
          'image',
          'title',
          'alt',
          'lock_shaded_cell',
          'hidden'
        ].includes(subKey)) {
          if (!state.subQuestions[key].options) {
            state.subQuestions[key].options = {};
          }
          state.subQuestions[key].options = {
            ...state.subQuestions[key].options,
            [subKey]: value,
          };
        } else if(['possible_responses'].includes(subKey)){
          console.log(value)
          console.log(value?.value)
          const extractedValue = value && Array.isArray(value.value) ? value.value : [];
          state.subQuestions[key].options = {
            ...state.subQuestions[key].options,
            possible_responses:{
              value: extractedValue,
            }
          };
        }
        else if (['correct_answer', 'alt_answer'].includes(subKey)) {
          if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {
              valid_response: {
                score: 0,
                value: {
                  method: 'byLocation',
                  value: []
                }
              },
              alt_response: []
            };
          }

          if (subKey === 'alt_answer') {
            // Initialize alt_response array if it doesn't exist
            if (!state.subQuestions[key].correct_answer.alt_response) {
              state.subQuestions[key].correct_answer.alt_response = [];
            }

            const altIndex = value.altIndex;

            // Handle new alternative answer
            if (altIndex >= state.subQuestions[key].correct_answer.alt_response.length) {
              state.subQuestions[key].correct_answer.alt_response.push({
                score: value.score || 0,
                value: {
                  method: value.method || 'byLocation',
                  value: value.value || []
                }
              });
            }
            // Update existing alternative answer
            else if (altIndex >= 0) {
              const currentAlt = state.subQuestions[key].correct_answer.alt_response[altIndex];
              state.subQuestions[key].correct_answer.alt_response[altIndex] = {
                score: value.score !== undefined ? value.score : currentAlt.score,
                value: {
                  method: value.method !== undefined ? value.method : currentAlt.value.method,
                  value: value.value !== undefined ? value.value : currentAlt.value.value
                }
              };
            }
          }
          else if (subKey === 'correct_answer') {
            // Update the correct answer with both the score and other properties without losing existing values
            state.subQuestions[key].correct_answer.valid_response = {
              score: value.score !== undefined ? value.score : state.subQuestions[key].correct_answer.valid_response.score,
              value: {
                method: value.method !== undefined ? value.method : state.subQuestions[key].correct_answer.valid_response.value.method,
                value: value.value !== undefined ? value.value : state.subQuestions[key].correct_answer.valid_response.value.value
              }
            };
          }
        }
        else if (subKey === '') {
          state.subQuestions[key] = value;
        }
        else if (['more_options'].includes(subKey)) {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            more_options: value
          };
        }

        else if (['hideCells'].includes(subKey)) {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            more_options: {
              ...state.subQuestions[key]?.more_options,
              layout: {
                ...state.subQuestions[key]?.more_options?.layout,
                hideCells: value
              }
            }
          };
        }
        else {
          state.subQuestions[key][subKey] = value;
        }
      }
      // if (key === DrawingTypes.TOKEN_HIGHLIGHT) {
      //   state.subQuestions[key][subKey] = value;
      // }

      if (
        [
          'mcs',
          'mcmr',
          'taf',
          'mcbl',
          'cms',
          'cmi',
          'cml',
          'machli',
          'class',
          'ordlis',
          'sortlis',
          'math',
          'clozmat',
          'clozmwIm',
          'liwm',
          'mewrt',
          'mqg',
          'ewrt',
          'st',
          'cmsnl',
          'ar',
          'ewpt',
          ChartQuestionTypes.BARCHART,
          ChartQuestionTypes.HISTOGRAM,
          ChartQuestionTypes.LINECHART,
          ChartQuestionTypes.DOTPLOT,
          ChartQuestionTypes.LINEPLOT,
          DrawingTypes.DRAWING,
          DrawingTypes.TOKEN_HIGHLIGHT,
          'graph',
          'rating'
        ].includes(key)
      ) {
        state.subQuestions = { ...state.subQuestions, [key]: value };
      }
      if (key === 'fileup') { // Others
        //File Upload Section
        state.subQuestions[key].type = 'fileup';

        if (subKey === 'max_score' || subKey === 'min_score_if_attempted') {
          state.subQuestions[key]["metdata"]["validation"][subKey] = value;
        } else if (subKey === 'distractor_rationale' || subKey === 'rubric_reference' || subKey === 'sample_answer'  || subKey === 'acknowledgements') {
          state.subQuestions[key]["more_options"][subKey] = value;
        } else if(subKey === "photo_capture" || subKey === "max_file_upload"){
          state.subQuestions[key]["settings"][subKey] = value;
        }else{
          state.subQuestions[key][subKey] = value;
        }

      }
      // Handle grid questions
      if (key === 'grid') {
        handleGridQuestion(state, key, subKey, value);
        return;
      }
    },
    setQuestionValues: (state, action) => {
      return action.payload;
    },
    setSubQuestionAmal: (state, { payload }: PayloadAction<any>) => {
      state.sub_questions = [payload];
    },
    saveCopySubQuestion: (state, action) => {
      const { type } = action.payload;
      let index = state?.sub_questions?.findIndex((object) => {
        // @ts-ignore
        return object?.type === type;
      });
      // Create a new object with filtered subQuestions
      const filteredSubQuestions = Object.fromEntries(
          Object.entries(state?.subQuestions).filter(([key, value]) => {
            return value.type === type;
          })
      );

      // Check if there are any values
      if (Object.keys(filteredSubQuestions).length > 0) {
        // Get the first (and only) value
        const values = Object.values(filteredSubQuestions)[0];

        if (index === -1) {
          // @ts-ignore
          state?.sub_questions?.push(values);
        } else {
          state.sub_questions[index] = values;
        }
      } else {
        // Handle the case where there are no matching subQuestions
        console.log('No matching subQuestions found');
      }
    },
  },
});

export const {
  setVal,
  resetData,
  resetSubQuestions,
  setKeyValue,
  setQuestionValues,
  removeQuestionType,
  saveCopySubQuestion,
  setSubQuestionAmal,
} = QuestionSlice.actions;

export default QuestionSlice.reducer;