const PlusIcon = () => {
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
      >
        <path
          d="M7.91048 6.12515L14.0635 6.12515L14.0635 7.87485L7.91048 7.87484L7.91048 14L6.15284 14L6.15284 7.87484L-0.000161515 7.87484L-0.000161439 6.12515L6.15284 6.12515L6.15284 -3.45785e-07L7.91048 -2.68956e-07L7.91048 6.12515Z"
          fill="white"
        />
      </svg>
    )
}

export default PlusIcon;