import React, { useEffect, useState } from 'react';
import FormatQuestion from '../../AuthorQuestion/FillBlanks/SubChildren/ClozeDragDrop/FormatQuestion';
import Word from '../../AuthorQuestion/FillBlanks/SubChildren/ClozeDragDrop/Word';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';

const PreviewclozeDragDrop = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [filledWords, setFilledWords] = useState([].fill(null));
  const [_responses, set_responses] = useState([]);

  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const more_options = currentQuestion?.more_options;

  useEffect(() => {
    if (showAnswer || localShowAnswer) {
      const correctAnswerJsonString = currentQuestion?.correct_answer?.valid_response && 
        Array.isArray(currentQuestion?.correct_answer?.valid_response?.value) ? 
        currentQuestion?.correct_answer?.valid_response?.value : 
        [];

      if (correctAnswerJsonString) {
        try {
          const parsedCorrectAnswer = correctAnswerJsonString;
          setFilledWords(parsedCorrectAnswer);
          set_responses(parsedCorrectAnswer);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
    } else {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        currentQuestion?.template_response,
        'text/html'
      );

      const elements = Array.from(doc.body.children);
      let currentId = 0;

      const responses = elements.flatMap((element, key) => {
        const result = [];
        let position = 0;
        if (element.tagName === 'P') {
          const childNodes = Array.from(element.childNodes);

          childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
              const wordsArray = node.textContent.trim().split(/\s+|&nbsp;/);
              wordsArray.forEach((word) => {
                const cleanedWord =
                  word === '<p><br></p>'
                    ? '*'
                    : word === '<p>&nbsp;</p>'
                    ? '*'
                    : word;
                result.push({
                  type: 'txt',
                  content: cleanedWord,
                  id: currentId,
                  position: position++,
                });
                if (cleanedWord === 'Response') {
                  result.push({
                    type: 'Response',
                    content: cleanedWord,
                    id: currentId,
                    position: position++,
                  });
                }
              });
            } else if (
              node.nodeType === Node.ELEMENT_NODE &&
              (node as Element).tagName === 'IMG'
            ) {
              const imgElement = node as HTMLImageElement;
              result.push({
                type: 'img',
                content: imgElement.src,
                width: imgElement.width,
                height: imgElement.height,
                id: currentId,
                position: position++,
              });
            } else if (
              ( node.nodeType === Node.ELEMENT_NODE &&
                (node as Element).tagName === 'STRONG'
              ) || 
              // @ts-ignore
              node?.childNodes[0]?.tagName === 'STRONG'
            ) {
              const wordsArray = node.textContent.trim().split(/\s+|&nbsp;/);
              wordsArray.forEach((word) => {
                const cleanedWord =
                  word === '<p><br></p>'
                    ? '*'
                    : word === '<p>&nbsp;</p>'
                    ? '*'
                    : word;
                if (cleanedWord === 'Response') {
                  result.push({
                    type: 'Response',
                    content: cleanedWord,
                    id: currentId,
                    position: position++,
                  });
                } else {
                  result.push({
                    type: 'txt',
                    content: cleanedWord,
                    id: currentId,
                    position: position++,
                  });
                }
              });
            }
          });
          currentId++;
        } else if (element.tagName === 'FIGURE') {
          Array.from(element.childNodes).forEach((node, index) => {
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              (node as Element).tagName === 'TABLE'
            ) {
              result.push({
                type: 'table',
                content: (node as Element).outerHTML, // You can use more detailed parsing if needed
                id: currentId,
                position: position++,
              });
            } else if (
              node.nodeType === Node.ELEMENT_NODE &&
              (node as Element).tagName === 'IMG'
            ) {
              const imgElement = node as HTMLImageElement;

              let imgalignment = '';

              if (element.className.includes('image-style-side')) {
                imgalignment = 'right';
                console.log('object');
              } else if (element.className.includes('image')) {
                imgalignment = 'center';
              }

              result.push({
                type: 'img',
                content: imgElement.src,
                width: imgElement.width,
                height: imgElement.height,
                id: currentId,
                position: position++,
                alttext: imgElement.alt,
                imgalignment: imgalignment,
              });
            }
          });

          currentId++;
        }
        return result;
      });

      const modifiedFilledWords = responses?.reduce((result, res) => {
        if (res?.content === '') {
          // If content is an empty string, add a line break
          result.push({ type: 'txt', content: '\n', id: res.id });
        } else {
          // Otherwise, add the original content
          result.push(res);
        }
        return result;
      }, []);

      setFilledWords(modifiedFilledWords);
      set_responses(modifiedFilledWords);
    }
  }, [showAnswer, localShowAnswer]);

  const handleDrop = (index, word) => {
    let updatedWords = [...filledWords];
    const droppedIndex = updatedWords.findIndex(
      (item) => item.id === index && item.content == 'Response'
    );

    // Ensure that the item at droppedIndex is defined
    if (droppedIndex !== -1 && updatedWords[droppedIndex]) {
      updatedWords[droppedIndex]['content'] = word;
      set_responses(updatedWords);
      setFilledWords(updatedWords);
    }
  };

  const handleGoToEditClick = () => {
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
    dispatch(setTypeQuestionOpenInEditMode(null));
  };

  const hanldeLayoutStyle = () => {
    let style = {};
    if (more_options && more_options?.layout?.possibility_list_position) {
      switch (more_options?.layout?.possibility_list_position) {
        case 'top':
          style = {
            display: 'flex',
            flexDirection: 'column-reverse',
          };
          break;
        case 'left':
          style = {
            display: 'flex',
            flexDirection: 'row-reverse',
          };
          break;
        case 'right':
          style = {
            display: 'flex',
            flexDirection: 'row',
          };
          break;

        default:
          style = {};
      }
    }
    return style;
  };

  const handleResponseStyle = (moreOptions, correctAnswers, index) => {
    let style: any = {
      width: 'fit-content', 
      height: 'fit-content', 
      // display: 'inline', 
      // lineHeight: 'normal', 
      // minWidth: 'fit-content'
    };
    const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
    if(moreOptions){
        style = {
            ...moreOptions?.response_options,
            width: moreOptions?.response_options?.width ? moreOptions.response_options.width + 'px' : 'fit-content',
            height: moreOptions?.response_options?.height ? moreOptions.response_options.height + 'px' : 'fit-content',
            fontSize: more_options?.layout.fontSize ? more_options?.layout.fontSize : 'inherit',
        }
    }
    if(moreOptions && resOptionsIndividaul.length > 0) {
        // we collect all responses first
        const allRes = correctAnswers.filter(ca => ca?.type == 'Response');
        const currentResFromcorrectanswers = correctAnswers[index];
        const currentResIndex = allRes.findIndex((res, idx) => currentResFromcorrectanswers === res);
        if(currentResIndex > -1) {
            const currentResOptions = resOptionsIndividaul.find((_item, idx) => idx == currentResIndex);
            if(currentResOptions) {
                style = {
                    ...style,
                    width: currentResOptions?.width ? currentResOptions?.width + 'px' : moreOptions?.response_options?.width ? moreOptions.response_options.width + 'px' : 'fit-content',
                    height: currentResOptions?.height ? currentResOptions?.height + 'px' : moreOptions?.response_options?.height ? moreOptions.response_options.height + 'px' : 'fit-content',
                    fontSize: more_options?.layout.fontSize ? more_options?.layout.fontSize : 'inherit',
                }
            }  
        }
    }
    return style;                                
}

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <div style={hanldeLayoutStyle()}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            //height: "150px",
            border: '1px solid #D9D9D9',
            padding: '24px',
            marginBottom: '10px',
            backgroundColor: '#f0f0f0',
            flexGrow: 1,
          }}
        >
          <div
            style={
              more_options && more_options?.layout.fontSize
                ? { fontSize: more_options?.layout.fontSize }
                : { width: '100%' }
            }
          >
            {filledWords.map((res, index) => (
              <React.Fragment key={index}>
                {index > 0 &&
                  filledWords[index].id === filledWords[index - 1].id && (
                    <span>&nbsp;</span>
                  )}
                {index > 0 &&
                  filledWords[index].id !== filledWords[index - 1].id && <br />}
                <span
                  draggable
                  //onDragStart={(e) => handleDragStart(e, index)}
                  //onDragOver={handleDragOver}
                  // style={{ display: 'inline-block' }}
                >
                  {res.type === 'Response' ? (
                    <FormatQuestion
                      key={res.id}
                      id={res.id}
                      onDrop={(word, id) => handleDrop(id, word)}
                      filledWord={filledWords[index].content}
                      style={handleResponseStyle(more_options, filledWords, index)}
                    />
                  ) : res.type === 'img' ? (
                        res.imgposition === '' ? (
                          <img
                            key={index}
                            src={res.content}
                            width={res.width}
                            height={res.height}
                            alt={res.content}
                          />
                        ) : (
                          <div style={{ textAlign: res.imgalignment }}>
                            <img
                              key={index}
                              src={res.content}
                              width={res.width}
                              height={res.height}
                              alt={res.alttext}
                              style={{ margin: 'auto', display: 'inline' }}
                            />
                          </div>
                        )
                      ) : res.type === 'table' ? (
                        <span
                          className="answertblclass"
                          dangerouslySetInnerHTML={{ __html: res.content }}
                        />
                  ) : (
                    // <p key={index} style={{ display: 'inline' }}>{res.content}</p>
                    <span 
                      key={index} 
                      //style={{ display: 'inline', lineHeight: 'normal',}}
                      style={
                        more_options
                          ? {
                              ...more_options?.response_options,
                              width: more_options?.response_options?.width
                                ? more_options.response_options.width + 'px'
                                : 'fit-content',
                              height: more_options?.response_options?.height
                                ? more_options.response_options.height + 'px'
                                : 'fit-content',
                              fontSize: more_options?.layout.fontSize
                                ? more_options?.layout.fontSize
                                : 'inherit',
                              //minWidth: 'fit-content',
                              dispaly: 'inline', 
                              lineHeight: 'normal', 
                            }
                          : { width: 'fit-content', height: 'fit-content', dispaly: 'inline', lineHeight: 'normal' }
                      }
                    >
                      {res.content}
                    </span>
                  )}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection:
              more_options &&
              more_options?.layout?.possibility_list_position &&
              ['right', 'left'].includes(
                more_options?.layout?.possibility_list_position
              )
                ? 'column'
                : 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {Array.isArray(currentQuestion?.options) ? (
            currentQuestion?.options.map((word, index) => (
              <Word key={index} word={word} />
            ))
          ) : (
            <p>No options available</p>
          )}
        </div>
      </div>
    </PreviewWrapper>
  );
};

export default PreviewclozeDragDrop;
