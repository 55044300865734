import { useEffect, useState } from 'react';
import { QuestionContent } from './styled';
import Button from '../../../components/Button';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DrawingTypes } from 'pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants';
import QuestiontypeModal from 'components/QuestiontypeModal';
import EditCorrectAnswer from 'pages/AuthorQuestion/HighlightDrawing/tokenHighlightsComponents/EditCorrectAnswer.component';

const PreviewTokenHighlight = ({
  currentQuestion,
  showAnswer = false,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token_templates = currentQuestion?.template_response?.token_templates;
  const validResponse = currentQuestion?.correct_answer?.valid_response;
  const moreOptions = currentQuestion?.more_options || {};   
//   const more_options = currentQuestion?.more_options;
//   const layout = more_options?.layout;
//   const initialResponse = {
//     value: currentQuestion.template_response.chartData,
//     score: 0,
//   }
  const [userResponse, setUserResponse] = useState(null);

  useEffect(() => {
    if(!userResponse) {
        setUserResponse({value: token_templates, score: 0});
    }
  }, [])

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode({type: DrawingTypes.TOKEN_HIGHLIGHT, id: currentQuestion?.id}));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };


  function updateUserSelection(validResponse, userResponse) {
    // Helper function to update the 'userSelectedCorrect', 'userSelected', and 'isCorrect' fields
    const updateSelection = (validItem, userItem) => {
        // Ensure both validItem and userItem are not null or undefined
        if (validItem && userItem) {
            // Clone the validItem (if it's immutable) to ensure you're not modifying frozen objects
            const clonedItem = { ...validItem };

            // Add 'userSelected' field based on both 'isToken' and 'isCorrect' properties of userItem
            const userSelected = (userItem.isToken === true && userItem.isCorrect === true);

            clonedItem.userSelected = userSelected;

            // Set 'userSelectedCorrect' to true only if userSelected is true and the item is correct
            if (userSelected && userItem.isCorrect === true && userItem.isToken === true) {
                clonedItem.userSelectedCorrect = true; // Correct selection
            } else {
                clonedItem.userSelectedCorrect = false; // Incorrect selection or not selected
            }

            // Return the modified clone
            return clonedItem;
        }
        return validItem; // Return the original item if invalid
    };

    // Function to handle nested iteration for paragraphs, sentences, and words
    const updateItems = (validItems, userItems, itemType) => {
        // Ensure validItems and userItems are arrays before proceeding
        if (Array.isArray(validItems) && Array.isArray(userItems)) {
            return validItems.map((validItem, i) => {
                const userItem = userItems[i];
                if (userItem) {
                    if (itemType === 'paragraph') {
                        return updateSelection(validItem, userItem);
                    } else if (itemType === 'sentence') {
                        return {
                            ...validItem,
                            lineSentences: validItem.lineSentences.map((validSentenceItem, j) => {
                                const userSentenceItem = userItem.lineSentences[j];
                                return userSentenceItem
                                    ? updateSelection(validSentenceItem, userSentenceItem)
                                    : validSentenceItem;
                            }),
                        };
                    } else if (itemType === 'word') {
                        return {
                            ...validItem,
                            lineWords: validItem.lineWords.map((validWordItem, j) => {
                                const userWordItem = userItem.lineWords[j];
                                return userWordItem
                                    ? updateSelection(validWordItem, userWordItem)
                                    : validWordItem;
                            }),
                        };
                    }
                }
                return validItem; // Return the original item if userItem is missing
            });
        }
        return validItems; // Return the original validItems if not arrays
    };

    // Ensure validResponse and userResponse are not null or undefined
    if (!validResponse || !userResponse) {
        console.error("validResponse or userResponse is null or undefined.");
        return null; // Early return in case the inputs are invalid
    }

    // Ensure the 'value' properties exist in both validResponse and userResponse
    const validResponseValue = validResponse.value;
    const userResponseValue = userResponse.value;

    if (!validResponseValue || !userResponseValue) {
        console.error("validResponse.value or userResponse.value is null or undefined.");
        return null; // Early return if 'value' property is missing
    }

    // Clone validResponse and userResponse to prevent mutation
    const clonedValidResponse = { ...validResponse, value: { ...validResponseValue } };
    const clonedUserResponse = { ...userResponse, value: { ...userResponseValue } };

    // Update paragraphs, sentences, and words
    clonedValidResponse.value.paragraphs = updateItems(clonedValidResponse.value.paragraphs, clonedUserResponse.value.paragraphs, 'paragraph');
    clonedValidResponse.value.sentences = updateItems(clonedValidResponse.value.sentences, clonedUserResponse.value.sentences, 'sentence');
    clonedValidResponse.value.words = updateItems(clonedValidResponse.value.words, clonedUserResponse.value.words, 'word');

    return clonedValidResponse;
}
  return (
    <QuestionContent parentMode={parentMode === "question"} style={moreOptions && moreOptions?.layout?.fontSize ? 
      {fontSize: moreOptions.layout?.fontSize}: 
      {fontSize: 'medium'}
    }>      {
        currentQuestion?.more_options?.extras?.instructor_stimulus && !editMode &&
        <div className="p-5 mb-4 bg-blue-100 rounded-md text-black">
          {currentQuestion?.more_options?.extras?.instructor_stimulus}
        </div>
      }
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
          <div className="bg-light-accent flex p-5 gap-3 rounded-lg">
            <p className="font-bold text-sm">Question :&nbsp;</p>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${currentQuestion?.question}`,
              }}
            />
          </div>
        </div>

        <div className="flex gap-4">
          {editMode ? (
            <>
                <Button
                    margin
                    title={'Go to edit'}
                    onClick={handleEditClick}
                />

                <Button
                    title={'Delete'}
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(true);
                    }}
                />

                {isModalOpen && (
                    <QuestiontypeModal
                        // @ts-ignore
                        setIsModalOpen={setIsModalOpen}
                        id={currentQuestion?.id}
                        isModalOpen={isModalOpen}
                    />
                )}
            </>
          ) : (
            <>
              <Button
                margin
                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                onClick={() => {
                  if (setShowAnswers) setShowAnswers(!showAnswer);
                }}
              />

              <Button
                title={'Back to edit'}
                onClick={() => {dispatch(setClosePreview())}}
              />

              <Button
                title={'Reset'}
                onClick={() => {
                    setUserResponse({score: 0, value: token_templates});
                    if (showAnswer) setShowAnswers(!showAnswer);
                }}
              />
            </>
          )}
        </div>
      </div>

     {userResponse && 
      <div>
        <EditCorrectAnswer 
            questionData={currentQuestion}
            handleData={(data) => {
                if(!editMode) {
                    setUserResponse({
                        value: data,
                        score: 0,
                    })
                }
            }}
            activeTab={showAnswer ? updateUserSelection(validResponse,userResponse) : userResponse}
            showAnswer={showAnswer}
        />
      </div>
     }
    </QuestionContent>
  );
};

export default PreviewTokenHighlight;
