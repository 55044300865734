import React, { useState, useCallback } from "react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import QuestiontypeModal from "components/QuestiontypeModal";
import CustomJoditEditor from "components/CustomJoditEditor";
import { createMathliveButton } from "components/CustomJoditEditor/mathliveButton";
import axios from "axios";
import { debounce } from 'utils/generalUtils';
import { RootState } from "../../../redux/store";
import { useSelector } from 'react-redux';
interface PreviewWrittenRecordedMathEssayRTProps {
  currentQuestion: {
    id: number;
    question: string;
    more_options: {
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      validation: {
        max_score: string;
        min_score_if_attempted: number;
      };
      ui_style: {
        fontsize: string;
        min_height: string;
        max_height: string;
        disable_auto_link: string;
        placeholder: string;
        submit_over_limit: boolean;
        spellcheck: boolean;
        is_math?: boolean;
      };
    };
    options: {
      text_blocks: [];
      content: string;
      formatting_options: [];
      max_length: number;
      show_word_limit: string;
      show_word_count: boolean;
    };
    type: string;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewWrittenRecordedMathEssayRT: React.FC<
  PreviewWrittenRecordedMathEssayRTProps
> = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "assessment",
  editMode = false,
}) => {
    const [content, setContent] = useState<string>(
      currentQuestion?.options?.content || ""
    );
    const [isOverLimit, setIsOverLimit] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const maxWords = currentQuestion?.options?.max_length || 0;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const minHeight = currentQuestion.more_options?.ui_style?.min_height || 200;
    const maxHeight = currentQuestion.more_options?.ui_style?.max_height || 200;
    const [wordCount, setWordCount] = useState(0);

    const debouncedSetContent = useCallback(debounce(setContent, 300), []);



    const handleEditorBlur = (newContent: string) => {
      //const text = newContent.trim();
      //const words = text.split(/\s+/).filter((word) => word !== "");


      debouncedSetContent(newContent);
      //setContent(newContent);

      const content = newContent; // You can also use editor.value;

      // Step 2: Remove HTML tags and get plain text
      const textContent = content.replace(/<[^>]*>/g, ' '); // Replace tags with space to avoid merging words

      // Step 3: Trim extra spaces and split the text into words
      const wordsArray = textContent.trim().split(/\s+/); // Split by any whitespace character(s)

      // Step 4: Count the words
      const wordCount = wordsArray.filter(word => word.length > 0).length; // Filter out empty strings

      setWordCount(wordCount);

      if (wordCount > maxWords) {
        setIsOverLimit(true);
      } else {
        setIsOverLimit(false);
      }

    };

    const handleEditClick = () => {
      dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
      navigate(`/edit-subquestion/${currentQuestion.id}`);
    };
    const placeholder = currentQuestion.more_options?.ui_style?.placeholder || '';
    const instructorStimulus =
      currentQuestion.more_options?.metadata?.instructor_stimulus;

    /*  const config = {
       // readonly: false,
       // toolbarAdaptive: true,
       placeholder: placeholder,
       toolbar: { items: currentQuestion?.options?.formatting_options },
     };
    */

     const tokenUser = useSelector((state: RootState) => state.assessments.config.tokenUser);
// Access the token from the state
const localToken = localStorage.getItem('token');
// Determine upload URL and headers
const defaultApiUrl = process.env.REACT_APP_API_URL || "";
const obeAppApiUrl = process.env.REACT_APP_OBE_APP_API_URL || "";
const uploadUrl = localToken || !tokenUser 
    ? `${defaultApiUrl}/api/formupload`
    : `${obeAppApiUrl}/formupload`;
const headers: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
  ...(tokenUser && !localToken ? { Authorization: `Bearer ${tokenUser}` } : {Authorization: `Bearer ${localToken}`}),
};


    const url = window.location.href;
    const regex = /\/edit-question\/([^\/]+)$/;
    const match = url.match(regex);
    const isID = match ? match[1] : null;

    const handleFileUpload = async (file, editor) => {
      
      try {
        const formData = new FormData();
        formData.append("file", file, file.name);
        console.log(formData);

        const response = await axios.post(
          uploadUrl,
          formData,
          { headers }
        );
        console.log(response);

        if (response.status) {
          editor.s.insertHTML(
            `<img src="${response.data.item.src}" alt="Uploaded Image"/>`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }

    const customButtons = [
      {
        name: "uploadImage",
        iconURL: "https://icon-library.com/images/upload-icon/upload-icon-29.jpg", // URL for your custom icon
        exec: (editor) => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "image/*";
          input.click();
          input.onchange = () => {
            const file = input.files[0];
            if (file) {
              console.log(file);
              handleFileUpload(file, editor);
            }
          };
        },
      },
      {
        name: "math",
        iconURL: "https://img.icons8.com/ios/50/000000/sigma.png",
        exec: (editor) => {
          const button = createMathliveButton(editor);
          button.exec();
        },
      },
      {
        name: "Response",
        tooltip: "Response",
        exec: (editor) => {
          const span = document.createElement("span");
          span.style.border = "1px solid black";
          span.style.padding = "5px"; // Add padding for spacing
          span.style.userSelect = "none";
          span.style.pointerEvents = "none";
          span.style.backgroundColor = "rgb(238, 238, 238)";
          span.contentEditable = "false";
          span.innerHTML = "<b>Response</b>";
          span.id = "btnresponse";

          editor.s.insertHTML(span.outerHTML);
        },
      },
    ];
    const formattingOptions: string[] =
      currentQuestion?.options?.formatting_options ?? [];

    const uniqueButtons = new Set<string>(formattingOptions);

    const updatedButtons = customButtons
      .filter(
        (button) =>
          typeof button === "string" ||
          uniqueButtons.has((button as { name: string }).name)
      )
      .filter(
        (button, index, self) =>
          typeof button === "string" ||
          self.findIndex(
            (b) =>
              typeof b !== "string" &&
              (b as { name: string }).name === (button as { name: string }).name
          ) === index
      );

    let getAllItems = updatedButtons.concat(formattingOptions as any);

    const namesWithObjects = new Set(
      getAllItems
        .filter((item) => typeof item === "object" && item !== null)
        .map((item) => item.name)
    );

    const filteredItems = getAllItems.filter((item) => {
      if (typeof item === "object" && item !== null) {
        return true;
      }
      if (typeof item === "string" && !namesWithObjects.has(item)) {
        return true;
      }
      return false;
    });

    const joditConfig = {
      // toolbar: { items: currentQuestion?.options?.formatting_options },
      minHeight: minHeight,
      maxHeight: maxHeight,
      placeholder: placeholder,
      readonly: editMode,
      buttons: filteredItems,

    }
    const fontSize = currentQuestion.more_options.ui_style.fontsize;
    const fontSizeMapping = {
      small: "12px",
      medium: "14px",
      large: "17px",
      "x-large": "20px",
      "xx-large": "24px",
    };
    const mappedFontSize = fontSizeMapping[fontSize] || "14px";

    /* const wordCount =
       content.trim() === "<p><br></p>"
         ? 0
         : content
           .trim()
           .split(/\s+/)
           .filter((word) => word !== "").length;*/

    return (
      <QuestionContent
        parentMode={parentMode === "question"}
        style={{ fontSize: mappedFontSize }}
      >
        <FullWidthContainer>
          <LeftContent>
            {instructorStimulus !== "" && !editMode && (
              <BlockTitle>{instructorStimulus}</BlockTitle>
            )}
            <BlockTitle
              dangerouslySetInnerHTML={{
                __html: `Question: ${currentQuestion?.question}`,
              }}
            />
          </LeftContent>
          <RightContent>
            {editMode ? (
              <>
                <ButtonLink
                  margin
                  title={"Go to edit"}
                  onClick={() => handleEditClick()}
                />
                <ButtonLink
                  margin
                  title={"Delete"}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                />

                {isModalOpen && (
                  <QuestiontypeModal
                    // @ts-ignore
                    setIsModalOpen={setIsModalOpen}
                    id={currentQuestion?.id as any}
                    isModalOpen={isModalOpen}
                  />
                )}
              </>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setClosePreview());
                  }}
                  style={{
                    width: "150px",
                    height: "40px",
                    fontWeight: 600,
                    color: "#FFF",
                    borderRadius: "8px",
                    backgroundColor: "#FA9B31",
                  }}
                >
                  Back to edit
                </button>
              </>
            )}
          </RightContent>
        </FullWidthContainer>
        <div>
          <div className={isOverLimit ? "editor-over-limit" : "editor"}>

            <CustomJoditEditor
              label=""
              value={content}
              onBlur={(newContent) => handleEditorBlur(newContent)}
              allconfig={false}
              userconfig={joditConfig}
              spellCheck={currentQuestion?.more_options?.ui_style?.spellcheck}
              minHeight={currentQuestion?.more_options?.ui_style?.min_height}
              maxHeight={currentQuestion?.more_options?.ui_style?.max_height}
            />


            {/* <CKEditor
            editor={ClassicEditor}
            onReady={(editor) => {
              editor.ui.view.editable.element.style.minHeight = `${minHeight}px`;
              editor.ui.view.editable.element.style.maxHeight = `${maxHeight}px`;
            }}
            data={content}
            config={config}
            disabled={editMode}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleEditorBlur(data);
            }}
          /> */}
          </div>
          <div
            className="flex gap-1 mt-2"
            style={isOverLimit ? { color: "red" } : {}}
          >
              {currentQuestion?.options?.show_word_limit === "alwaysVisible" || currentQuestion?.options?.show_word_limit === "" && (
                  <p>{wordCount} / {maxWords} Word Limit</p>
              )}
              { (currentQuestion?.options?.show_word_limit === "onLimit" && (wordCount < maxWords || wordCount == 0)) && (
                  <p>{wordCount} Word(s)</p>
              )}
              {(currentQuestion?.options?.show_word_limit === "off" && (wordCount > maxWords || wordCount == 0)) && (
                  <p>{wordCount} Word(s)</p>
              )}
              {(currentQuestion?.options?.show_word_limit === "onLimit" && wordCount > maxWords && wordCount !== 0) && (
                  <p>{wordCount} / {maxWords} Word Limit</p>
              )}
          </div>
        </div>
      </QuestionContent>
    );
  };

export default PreviewWrittenRecordedMathEssayRT;
