// myReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {getListDropSubjects} from "../../services/subjects";

interface SubjectsState {
    subjectsList: object[];
    pagination: {};
    metaTags: object[];
    grades: object[];
    dropSubjects?: object[];
}

const initialState: SubjectsState = {
    subjectsList: [],
    pagination:{},
    metaTags: [],
    grades: [],
    dropSubjects: []
};

const mySlice = createSlice({
    name: 'subjects',
    initialState,
    reducers: {
        setSubjects(state, action: PayloadAction<any>) {
            console.log('action', action.payload);
            const { items, pagination, filters } = action.payload;
            console.log('############################################', filters)
            state.subjectsList = items;
            state.pagination = pagination;

            const newMetaTags = filters?.tag_type.map((item) => {
                return { id: item.id, value: item.id , label: item.name};
            });
            state.metaTags = [{ id: -1, value: "", label: "Select an option" }, ...newMetaTags];

            const newGrades = filters?.grades.map((item) => {
                return { id: item.id, value: item.id , label: item.name};
            });
            state.grades = [{ id: -1, value: "", label: "Select an option" }, ...newGrades];

        },
        setListDropSubjects(state, action) {
            const { data } = action.payload;

            // Check if data is an array before mapping
            if (!Array.isArray(data)) {
                console.error("Expected data to be an array, but got:", data);
                return; // Early return to avoid further issues
            }

            const dropSubjects = data.map((item) => {
                // Ensure each item has the expected structure
                return {
                    id: item.id,
                    value: item.id,
                    label: item.name,
                    grades: Array.isArray(item?.grades)
                        ? item.grades.map(g => ({
                            id: g?.id,
                            value: g?.id,
                            label: g?.name
                        }))
                        : [],
                };
            });

            // Since we've already checked if data is an array,
            // we can safely assume dropSubjects will be an array.
            state.dropSubjects = [
                // Uncomment if you need the default option
                // { id: -1, value: "", label: "Select an option", grades: [] },
                ...dropSubjects,
            ];
        }

    },
});

export const { setSubjects, setListDropSubjects } = mySlice.actions;
export default mySlice.reducer;
