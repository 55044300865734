import React from "react";
import { UseFieldArrayReturn } from "react-hook-form";
import { AlternateContent, Item } from "../../FillBlanks/styled";
import PlusIcon from "pages/AuthorQuestion/FillBlanks/SubChildren/components/plusIcon.component";
import { Tab } from "./Tab";
import { ResponseButton } from "components/styled";

interface ResponseTabsProps {
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
  fields: UseFieldArrayReturn["fields"];
  onAddAlternative: () => void;
  getScoringValue: (index: number) => number;
  onScoreChange: (value: string, index: number) => void;
  remove: (index: number) => void;
}

const ResponseTabs: React.FC<ResponseTabsProps> = ({
  activeTabIndex,
  setActiveTabIndex,
  fields,
  onAddAlternative,
  getScoringValue,
  onScoreChange,
  remove,
}) => {
  const getTabs = () => [
    "Correct Answer",
    ...fields.map((_, index) => `Alternative ${index + 1}`),
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="text-xl font-bold">Set Correct Answers</p>
        <AlternateContent>
          <ResponseButton onClick={onAddAlternative}>
            <PlusIcon />
            <span>Alternative Answer</span>
          </ResponseButton>
        </AlternateContent>
      </div>
      <div className="flex flex-row border-b border-[#e8e8e8]">
        {getTabs().map((label, index) => (
          <Tab
          key={`${label}-${index}`}
          label={label}
          isActive={index === activeTabIndex}
          onClick={() => setActiveTabIndex(index)}
          tabIndex={index}
          onDelete={(e) => {
            e.preventDefault();
            // Remove the tab at the current index
            remove(index -1);

            // Adjust the active tab index after removal
            if (index === activeTabIndex) {
              // If the active tab is removed, adjust to the previous tab or 0
              setActiveTabIndex(index > 0 ? index - 1 : 0);
            } else if (index < activeTabIndex) {
              // If a tab before the active one is removed, shift the active index back by 1
              setActiveTabIndex(activeTabIndex - 1);
            }
          }}
        />
        ))}
      </div>
      <div className="flex items-center justify-between mb-5 rounded-md">
        <div className="flex items-center gap-2">
          <div>
            <Item
              type="text"
              value={getScoringValue(activeTabIndex)}
              onChange={(e) => onScoreChange(e.target.value, activeTabIndex)}
            />{" "}
            Point(s)
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponseTabs;
