import styled from 'styled-components';
import { PropsWithChildren, useState } from 'react';

interface BackgroundImageProps {
  backgroundImageUrl: string;
  altText?: string; // Add an altText prop
  ariaLabel?: string;
  title?: string;
  width?: string;
  height?: string;
}

export const BackgroundImage = styled.div.attrs<BackgroundImageProps>(
  ({ ariaLabel, title, altText }) => ({
    role: 'img',
    'aria-label': ariaLabel,
    title: title,
  })
)<BackgroundImageProps>`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  background-image: ${({ backgroundImageUrl }) => `url(${backgroundImageUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: gray;
  font-size: 1.2rem;
  background-color: #f3f3f3; /* Fallback color if the image fails to load */
`;

const BackgroundImageContainerWithAltText: React.FC<PropsWithChildren<BackgroundImageProps>> = ({
  backgroundImageUrl,
  altText,
  ariaLabel,
  title,
  width,
  height,
  children,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  return (
    <>
      {/* Hidden img element to detect loading */}
      <img
        src={backgroundImageUrl}
        alt={altText}
        onError={handleImageError}
        style={{ display: 'none' }}
      />
      <BackgroundImage
        backgroundImageUrl={isImageLoaded ? backgroundImageUrl : ''}
        ariaLabel={ariaLabel}
        title={title}
        width={width}
        height={height}
      >
        {!isImageLoaded && <span>{altText}</span>}
        {children}
      </BackgroundImage>
    </>
  );
};

export default BackgroundImageContainerWithAltText;
