import styled from 'styled-components';
import { device } from '../utils/device';
import { Colors } from '../utils/Colors';

export const Container = styled.form<{ padding?: any }>`
  width: 100%;
  display: flex;
  margin-top: -50px;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  border: 1px solid #cccc;
  
  padding: ${(props) => (props.padding ? props.padding : '3px')};

  @media ${device.mobileL} {
    padding: 15px; /* Adjust padding for smaller screens */
  }
`;

export const ContainerBlock = styled.div<any>`
  width: 100%;
  display: flex;
  margin-top: -50px;
  flex-direction: row;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  padding: 0px 30px 20px 0px;
  @media ${device.tablet || device.laptop || device.laptopL} {
    width: 100%;
    padding: 0px;
    flex-direction: column;
  }
`;

export const ContainerInsightBlock = styled.div<any>`
  width: 100%;
  height: 80px;
  display: flex;
  margin-top: -50px;
  flex-direction: row;
  background: #ffffff;
  border-radius: 8px;
  margin: 0px 10px 3em 5px;
  border: 1px solid;
  border-color: #ececec;
  padding: 0px 5px 0px 40px;
  align-items: center;
  justify-content: space-between;
`;

export const StaticCard = styled.div`
  width: 100%;
  min-width: fit-content;
  height: 95px;
  display: flex;
  flex-direction: row;
  padding: 0px 40px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  margin: 0 10px;
  border: 1px solid;
  border-color: #ececec;
  @media ${device.mobileS_tablet} {
    margin: 5px;
    width: 100%;
  }
`;

export const StaticCardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StaticCardTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  text-transform: capitalize;
  color: #2b2b2b;
`;

export const StaticCardNumber = styled(StaticCardTitle)`
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-transform: capitalize;
  color: #35363a;
`;

export const Block = styled.div<{
  marginTop?: any;
  padding?: any;
  marginBottom?: any;
  marginLeft?: any;
}>`
  display: flex;
  /*
  flex: 1 1 0;
  */
  flex-direction: row;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '5px')};
  padding: ${(props) => (props.padding ? props.padding : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Button = styled.button<any>`
  min-width: 11em;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.7rem;
  margin-left: 5px;
  margin-top: 20px;
  background-color: ${(props) => (props.disabled ? '#ccc' : '#FA9B31')};
  border: ${(props) => (props.disabled ? 'none' : '1px solid')};
  color: #fff;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : 'white')};
    color: ${Colors.forestGreen};
    border: 1px solid;
  }
`;

export const CreateButton = styled(Button)`
  background: #fa9b31;
  color: white;
  border-color: #fa9b31;
  &:hover {
    background: #35363a;
  }
`;

export const DiscardButton = styled(Button)`
  background: #fffae8;
  color: #fa9b31;
  border: 1px solid #fa9b31;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
`;

export const BlockRight = styled.div`
  display: flex;
  width: 20%;
  align-self: flex-end;
`;

export const BlockLeft = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;
  align-self: flex-start;
`;

export const CheckboxGroupLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 20px 0;
  color: #35363a;
`;

export const CheckboxInput = styled.input`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => (props.checked ? 'blue' : 'gray')};
  border-radius: 3px;
  background-color: ${(props) => (props.checked ? 'lightblue' : 'white')};
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  color: #d80a0c;
`;

export const TagsName = styled.div`
  height: 45px;
  background: var(--light-accent);
  border-radius: 3.25165px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border: 1px solid #ccc;
`;

export const CustomButton = styled.button<any>`
  width: 100%;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin: 5px 0;
  background-color: ${(props) =>
    !props.disabled && props.backgroundColor
      ? props.backgroundColor ?? Colors.forestGreen
      : Colors.forestGreen};
  border: ${(props) => (props.disabled ? 'none' : '1px solid ')};
  color: ${(props) => (props.color ? props.color : 'white')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;
`;

export const CancelButton = styled.button<any>`
  min-width: 150px;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin: 5px;
  background-color: #fff;
  border: ${(props) => (props.disabled ? 'none' : '1px solid ')};
  color: ${Colors.forestGreen};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? '#ccc' : Colors.forestGreen};
    color: #fff;
`;

export const TitleCustomComponent = styled.div`
  height: 45px;
  width: 100%;
  background: #eeeeee;
  border-radius: 3.25165px;
  margin: 3px;
  display: flex;
  align-items: center;
`;

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-bottom: 2px solid #e8e8e8;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  border-bottom: 2px solid #ccc;
`;

export const Tab = styled.span<{ isActive: boolean }>`
  padding-bottom: 3px;
  padding-bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;

  ${(props) =>
    props.isActive &&
    `font-weight: 600;
    border-bottom: 3px solid  #E93837;
    color: #E93837;
  `}
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafa;
  /*margin: 30px 0px;*/
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0 10px;
`;

export const TableData = styled.div`
  border-top: 1px solid #d9d9d9;
  /* margin: 30px 0px;
  padding: 25px;*/
`;

export const FirstItem = styled.div`
  flex: 0 0 auto;
  padding: 50px 30px;
  border: 1px solid #d9d9d9;
`;

export const SecondItem = styled.div`
  flex: 1;
  padding: 50px 30px;
  border: 1px solid #d9d9d9;
  overflow: hidden;
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
  height: 45px;
  background-color: #eee;
  border-radius: 6px;
  margin: 20px 0;
`;

export const StyledH2 = styled.h2`
  font-weight: 600;
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`;

export const StyledParagraph = styled.p`
  font-weight: 600;
`;

export const StyledHR = styled.hr`
  width: 100%;
  margin-top: 10px;
  border: none;
  height: 1px;
  background: repeating-linear-gradient(
    90deg,
    #eee,
    #eee 6px,
    transparent 6px,
    transparent 12px
  );
`;

export const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const LeftContent = styled.div`
  flex: 1;
`;

export const RightContent = styled.div`
  display: flex;
  margin-left: 20px;
  margin-top: 30px;
  margin-right: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const AddResponseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${Colors.forestGreen};
  height: 37px;
  padding: 10px 60px;
  flex-shrink: 0;
  margin: 20px 10px 20px 0;
  color: white;
  width: fit-content;
  cursor: pointer;
  span {
    margin: 0 5px;
  }
`;

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 15px 10px;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  margin: 20px 0;
  background-color: #fffae8;
`;

export const Title = styled.h4`
  padding: 10px;
  color: #2c2c2c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ResponseButton = styled.div<{ type?: string; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${Colors.forestGreen};
  height: 37px;
  padding: 10px 10px;
  flex-shrink: 0;
  margin: 10px 0;
  color: white;
  width: fit-content;
  cursor: pointer;
  ${(props) =>
    props?.disabled &&
    `background-color: #eeeeee;
    cursor: default;
  `}
  span {
    margin: 0 5px;
  }
`;

export const SubQuestionTitle = styled.div`
  padding: 10px;
  color: #2c2c2c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
