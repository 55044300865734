import { Dispatch } from 'redux';
import axios from 'axios';
import { createAction,createAsyncThunk  } from '@reduxjs/toolkit';
import {cleanAllAnswers} from "redux/slices/PreviewSlice";
import { toast } from 'react-toastify';
// Define action types
const FETCH_ASSESSMENT_REQUEST = 'FETCH_ASSESSMENT_REQUEST';
const FETCH_ASSESSMENT_SUCCESS = 'FETCH_ASSESSMENT_SUCCESS';
const FETCH_ASSESSMENT_FAILURE = 'FETCH_ASSESSMENT_FAILURE';
const SUBMIT_ASSESSMENT_REQUEST = 'SUBMIT_ASSESSMENT_REQUEST';
const SUBMIT_ASSESSMENT_SUCCESS = 'SUBMIT_ASSESSMENT_SUCCESS';
const SUBMIT_ASSESSMENT_FAILURE = 'SUBMIT_ASSESSMENT_FAILURE';
const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';

// Define action creators
export const fetchAssessmentRequest = createAction(FETCH_ASSESSMENT_REQUEST);
export const fetchAssessmentSuccess = createAction<FetchAssessmentSuccessAction>(FETCH_ASSESSMENT_SUCCESS);
export const fetchAssessmentFailure = createAction<FetchAssessmentFailureAction>(FETCH_ASSESSMENT_FAILURE);
export const submitAssessmentRequest = createAction(SUBMIT_ASSESSMENT_REQUEST);
export const submitAssessmentSuccess = createAction(SUBMIT_ASSESSMENT_SUCCESS);
export const submitAssessmentFailure = createAction(SUBMIT_ASSESSMENT_FAILURE);
export const fetchReportsRequest = createAction(FETCH_REPORTS_REQUEST);
export const fetchReportsSuccess = createAction(FETCH_REPORTS_SUCCESS);
export const fetchReportsFailure = createAction(FETCH_REPORTS_FAILURE);

// Define action interfaces
interface FetchAssessmentRequestAction {
  type: typeof FETCH_ASSESSMENT_REQUEST;
}

interface FetchAssessmentSuccessAction {
  type: typeof FETCH_ASSESSMENT_SUCCESS;
  payload: AssessmentPayload;
}

interface FetchAssessmentFailureAction {
  type: typeof FETCH_ASSESSMENT_FAILURE;
  payload: string;
}

interface SubmitAssessmentRequestAction {
  type: typeof SUBMIT_ASSESSMENT_REQUEST;
}

interface SubmitAssessmentSuccessAction {
  type: typeof SUBMIT_ASSESSMENT_SUCCESS;
  payload: any; // Change this to the actual payload type
}

interface SubmitAssessmentFailureAction {
  type: typeof SUBMIT_ASSESSMENT_FAILURE;
  payload: string;
}

interface FetchReportsRequestAction {
  type: typeof FETCH_REPORTS_REQUEST;
}

interface FetchReportsSuccessAction {
  type: typeof FETCH_REPORTS_SUCCESS;
  payload: ReportsPayload;
}

interface FetchReportsFailureAction {
  type: typeof FETCH_REPORTS_FAILURE;
  payload: string;
}

// Combine action types into union types
type AssessmentActionTypes =
    | FetchAssessmentRequestAction
    | FetchAssessmentSuccessAction
    | FetchAssessmentFailureAction;

type SubmitAssessmentActionTypes =
    | SubmitAssessmentRequestAction
    | SubmitAssessmentSuccessAction
    | SubmitAssessmentFailureAction;

type ReportsActionTypes =
    | FetchReportsRequestAction
    | FetchReportsSuccessAction
    | FetchReportsFailureAction;

export const fetchData = async (
    dispatch: Dispatch<AssessmentActionTypes>,
    config: any,
    id: any
) => {
  try {
    dispatch({ type: FETCH_ASSESSMENT_REQUEST });
    // Perform asynchronous operation (e.g., API call)
    let url = config?.role == 'Student' ? `${process.env.REACT_APP_API_URL}/apiv2/assignment/player/${id}` : `${process.env.REACT_APP_API_URL}/apiv2/assessment/${id}`;
    const response = await axios.get(
        url,
        {
          headers: {
            'X-Authorization': `${config?.apiKey}`,
            Accept: 'application/json',
            User: config?.user,
            Role: config?.role,
          },
        }
    );
    let listsQuestions = response?.data?.data?.questions; //?.item

    const keys = []; // keys hidden

    const concatenatedSubQuestions = listsQuestions
        ?.map((obj: any) => obj.sub_questions)
        .reduce((acc: any, subQuestions: any) => acc.concat(subQuestions), [])
        .filter(question => !keys.includes(question.type));

    dispatch({
      type: FETCH_ASSESSMENT_SUCCESS,
      payload: {
        defaultAssessment: response?.data,
        subQuestions: concatenatedSubQuestions,
      },
    });
  } catch (error) {
    console.log('error', error);
    // If the error is of type unknown, refine it to a string before dispatching the action
    const errorMessage =
        typeof error === 'string' ? error : 'An error occurred';

    dispatch({
      type: FETCH_ASSESSMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const fetchDataRedirect = async (
    dispatch: Dispatch<AssessmentActionTypes>,
    config: any,
    id: any,
    token?: string
) => {
  try {
    dispatch({ type: FETCH_ASSESSMENT_REQUEST });
    // Perform asynchronous operation (e.g., API call)
    let url = config?.role == 'Student' ? `https://api.development.obeasses.net/apiservice/assignment/player/${id}` : `https://api.development.obeasses.net/apiservice/assessment/${id}`;
    const response = await axios.get(
        url,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
    );
    let listsQuestions = response?.data?.data?.questions; //?.item

    const keys = []; // keys hidden

    const concatenatedSubQuestions = listsQuestions
        ?.map((obj: any) => obj.sub_questions)
        .reduce((acc: any, subQuestions: any) => acc.concat(subQuestions), [])
        .filter(question => !keys.includes(question.type));

    dispatch({
      type: FETCH_ASSESSMENT_SUCCESS,
      payload: {
        defaultAssessment: response?.data,
        subQuestions: concatenatedSubQuestions,
      },
    });
  } catch (error) {
    console.log('error', error);
    // If the error is of type unknown, refine it to a string before dispatching the action
    const errorMessage =
        typeof error === 'string' ? error : 'An error occurred';

    dispatch({
      type: FETCH_ASSESSMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const submit = async (
    dispatch: Dispatch<AssessmentActionTypes>,
    config,
    assessmentId: string,
    answers: any,
    time: string
) => {
  try {
    // Prevent the function from being called twice
    let hasPostedMessage = false;
    // Dispatch submitAssessmentRequest to indicate submission has started
    // @ts-ignore
    await dispatch(submitAssessmentRequest());

    // Transform the answers as needed
    const transformedData = answers?.map((item: any) => ({
      sub_question_id: item.subQuestionId,
      Answer:  item?.valid_response || item?.selectedAnswer,
    }));

    // Send the request
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/apiv2/submit-answer`,
        {
          assessment_id: assessmentId,
          passed_time: time,
          status: 1, // Fixed value
          answers: transformedData ?? [],
        },
        {
          headers: {
            'X-Authorization': `${config?.apiKey}`,
            Accept: 'application/json',
            User: `${config?.user}`,
            'User-ting': `${config?.user_ting}`,
            Role: `${config?.role}`,
          },
        }
    );

    // Notify success
    //window.parent.postMessage({ type: 'SUCCESS', payload: 'Your answers submit successfully' }, '*');

    if (!hasPostedMessage) {
      window.parent.postMessage({ type: 'SUCCESS', payload: 'Your answers submit successfully' }, '*');
      hasPostedMessage = true;
    }

    // Clean up after successful submission
    // @ts-ignore
    await dispatch(cleanAllAnswers());
  } catch (error) {
    console.error('Error during submission', error);
    const errorMessage = typeof error === 'string' ? error : 'An error occurred';

    // Notify failure
    window.parent.postMessage({ type: 'FAILURE', payload: 'Your answers have failed!' }, '*');
  }
};

export const submitRedirected = async (
    dispatch: Dispatch<SubmitAssessmentActionTypes>,
    assessmentId: string,
    answers: any,
    time: string,
    token?: string,
    obeId?: string,
    user?: string,
    referrer?: string,

) => {
  try {
    // Prevent the function from being called twice
    let hasPostedMessage = false;
    // Dispatch submitAssessmentRequest to indicate submission has started
    // @ts-ignore
    await dispatch(submitAssessmentRequest());

    // Transform the answers as needed
    const transformedData = answers?.map((item: any) => ({
      sub_question_id: item.subQuestionId,
      Answer:  item?.valid_response || item?.selectedAnswer,
    }));
    // Send the request
    const response = await axios.post(
        `https://api.development.obeasses.net/apiservice/submit-answer`,
        {
          assessment_id: assessmentId,
          passed_time: time,
          status: 1, // Fixed value
          answers: transformedData ?? [],
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
    );

    // Log response for debugging
    // Notify success
    //window.parent.postMessage({ type: 'SUCCESS', payload: 'Your answers submit successfully' }, '*');
    dispatch(submitAssessmentSuccess(response.data.message));
    toast.success(response.data.message);
    if (!hasPostedMessage) {
      window.parent.postMessage({ type: 'SUCCESS', payload: 'Your answers submit successfully' }, '*');
      hasPostedMessage = true;
    }

    // Clean up after successful submission
    // @ts-ignore
    await dispatch(cleanAllAnswers());
    window.location.href=`${referrer}Creative%20School/obe-assessments/completed-assessments?assessment_id=${obeId}&obeasses_id=${assessmentId}&user_ting=${user}`;
  } catch (error) {
    console.error('Error during submission', error);
    const errorMessage = typeof error === 'string' ? error : 'An error occurred';
    toast.error('Submission failed! Please try again.');
    // Notify failure
    window.parent.postMessage({ type: 'FAILURE', payload: 'Your answers have failed!' }, '*');
  }
};

export const getReportsByAssessment = async (
    dispatch: Dispatch<ReportsActionTypes>,
    config: any,
    id: any
) => {
  try {
    dispatch({ type: FETCH_REPORTS_REQUEST });
    let data: any = null;
    let error: any = null;
    // Perform asynchronous operation (e.g., API call)
    let url = `${process.env.REACT_APP_API_URL}/apiv2/report/${id}`;
    const response = await axios.get(
        url,
        {
          headers: {
            'X-Authorization': `${config?.apiKey}`,
            Accept: 'application/json'
          },
        }
    )

        .then((body) => {
          data = body.data;
          dispatch({
            type: FETCH_REPORTS_SUCCESS,
            payload: data
          });
        })
        .catch((err) => (error = err.response.data.error));

  } catch (error) {
    console.log('error', error);
    // If the error is of type unknown, refine it to a string before dispatching the action
    const errorMessage =
        typeof error === 'string' ? error : 'An error occurred';

    dispatch({
      type: FETCH_REPORTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getReportBysStudentId = async (
    dispatch: Dispatch<ReportsActionTypes>,
    config: any,
    id: any,
) => {
  try {
    dispatch({ type: FETCH_REPORTS_REQUEST });
    let data: any = null;
    let error: any = null;
    // Perform asynchronous operation (e.g., API call)
    let url = `${process.env.REACT_APP_API_URL}/apiv2/assesemets-studentReport/${id}`;
    const response = await axios.get(
        url,
        {
          headers: {
            'X-Authorization': `${config?.apiKey}`,
            Accept: 'application/json',
            User: `${config?.user_ting}`,
          },
        }
    )

    if (response.status === 200) {
      data = response.data;
      dispatch({
        type: FETCH_REPORTS_SUCCESS,
        payload: data,
      });
    } else if (response.status === 404 || response.status === 400 ) {
      error = response.data.error;
      console.log('Error 400:', error);
      dispatch({
        type: FETCH_REPORTS_FAILURE,
        payload: error,
      });
    }
  } catch (error) {
    console.log('error', error);
    // If the error is of type unknown, refine it to a string before dispatching the action
    const errorMessage =
        typeof error === 'string' ? error : 'An error occurred';

    dispatch({
      type: FETCH_REPORTS_FAILURE,
      payload: errorMessage,
    });
  }
};
export const getReportByStudentIdRedirect = async (
    dispatch: Dispatch<ReportsActionTypes>,
    token: any,
    id: any,
) => {
  try {
    dispatch({ type: FETCH_REPORTS_REQUEST });
    let data: any = null;
    let error: any = null;
    // Perform asynchronous operation (e.g., API call)
    let url = `https://api.development.obeasses.net/apiservice/assesemets-studentReport/${id}`;
    const response = await axios.get(
        url,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
    )

    if (response.status === 200) {
      data = response.data;
      dispatch({
        type: FETCH_REPORTS_SUCCESS,
        payload: data,
      });
    } else if (response.status === 404 || response.status === 400 ) {
      error = response.data.error;
      console.log('Error 400:', error);
      dispatch({
        type: FETCH_REPORTS_FAILURE,
        payload: error,
      });
    }
  } catch (error) {
    console.log('error', error);
    // If the error is of type unknown, refine it to a string before dispatching the action
    const errorMessage =
        typeof error === 'string' ? error : 'An error occurred';

    dispatch({
      type: FETCH_REPORTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getTeacherReportByStudentId = async (
    dispatch: Dispatch<ReportsActionTypes>,
    config: any,
    id: any,
) => {
  try {
    dispatch({ type: FETCH_REPORTS_REQUEST });
    let data: any = null;
    let error: any = null;

    let url = `${process.env.REACT_APP_API_URL}/apiv2/teacher-studentReport/${id}/students/${config?.user_ting}`;
    const response = await axios.get(
        url,
        {
          headers: {
            'X-Authorization': `${config?.apiKey}`,
            Accept: 'application/json',
            User: `${config?.user_ting}`,
          },
        }
    );

    if (response.status === 200) {
      data = response.data;
      dispatch({
        type: FETCH_REPORTS_SUCCESS,
        payload: data,
      });
    } else if (response.status === 404 || response.status === 400 ) {
      error = response.data.error;
      console.log('Error 400:', error);
      dispatch({
        type: FETCH_REPORTS_FAILURE,
        payload: error,
      });
    }
  } catch (error) {
    console.log('error', error);
    // If the error is of type unknown, refine it to a string before dispatching the action
    const errorMessage =
        typeof error === 'string' ? error : 'An error occurred';

    dispatch({
      type: FETCH_REPORTS_FAILURE,
      payload: errorMessage,
    });
  }
};
export const getTeacherReportByStudentIdRedirected = async (
    dispatch: Dispatch<ReportsActionTypes>,
    token: any,
    id: any,
    user_id: string
) => {
  try {
    dispatch({ type: FETCH_REPORTS_REQUEST });
    let data: any = null;
    let error: any = null;

    let url = `https://api.development.obeasses.net/apiservice/teacher-studentReport/${id}/students/${user_id}`;
    const response = await axios.get(
        url,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
    );

    if (response.status === 200) {
      data = response.data;
      console.log(data);
      dispatch({
        type: FETCH_REPORTS_SUCCESS,
        payload: data,
      });
    } else if (response.status === 404 || response.status === 400 ) {
      error = response.data.error;
      console.log('Error 400:', error);
      dispatch({
        type: FETCH_REPORTS_FAILURE,
        payload: error,
      });
    }
  } catch (error) {
    console.log('error', error);
    // If the error is of type unknown, refine it to a string before dispatching the action
    const errorMessage =
        typeof error === 'string' ? error : 'An error occurred';

    dispatch({
      type: FETCH_REPORTS_FAILURE,
      payload: errorMessage,
    });
  }
};
// Example usage for getAssessmentById
export const getAssessmentById =
    (config: any, id: any,token?: string, type?: 'normal' | 'redirect') => async (dispatch: Dispatch<AssessmentActionTypes>) => {
      if(type === 'normal')
        await fetchData(dispatch, config, id);
      if(type === 'redirect'){
        await fetchDataRedirect(dispatch, config, id, token);
      }
    };

// Example usage for getPlayerById ToFix
export const getPlayerById =
    (config: any, id: any) => async (dispatch: Dispatch<AssessmentActionTypes>) => {
      await fetchData(dispatch, config, id);
    };

// Example usage for submit answers
export const submitAnswers = createAsyncThunk<
    void,
    {
      assessmentId: string;
      answers: any;
      time: string;
      type?: 'normal' | 'redirected';
      token?: string;
      config?: any
      obeId?: string,
      user?: string,
      referrer?: string;
    } // Type of the argument object
>(
    'assessment/submitAnswers',
    async ({ assessmentId, answers, time, type = 'normal', token, config, obeId, user, referrer }, { dispatch }) => {
      if (type === 'normal') {
        await submit(dispatch, config, assessmentId, answers, time);
      } else {
        await submitRedirected(dispatch, assessmentId, answers, time, token, obeId, user, referrer);
      }
    }
);

// Example get Reports By Assessment
export const getReportsByClass =
    (config: any, id: any) => async (dispatch: Dispatch<AssessmentActionTypes>) => {
      await getReportsByAssessment(dispatch, config, id);
    };

// Example get Reports By Assessment
export const getReportsByStudentId =
    (config: any, id: any, type: 'normal' | 'redirect' ="normal", token?:string) => async (dispatch: Dispatch<AssessmentActionTypes>) => {
  console.log(type, token, id)
      if(type === 'normal')
        await getReportBysStudentId(dispatch, config, id);
      if(type === 'redirect')
        await getReportByStudentIdRedirect(dispatch, token, id);
    };


// Example get Teacher Reports By Student studentId
export const getTeacherReportsByStudentId =
    (config: any, id: any, type: 'normal' | 'redirect' ="normal", token?:string, user_id?:string) => async (dispatch: Dispatch<AssessmentActionTypes>) => {
      console.log(id, type, token,user_id)
  if(type === 'normal')
        await getTeacherReportByStudentId(dispatch, config, id);
      if(type === 'redirect')
        await getTeacherReportByStudentIdRedirected(dispatch, token, id, user_id);
    };


// Define payload interface
interface AssessmentPayload {
  defaultAssessment: any; // Change YourAssessmentDataType to the actual data type
  subQuestions: any[];
}

interface ReportsPayload {
  //defaultAssessment: any; // Change to the actual data type
  data: any;
}
