/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, Typography } from '@mui/material';

interface OBJECT {
  id?: number;
  label?: string;
  value?: string | number;
  disable?: boolean; // Optional to handle disabled options
}

type CustomDropdownProps = {
  title?: string;
  value?: string | number | null;
  dataArray?: any[];
  onChangeHandler: (e: any) => void;
  sxClass?: object;
  displayEmpty?: boolean;
  loading?: boolean;
  parentStyle?: string;
  handleClearClick?: () => void; // Optional clear button
  showCancel?: boolean; // Controls visibility of clear button
  disabled?: boolean;
  required?: boolean;
  labelParentClass?: string;
  IconParentClass?: string;
  padding?: boolean;
};

export default function Dropdown(props: CustomDropdownProps) {
  const {
    onChangeHandler,
    title,
    dataArray,
    value,
    displayEmpty = false,
    loading,
    parentStyle,
    handleClearClick,
    showCancel,
    required,
    labelParentClass = '',
    IconParentClass = '',
    sxClass,
    disabled,
  } = props;

  const handleChange = (event: any) => {
    onChangeHandler(event.target.value);
  };

  return (
      <div className={`relative ${parentStyle || ''} w-full`}>
        {/* Title */}
        <Typography
            className={`${
                props?.padding ? 'pb-2.5' : 'pb-2'
            } text-[15px] capitalize ${labelParentClass}`}
            variant="caption"
            display="block"
        >
          {title}
          {required && (
              <Typography
                  className="text-main-red"
                  variant="caption"
                  display="inline-block"
              >
                &nbsp;*
              </Typography>
          )}
        </Typography>

        {/* Dropdown */}
        <select
            disabled={disabled}
            style={{
              appearance: 'none',
              ...sxClass,
            }}
            value={value}
            onChange={handleChange}
            className="!rounded-[5px] border border-[#ECECEC] px-3 py-[9px] text-sm min-w-full"
        >
          {/* Empty State Logic */}
          {!displayEmpty && dataArray?.length < 1 && (
              <option disabled={true} value="">
                No Data
              </option>
          )}

          {displayEmpty && dataArray?.length < 1 && (
              <option disabled={true} value="">
                Select
              </option>
          )}

          {/* Render Options */}
          {dataArray?.map((e) => (
              <option
                  key={e.id}
                  value={e.value}
                  disabled={e.disable === true}
              >
                {e.label}
              </option>
          ))}

          {/* Loading State */}
          {loading && (
              <MenuItem disabled={true}>
                <div className="text-center w-full">
                  <CircularProgress color="success" size="20px" />
                </div>
              </MenuItem>
          )}
        </select>

        {/* Icon and Clear Button */}
        <div className="text-accent p-2 absolute right-0 bottom-0 flex items-center gap-2">
          {showCancel && handleClearClick && (
              <ClearIcon
                  className="cursor-pointer !text-[20px]"
                  onClick={handleClearClick}
              />
          )}
          <KeyboardArrowDownIcon
              className={`cursor-pointer ${IconParentClass} m-auto !text-[20px]`}
          />
        </div>
      </div>
  );
}
