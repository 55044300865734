import styled from 'styled-components';
import { EditableContentProps } from './types';
import { Colors } from 'utils/Colors';

export const BackgroundImage = styled.div<{
  width: string;
  backgroundImageUrl: string | any;
}>`
  width: ${(props) => props.width || '100%'};
  height: 80vh;
  background-image: url(${(props) => props.backgroundImageUrl});
  background-repeat: no-repeat;
  position: relative;
`;

// Use the type for the styled component props
export const EditableContent = styled.input<EditableContentProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  resize: none;
  padding: 5px;
  /* font-size: 14px; */
  background-color: ${Colors.forestGreen3};
  // Add any additional styling here
`;

export const EditableContentMultipleLine = styled.textarea<EditableContentProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  resize: none;
  padding: 5px;
  /* font-size: 14px; */
  background-color: ${Colors.forestGreen3};
  // Add any additional styling here
`;
