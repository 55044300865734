const ChartQuestionTypes = Object.freeze({
    BARCHART: 'bc',
    LINECHART: 'lc',
    HISTOGRAM: 'his',
    DOTPLOT: 'dotplot',
    LINEPLOT: 'lineplot',
});

const DrawingTypes = Object.freeze({
    DRAWING: 'draw',
    SHADING: 'shad',
    TOKEN_HIGHLIGHT: 'tohigh', // 'tokenHighlight'
    HOT_SPOT: 'hotsp',
});

const EditTokenTypes = Object.freeze({
    Sentence: "Sentence",
    Paragraph: "Paragraph",
    Word: "Word",
    None: "None",
})

const ChemistryTypes = Object.freeze({
    CHEM_FORMULA:'chfor',
    CLOZE_CHEM:'cloch',
    CHEM_ESSAY: 'chessay',
    CLOZE_CHEM_WITH_IMG:'chwi',
})

export {
    ChartQuestionTypes,
    DrawingTypes,
    EditTokenTypes,
    ChemistryTypes,
}