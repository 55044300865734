import React from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';
import { Colors } from 'utils/Colors';

const StyledWord = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 5px;
  color: black;
  background-color: ${Colors.forestGreen3};
  cursor: move;
  min-width: 100px;
`;

const Word = ({ word }) => {
    const [, drag] = useDrag(
        () => ({
            type: 'FILLED_WORD',
            item: { word: word },
        }),
        [word]
    );

    return (
        <StyledWord ref={drag}>
            {word}
        </StyledWord>
    );
};

export default Word;
