import { ResponsiveLine } from '@nivo/line';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getModifiedMaxYValue, getResponseFontSize } from 'utils/generalUtils';
import { scaleLinear } from 'd3-scale'; // Import for custom tick generation

// import ModalEditBarValue from './modalEditBarValue.component';
// import { FaArrowCircleLeft, FaArrowCircleRight, FaEdit, FaTrash } from 'react-icons/fa';

// interface IPoint {id: string, x: string, y: string | number}
interface LineChartGraphProps {
    activeTab: { value: any[], score: number | string },
    questionData: any,
    handleLineData: (newLineData: any[]) => void,
}

export default function LineChartGraph({ activeTab, questionData, handleLineData }: LineChartGraphProps) {
    const { template_response } = questionData;
    const more_options = questionData?.more_options;
    const layout = more_options && more_options?.layout ? more_options?.layout : null;
    const fontSize = getResponseFontSize(more_options, true);
    
    const lineData = [
        {
            id: 'lineData',
            color: 'hsl(220, 70%, 50%)',
            data: activeTab?.value.map(point => ({ ...point, y: Number(point.y) })),
        }
    ];
    const initialBarValues = useRef({...lineData[0].data});
    const maxYValue = !isNaN(Number(template_response.max_y_value)) ? Math.round(Number(template_response.max_y_value)) : 100;

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragStartY, setDragStartY] = useState<number | null>(null);

    // const [hoveredBar, setHoveredBar] = useState<string | null>(null);
    // const [showModal, setShowModal] = useState(false);
    // const [inputValue, setInputValue] = useState('');
    // const [editedBar, setEditedBar] = useState<string | null>(null);
    // const [hoveredPointId, setHoveredPointId] = useState<string | null>(null);

    // const handleEdit = (barValue: string) => {
    //     setEditedBar(barValue);
    //     setInputValue(barValue);
    //     setShowModal(true);
    // };

    // const handleModalClose = () => {
    //     setShowModal(false);
    //     setInputValue('');
    // };
    
    // const handleDelete = (barValue: string) => {
    //     const newBarData = activeTab.value.filter((point: IPoint) => point.x != barValue);
    //     handleLineData(newBarData);
    // };

    // const handleOkForEditBar = () => {
    //     const newBarData = activeTab.value.map((point: IPoint) => {
    //         if(point.x === editedBar && inputValue){
    //             return {...point, x: inputValue}
    //         }else {
    //             return point
    //         }
    //     });
    //     handleLineData(newBarData);
    //     handleModalClose();
    // }


    const handleDragStart = useCallback((event, index) => {
        setDraggedIndex(index);
        setDragStartY(event.clientY);
        initialBarValues.current = [...lineData[0].data];
    }, [lineData]);

    const handleDragMove = useCallback((event) => {
        if (draggedIndex !== null && dragStartY !== null) {
            // const deltaY = dragStartY - event.clientY;
            const  deltaY = Math.round((dragStartY - event.clientY) * (0.35 * maxYValue / 100));
            const newLineData = activeTab.value.map((point, i) => {
                if (i === draggedIndex) {
                    const calculatedValue = Math.min(maxYValue, Math.max(0, Number(initialBarValues.current[i].y) + deltaY))
                    return {
                        ...point,
                        y: maxYValue >= 10 ? Math.round(calculatedValue) : calculatedValue,
                    };
                }
                return point;
            });
            handleLineData(newLineData);
        }
    }, [draggedIndex, dragStartY, activeTab, maxYValue, handleLineData]);

    const handleDragEnd = useCallback(() => {
        setDraggedIndex(null);
        setDragStartY(null);
    }, []);


    useEffect(() => {
        const allCircles = document.getElementsByTagName("circle");
        if(layout && layout?.point_style && layout?.point_style == "cross") {
            Array.from(allCircles).forEach((el) => {
                el.setAttribute("display", "none");
            })
        }else {
            Array.from(allCircles).forEach((el) => {
                el.setAttribute("display", "block");
            })
        }
    }, [layout])

    // const handleReorderPoints = (currentIndex: number, type: "left" | "right") => {
    //     const allPoints = activeTab?.value;
    //     let reorderedPoints = allPoints.filter((p: IPoint, index: number) => index != currentIndex );
    //     const currentPoint = allPoints.find((p: IPoint, idx) => idx == currentIndex);
    //     if(type == "right" && currentPoint) {
    //         reorderedPoints.splice(currentIndex + 1, 0, currentPoint);
    //     }else if(type == "left" && currentPoint) {
    //         reorderedPoints.splice(currentIndex - 1, 0, currentPoint);
    //     }
    //     handleLineData(reorderedPoints);
    // }

    return (
        <div
            style={{
                border: '1px solid #D9D9D9',
                backgroundColor: '#f3f5f7',
                padding: '8px',
                marginBottom: '16px',
                width: '100%',
                height: maxYValue >= 100 ? '400px' : '500px',
                userSelect: 'none',
                fontSize,
            }}
            onMouseMove={handleDragMove}
            onMouseUp={handleDragEnd}
        >
            <ResponsiveLine
                theme={{
                    text: {fontSize},
                    axis: {
                        legend: {text: {fontSize, height: 50},}
                    },
                    
                }}
                data={lineData}
                margin={{top: 60, right: 130, bottom: 70, left: 90}}
                xScale={{ 
                    type: 'point',
                }}
                yScale={{ 
                    type: 'linear', 
                    min: 0, 
                    max: (maxYValue), 
                    stacked: false, 
                    reverse: false,
                }}
                enableGridX={layout && layout?.show_gridlines ? ["both", "x_only"].includes(layout.show_gridlines) : true}
                enableGridY={layout && layout?.show_gridlines ? ["both", "y_only"].includes(layout.show_gridlines) : true}
                axisRight={null}
                axisTop= {{
                    tickSize: 20,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: template_response?.chartTitle,
                    legendPosition: "middle",
                    legendOffset: -40,
                    renderTick: (props) => props.value,
                }}
                axisBottom={{
                    tickSize: 10,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: template_response?.xLabel || '',
                    legendPosition: 'middle',
                    legendOffset: 58,
                    renderTick: (props) => (
                        <g
                        >
                          <text
                            x={props.x}
                            y={props.y + 20}
                            textAnchor="middle"
                            dominantBaseline="middle"
                          >
                            {props.value}
                          </text>
                        </g>
                      ),
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: template_response?.yLabel || '',
                    legendPosition: 'middle',
                    legendOffset: -64,
                    tickValues: (() => {
                        const ticks = scaleLinear()
                            .domain([0, maxYValue])
                            .ticks(10);
                        // Ensure the maxYValue is included and filter overlapping ticks
                        return ticks.concat(maxYValue).filter((value, index, self) => {
                            if (index === self.length - 1) return true; // Always include the max value
                            const nextValue = self[index + 1];
                            return nextValue === undefined || nextValue - value > maxYValue * 0.05; // Minimum 10% gap
                        });
                    })(),
                }}
                colors="#3182CE"
                pointSize={10}
                pointColor="#ffffff"
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh={true}
                enableSlices="x"
                animate={true}
                isInteractive={true}
                onClick={(e) => {}}
                layers={[
                    'grid',
                    'axes',
                    'lines',
                    // 'points',
                    ({ points }) => (
                        <g>
                            {points.map((point, index) =>{
                                const pointId = point.id;
                                
                                return(
                                    <g 
                                        key={point.id}
                                        
                                    >
                                        {
                                            layout && layout?.point_style && layout?.point_style == "cross" ?
                                            (<path
                                                d="M24.778,21.419L19.276,15.917L24.777,10.415L21.949,7.585L16.447,13.087L10.945,7.585L8.117,10.415L13.618,15.917L8.116,21.419L10.946,24.248L16.447,18.746L21.948,24.248Z"
                                                fill="#3182CE"
                                                transform={`translate(${point.x - 16}, ${point.y - 16})`}
                                                onMouseDown={(event) =>
                                                    handleDragStart(event, index)
                                                }
                                                className=" hover:fill-[#08467f] cursor-grab"
                                            />)
                                            :
                                            (<circle
                                                cx={point.x}
                                                cy={point.y}
                                                r={8}
                                                fill="rgba(0,0,0,0)"
                                                onMouseDown={(event) => handleDragStart(event, index)}
                                                className=" hover:fill-[#3688d5] cursor-grab fill-[#acc6de]"
                                            />
                                            )
                                        }
                                        <text
                                            x={point.x}
                                            y={point.y - 15} // Position above the point
                                            textAnchor="middle"
                                            dominantBaseline="central"
                                            fill="black" // Customize color as needed
                                            style={{ fontSize, pointerEvents: 'none' }}
                                        >
                                            {point?.data.y.toString() }
                                        </text>

                                        
                                    </g>
                                )
                            })}
                        </g>
                    )
                ]}
            />

            
        </div>
    );
}
