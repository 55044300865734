import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import DropZone from "../partials/DropZone/DropZone";
import Button from "components/Button";
import DraggableElementMatchList from "./DraggableElementMatchList";
import DroppableArea from "../partials/DroppableArea/DroppableArea";
import { TOptionItem } from "types";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import DesignElement from "../partials/DesignElement/DesignElement";

type MatchListBlocPrevProps = {
  stimulus: TOptionItem[];
  possibRes: TOptionItem[];
  currentCorrectChoices: any[];
  handleDrop: (index: number, word: string) => void;
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  hasGroup?: boolean;
  getId?: any;
  score?: number;
  showAnswer?: boolean;
  correctAnswers?: any[];
  handleDataRest?: any;
  possibOptions: TOptionItem[];
};

export const MatchListBlocPrev = ({
  stimulus,
  possibRes,
  currentCorrectChoices,
  handleDrop,
  showAnswer,
  correctAnswers,
  handleDataRest,
}: MatchListBlocPrevProps) => {
  const { t } = useTranslation();
  const [currentChoices, setCurrentChoices] = useState(currentCorrectChoices);
  const [initialChoices, setInitialChoices] = useState(currentCorrectChoices);
  const [availableItems, setAvailableItems] = useState<string[]>(
    possibRes?.map((item) => item.label)
  );
  const [initialItems, setInitialItems] = useState<string[]>(
    possibRes?.map((item) => item.label) || []
  );

  const [view, setView] = useState("correct");

  const textRef = useRef<Array<HTMLDivElement | null>>([]);
  const correctTextRef = useRef<Array<HTMLDivElement | null>>([]);
  const duplicateResponses = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["machli"] as any)?.more_options?.duplicate_responses
  );
  const [hasAnswered, setHasAnswered] = useState<boolean>(false);
  const [showCheckAnswer, setShowCheckAnswer] = useState<boolean>(false); 
  
  useEffect(() => {
    stimulus?.forEach((item, i) => {
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
    });
  }, [stimulus]);

  useEffect(() => {
    if (showAnswer || showCheckAnswer) {
      correctAnswers?.forEach((item, i) => {
        if (correctTextRef.current[i]) {
          correctTextRef.current[i].innerHTML = DOMPurify.sanitize(item);
        }
      });
    }
  }, [correctAnswers, showAnswer,showCheckAnswer]);

  useEffect(() => {
    if (view === "alternate") {
      setCurrentChoices(currentCorrectChoices.map(() => ""));
    } else {
      setCurrentChoices(currentCorrectChoices);
    }
    const anyItemsDropped = Object.values(currentCorrectChoices).some((items) => items.length > 0);
    console.log(anyItemsDropped, " Dropped Items");
    setHasAnswered(anyItemsDropped);

  }, [view, currentCorrectChoices]);

  useEffect(() => {
    setAvailableItems(possibRes?.map((item) => item.label));
    setInitialItems(possibRes?.map((item) => item.label) || []);
  }, [possibRes]);

  useEffect(() => {
    setInitialChoices(currentCorrectChoices);
  }, [currentCorrectChoices]);

  const handleDropInternal = (index: number, word: string) => {
    if (!availableItems.includes(word)) {
      console.warn("Response is no longer available.");
      return; 
    }
  
    const newChoices = [...currentChoices];
  
    while (newChoices.length <= index) {
      newChoices.push(""); 
    }
  
    if (newChoices[index] && !duplicateResponses) {
      setAvailableItems((prevItems) => [...prevItems, newChoices[index]]);
    }
  
    if (!duplicateResponses) {
      newChoices.forEach((choice, i) => {
        if (choice === word && i !== index) {
          newChoices[i] = ""; 
          setAvailableItems((prevItems) => [...prevItems, word]); 
        }
      });
    }
  
    newChoices[index] = word;
    setCurrentChoices(newChoices); 
    handleDrop(index, word);
  
    if (!duplicateResponses) {
      setAvailableItems((prevItems) => prevItems.filter((item) => item !== word));
    }
  };

  const handleRemove = (index: number) => {
    const newChoices = [...currentChoices];
    const removedItem = newChoices[index];
  
    newChoices[index] = "";
    setCurrentChoices(newChoices);
  
    if (removedItem && !duplicateResponses) {
      setAvailableItems((prevItems) => [...prevItems, removedItem]);
    }
  
    handleDrop(index, "");
  };
  const more_options = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["machli"] as any)?.more_options
  );
  const possibility_list_position = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["machli"] as any)?.more_options?.ui_style
        ?.possibility_list_position
  );
  const instant_feedback = more_options?.ui_style?.instant_feedback;

  const getLabel = (index: number) => {
    let label = "";
    if (more_options?.ui_style?.validation_stem_numeration) {
      switch (more_options?.ui_style?.validation_stem_numeration) {
        case "numerical":
          label = (index + 1).toString();
          break;
        case "lower-alpha":
          label = String.fromCharCode(97 + index);
          break;
        case "upper-alpha":
          label = String.fromCharCode(97 + index).toUpperCase();
          break;
        default:
          break;
      }
    }
    return label;
  };

  const getDroppableArea = () => (
    <DroppableArea
      id={-1}
      onDrop={handleDropInternal}
      droppedItems={availableItems}
      className="flex items-center gap-1 flex-wrap"
    />
  );

  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const fontSize = fontSizeMapping[more_options?.ui_style?.fontsize];

  const handleCheckAnswerButton = (e) => {
    e.preventDefault();
    setShowCheckAnswer(!showCheckAnswer);
  };


  return (
    <DndProvider backend={HTML5Backend}>
      {handleDataRest && <Button title={t("reset")} onClick={handleDataRest} />}
      <div className="mt-4">
        {/* Conditional rendering of DroppableArea based on possibility_list_position */}
        {possibility_list_position === "top" && (
          <div className="mb-4">{getDroppableArea()}</div>
        )}

        <div style={{ display: "flex" }}>
          {possibility_list_position === "left" && (
            <div style={{ marginRight: "1rem" }}>{getDroppableArea()}</div>
          )}

          <div
            className="w-full flex items-center justify-center mt-4"
            style={{
              border: "1px solid #ccc",
              borderRadius: "6px",
              width: "100%",
              fontSize: fontSize, // Apply font size dynamically
            }}
          >
            <div className="max-w-[40%]">
              {stimulus?.map((item, i) => (
                <div key={item.id} className="flex items-center">
                  <div className="p-2 border-solid border border-grey-500 my-4 rounded-md min-w-[300px] min-h-[48px] ">
                    <div ref={(el) => (textRef.current[i] = el)} />
                  </div>
                </div>
              ))}
            </div>

            <div>
              {stimulus?.map((item, key) => (
                <div key={item.id} className="flex items-center">
                  <div className="p-2 my-4 h-[48px]">
                    <DesignElement />
                  </div>
                </div>
              ))}
            </div>

            <div className="max-w-[40%]">
              {stimulus?.map((item, key) => (
                <div key={item?.id} className="flex items-center gap-2">
                  {currentChoices && (
                    <DropZone
                      id={key}
                      onDrop={handleDropInternal}
                      filledWord={currentChoices[key]}
                      // filledWord={currentChoices[key]} // Pass the current choice
                      onRemove={handleRemove} // Pass the onRemove prop
                    />
                  )}
                  {(showAnswer || showCheckAnswer) && correctAnswers && view === "correct" && (
                    <div
                      className={`${correctAnswers[key] === currentChoices[key]
                          ? "border-2 p-1 border-green-500"
                          : "border-2 p-1 border-red-500"
                        }`}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ marginRight: "4px" }}>
                        {getLabel(key)}.
                      </span>

                      <div ref={(el) => (correctTextRef.current[key] = el)} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {possibility_list_position === "right" && (
            <div style={{ marginLeft: "1rem" }}>{getDroppableArea()}</div>
          )}
        </div>

        {possibility_list_position === "bottom" && (
          <div className="mt-4">{getDroppableArea()}</div>
        )}

        {possibility_list_position !== "top" &&
          possibility_list_position !== "left" &&
          possibility_list_position !== "right" &&
          possibility_list_position !== "bottom" &&
          getDroppableArea()}

        {/* Check Answer Button */}
        {instant_feedback &&(
        <div className="mt-6 flex justify-end">
          <button
            disabled={!hasAnswered}
            onClick={handleCheckAnswerButton}
            className={`text-right font-semibold py-2 px-4 border rounded transition duration-300 ${!hasAnswered
                ? "bg-[#aaaaaa] text-[#717171] cursor-not-allowed"
                : "bg-transparent hover:bg-accent text-gray-700 hover:text-white border hover:border-transparent"
              }`}
          >
            Check Answer
          </button>
        </div>
         )}
      </div>
    </DndProvider>
  );
};

MatchListBlocPrev.defaultProps = {
  editMode: false,
};
