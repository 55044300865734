import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import { Container } from '../CreateUser/styled';
import MainLayout from '../../components/MainLayout';

import EditBasicDetails from '../../components/EditQuestionTabs/EditBasicDetails';
// import EditLayout from '../../components/EditQuestionTabs/EditLayout';
// import EditTags from '../../components/EditQuestionTabs/EditTags';
// import EditAdditionalDetails from '../../components/EditQuestionTabs/EditAdditionalDetails';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../redux/store';
import {
  ContentContainer,
  HeaderContainer,
  SecondItem,
  Tab,
} from '../../components/styled';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

// import { setOldData } from '../../redux/slices/EditQuestionSlice';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { editVal, setOldData } from 'redux/slices/EditQuestionSlice';
import { openEditMode } from 'redux/slices/EditModeSlice';
import { editQuestionRequest } from 'api-rest/questions';
import { resetData } from 'redux/slices/QuestionSlice';
import Dropdown from 'components/DropDown';
import { accessTypes, statusOptions } from 'utils/data';

const tabs = [
  { id: 1, title: 'Basic Detail', children: <EditBasicDetails /> },
  //{ id: 2, title: 'Additional Details', children: <EditAdditionalDetails /> },
  //{ id: 3, title: 'Layout', children: <EditLayout /> },
  //{ id: 4, title: 'Tags', children: <EditTags /> },
];

const EditQuestion = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [CurrentAccessType, setCurrentAccessType] = useState(0);

  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  const editQuestion = useSelector((store: RootState) => store.editQuestion);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const { id } = useParams();
  const { currentQuestion } = useSelector(
    (state: RootState) => state.showQuestion
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetData());
    currentQuestion && dispatch(setOldData(currentQuestion));

    setCurrentStatus(currentQuestion?.status ? 1 : 0);
    setCurrentAccessType(currentQuestion?.access ? 1 : 0);
  }, [id, currentQuestion]);

  const onSubmit = async () => {
    // Ensure tags is an array of tag IDs
    const { tags, subject, grade, ...rest } = editQuestion;
    console.log('onSubmit editQuestion =', editQuestion); // Debugging

    // Handle tags: Ensure it's an array of IDs
    const tagIds = Array.isArray(tags)
      ? tags.map((tag: any) => tag.id || tag) // Extract IDs if tags are objects
      : [];
  
    // Construct the payload
    const newObject = {
      ...rest, // Spread the rest of the keys and their values
      tags: tagIds, // Use the array of tag IDs
      subject_id: subject?.id || null, // Handle subject ID
      grade_id: grade?.id || null, // Handle grade ID
      access: ["true", "1", true, 1].includes(rest?.access) ? "1" : "0", // Normalize access
      status: ["true", "1", true, 1].includes(rest?.status) ? "1" : "0", // Normalize status
    };
  
    console.log('onSubmit newObject =', newObject); // Debugging
  
    // Send the request
    const { error } = await editQuestionRequest(id, newObject);
  
    // Handle the response
    if (error) {
      toast.error('Failed To Update Question!');
      return;
    }
  
    // Display success message
    toast.success('Question Updated Successfully!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  
    // Navigate to the questions listing page
    navigate('/questions-listing');
  };
// console.log("TESTTT",currentQuestion?.status)
  return (
    <MainLayout
      header={
        <Header
          children={
            <SubHeader
              title={'Questions Listing'}
              subTitle={'Edit Questions'}
              showButtons={['save', 'questions']}
              method={handleSubmit(onSubmit)}
            />
          }
        />
      }
    >
      <Container>
        {/* Edit Question Tabs */}
        <HeaderContainer>
          <div className="flex justify-between w-full items-end">
            <div className="flex gap-5">
              {tabs.map((item, index) => (
                <Tab
                  key={item?.id}
                  onClick={() => handleTabClick(index)}
                  isActive={index === activeTab}
                >
                  {item.title}
                </Tab>
              ))}
            </div>

            <div className="flex items-center gap-2 mb-4">
              <Dropdown
                displayEmpty={true}
                title="Access Type"
                // value={CurrentAccessType}
                value={editQuestion?.access}
                dataArray={accessTypes}
                onChangeHandler={(val) => {
                  setCurrentAccessType(val);
                  dispatch(editVal({key: 'access', val}))
                  // console.log(val, 'value');
                }}
                sxClass={{ width: '206px' }}
              />

              <Dropdown
                displayEmpty={true}
                title="Status"
                // value={currentStatus}
                value={editQuestion?.status}
                dataArray={statusOptions}
                onChangeHandler={(val) => {
                  setCurrentStatus(val);
                  dispatch(editVal({key: 'status', val}))
                  // console.log(val);
                }}
                sxClass={{ width: '206px' }}
              />
            </div>
          </div>
        </HeaderContainer>

        <hr className="w-full mt-5 h-[1px] bg-[#eee]" />

        <ContentContainer>
          <SecondItem>{tabs[activeTab]?.children}</SecondItem>
        </ContentContainer>
        {/* End Edit Question Tabs */}

        {/* Add New sub-question */}
        {/* <hr className="w-full mt-5 h-1 bg-[#eee]" /> */}

        <div
          onClick={() => {
            dispatch(openEditMode());
            dispatch(resetData());
            navigate('/add-question');
          }}
          className="bg-[#fafafa] mt-5 flex items-center justify-center cursor-pointer"
          style={{
            height: '111px',
            border: '2px dashed #CAC9C9',
            borderRadius: '4px',
            padding: '0 30px',
          }}
        >
          <span className="flex items-center gap-2">
            <AiOutlineFileAdd className="text-2xl" /> <p>Add Questions</p>
          </span>
        </div>
      </Container>
    </MainLayout>
  );
};

export default EditQuestion;
