import styled from 'styled-components';
import { Colors } from '../../../utils/Colors';
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const Title = styled.div`
  padding: 10px;
  color: #2c2c2c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const MenuItemContainer = styled.div<{ isActive: boolean; isOpen?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
  color: ${(props) => (props.isActive ? 'white' : '#000')};
  background-color: ${(props) => (props.isActive ? '#FA9B31' : 'white')};
  cursor: ${(props) => (!props.isOpen ? 'pointer' : 'not-allowed')}; // Updated this line
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ececec;
`;

export const ChildrenContent = styled.div`
  margin: 20px 0;
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
  color: #000;
  font-weight: bold;
  margin: 10px 0 20px 0;
  border-radius: 5px;
`;

export const ResponseButton = styled.div<{ type?: any }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #FA9B31;
  height: 37px;
  padding: 10px 10px;
  flex-shrink: 0;
  margin: 10px 0;
  color: white;
  width: fit-content;
  cursor: pointer;
  span {
    margin: 0 5px;
  }
`;

export const ResponseItem = styled.div``;

export const AddResponseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${Colors.forestGreen};
  height: 37px;
  padding: 10px 60px;
  flex-shrink: 0;
  margin: 20px 10px 20px 0;
  color: white;
  width: fit-content;
  cursor: pointer;
  span {
    margin: 0 5px;
  }
`;

export const AlternateContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ResponseInput = styled.input`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
  padding: 8px;
  color: #000;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  outline: none;
`;

export const ResponseLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 149px;
  align-items: center;
  gap: 5px;
  padding: 8px;
  margin-right: 8px;
  color: white;
  border-radius: 5px;
  background: #FA9B31;
  cursor: pointer;
  outline: none;
  user-select: none;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FA9B31;
  font-weight: bold;
  gap: 2px;
  width: 105px;
  height: 37px;
  border-radius: 8px;
  border: 1px solid #FA9B31;
  margin: 0 16px;
`;

export const ToolbarOptionButton = styled.button<{ active: boolean, backgroundColor?: string }>`
  color: var(--grey, #35363a);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 3px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.active ? '#FA9B31' : 'transparent')};
  color: ${(props) => (props.active ? '#FFF' : '#000')};
  border: 1px solid #FA9B31;
`;

export const OptionsBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImagePreview = styled.div`
  width: 100%;
  height: 300px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: row;
  padding: 16px;
  overflow:hidden ;
`;

export const OptionsContent = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #eee;
  padding: 16px;
`;

export const LeftDiv = styled.div`
  flex: 1;
  background-color: transparent;
  border: 1px solid #de2128;
`;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
`;

export const RightDiv = styled.div`
  flex: 1;
  background-color: transparent;
  margin-left: 30px;
  padding: 0 30px;
`;

export const ItemText = styled.div`
  color: var(--grey, #35363a);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  margin-top: 20px;
  padding: 0 5px;
`;

export const ColorSwatch = styled.div<{ color: string; isSelected: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  border: 2px solid ${(props) => (props.isSelected ? 'black' : 'transparent')};
  border-radius: 50%;
  cursor: pointer;
`;

export const ImageSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

export const Item = styled.input`
  /* Add your styles for the items here */
  /* width: 204px; */
  width: 400px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  margin: 5px 0;
  padding: 0 5px;
`;

export const CanvasBorderContainer = styled.div`
  /* Add your styles for the items here */
  display: flex;
  width: 180px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  margin: 5px 0;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColorInput = styled.input`
  width: 100%;
`;

export const ColorPreview = styled.div`
  width: 60px;
  margin: 2px;
`;

export const ColorModal = styled.div`
  display: ${(props: { open: boolean }) => (props.open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20%;
  height: 20%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
`;

export const ColorPalette = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 204px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  margin: 5px 0;
`;

export const ColorBox = styled.div`
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  width: 204px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  margin: 5px 0;
`;

export const ButtonInput = styled.button<any>`
  max-width: 150px;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  margin: 5px;
  background-color: #FA9B31;
  border: ${(props) => (props.disabled ? 'none' : '1px solid #DE2128')};
  color: white;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;
`;

export const GroupeNameInput = styled.div``;
