import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import SubHeader from '../../components/SubHeader';
import Spinner from '../../components/spinner/Spinner';
import { Block, Button, CancelButton, Container, Line } from '../../components/styled';
import { Input, Label } from '../../components/InputWithLabel/styled';

const CreateTagHierarchy = () => {
  // State management
  const [selectedTagType, setSelectedTagType] = useState('');
  const [tagTypes, setTagTypes] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagName, setTagName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Fetch tag types on component mount
  useEffect(() => {
    const fetchTagTypes = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/tagType/datatable`,
          {
            params: {
              page: 1,
              per_page: 100
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              Accept: 'application/json',
            }
          }
        );
        setTagTypes(response.data.items);
      } catch (error) {
        toast.error('Failed to fetch tag types');
        console.error('Error fetching tag types:', error);
      }
      setIsLoading(false);
    };

    fetchTagTypes();
  }, []);

  // Handle tag type selection
  const handleTagTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedTagType(selectedType);
    
    const selectedTypeData = tagTypes.find(type => type.name === selectedType);
    if (selectedTypeData?.tags) {
      setAvailableTags(selectedTypeData.tags.filter(tag => 
        !selectedTags.some(selected => selected.id === tag.id)
      ));
    }
  };

  // Drag and drop handlers
  const handleDragStart = (e, tag) => {
    e.dataTransfer.setData('tagData', JSON.stringify(tag));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    try {
      const tag = JSON.parse(e.dataTransfer.getData('tagData'));
      if (!selectedTags.some(selected => selected.id === tag.id)) {
        setSelectedTags([...selectedTags, tag]);
        setAvailableTags(availableTags.filter(t => t.id !== tag.id));
      }
    } catch (error) {
      console.error('Error handling drop:', error);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setSelectedTags(selectedTags.filter(tag => tag.id !== tagToRemove.id));
    if (tagToRemove.name) {
      setAvailableTags([...availableTags, tagToRemove]);
    }
  };

  // Save tag hierarchy
  const handleSave = async () => {
    if (!tagName.trim()) {
      toast.error('Please enter a tag name');
      return;
    }

    if (selectedTags.length === 0) {
      toast.error('Please select at least one tag');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tagHierarchy`,
        {
          name: tagName,
          tags: selectedTags.map(tag => tag.id)
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          }
        }
      );

      if (response.status === 200) {
        toast.success('Tag hierarchy created successfully');
        // Reset form
        setTagName('');
        setSelectedTags([]);
        setSelectedTagType('');
        setAvailableTags([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Failed to create tag hierarchy');
    }
  };

  return (
    <MainLayout
      header={
        <Header>
          <SubHeader
            title="Dashboard"
            subTitle="Create New Tag Hierarchy"
            showButtons={['tagsHierarchy']}
          />
        </Header>
      }
    >
      <Container>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="space-y-6 flex flex-col">
            {/* Tag Type Selection */}
            <Block style={{ width: '50%', display: 'flex', flexDirection: 'column' , marginTop: '16px' }}>
              <Label>Select Tag Type</Label>
              <select 
                className="w-full p-2 border rounded"
                value={selectedTagType}
                onChange={handleTagTypeChange}
              >
                <option value="">Select an option</option>
                {tagTypes.map((type) => (
                  <option key={type.id} value={type.name}>
                    {type.name}
                  </option>
                ))}
              </select>
            </Block>

            {/* <Line className="my-4" /> */}

            {/* Tag Name Input */}
            <Block style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
              <Label>Tag Name*</Label>
              <Input
                type="text"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                className="w-full"
                required
              />
            </Block>

            {/* Drag and Drop Area */}
            <div className="mt-8 flex gap-6">
              {/* Selected Tags */}
              <div className="w-1/2">
                <h2 className="text-lg font-semibold mb-2">Selected Tags</h2>
                <div
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                  className="min-h-[200px] p-4 border-2 border-dashed rounded-lg bg-gray-50"
                >
                  <div className="flex flex-wrap gap-2">
                    {selectedTags.map((tag) => (
                      <div
                        key={tag.id}
                        className="flex items-center gap-2 px-3 py-2 bg-white rounded-md border"
                      >
                        <span>{tag.name}</span>
                        <button
                          onClick={() => handleRemoveTag(tag)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <AiOutlineClose />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Available Tags */}
              <div className="w-1/2">
                <h2 className="text-lg font-semibold mb-2">Available Tags</h2>
                <div className="min-h-[200px] p-4 border-2 rounded-lg bg-gray-50">
                  <div className="flex flex-wrap gap-2">
                    {availableTags.map((tag) => (
                      <div
                        key={tag.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, tag)}
                        className="px-3 py-2 bg-white rounded-md border cursor-move hover:shadow-md transition-shadow"
                      >
                        {tag.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-4 mt-8">
              <CancelButton type="button" className="w-32 m-[18px]">
                Discard
              </CancelButton>
              <Button type="button" onClick={handleSave} className="w-32">
                Save
              </Button>
            </div>
          </div>
        )}
      </Container>
    </MainLayout>
  );
};

export default CreateTagHierarchy;