import { useEffect, useRef, useState } from "react";
import { buttonsSettings } from "utils/graph/shapeDrawerUtil";
import { debounce } from "utils/generalUtils";
import {
  getObjectClicked,
  handleBoardAddLabel,
  handleBoardDelete,
  handleMovingObject,
  handlePointClick,
  handlePolygonClick,
  handleTwoPointsShape,
} from "./utils/boardUtils";

const useBoardEvents = (
  board,
  selectedButton,
  setCustomGraphOptions,
  activeTabIndex,
  setLabelState,
  userFinishedDrawing,
  graphPoints,
  deleteStart,
  setDeleteStart,
  questiontype,
  graphSettings,
  boardResetting,
  setBoardResetting,
  deletedElements,
  setDeletedElements,
  setActiveShape
) => {
  const pointsRef = useRef({ points: [], shapeDrawer: null });
  const elementsRef = useRef([]);
  const [graphOptions, setGraphOptions] = useState([]);
  const polygonStateRef = useRef(false);
  const [polygonDone, setPolygonDone] = useState(false);
  const startSaving = useRef(false);
  const prevScrollY = useRef(window.scrollY); // Store the previous scroll position
  const selectedButtonRef = useRef("");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY.current && polygonStateRef.current && graphOptions.length > 0) {
        // console.log("Scrolling down");
        // Add logic for scrolling down
        debouncedUpdate();
      } else if (currentScrollY < prevScrollY.current && polygonStateRef.current && graphOptions.length > 0) {
        // console.log("Scrolling up");
        // Add logic for scrolling up
        debouncedUpdate();
      }
      prevScrollY.current = currentScrollY;
    };

    // Debounced scroll handler
    const debouncedHandleScroll = debounce(handleScroll, 200); // Adjust delay as needed

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [graphOptions.length, polygonStateRef.current]); // Run only once
  const debouncedUpdate = debounce(() => {
    setCustomGraphOptions((prevData) => {
      const updatedData = [...prevData, ...graphOptions].filter(
        (item) => !deletedElements.includes(item.id)
      );
      return updatedData;
    });
    setGraphOptions([]);
    elementsRef.current = [];
    board.update();
  }, 0);
  console.log(selectedButton, "selectedButton");
  // useEffect(() => {
  //   if (userFinishedDrawing && startSaving.current) {
  //    startSaving.current = false
  //   }
  //   if (!userFinishedDrawing) {
  //     startSaving.current = false
  //    }
  //    if(userFinishedDrawing) {
  //     startSaving.current = true  
  //    }
    
  // }, [userFinishedDrawing]);

  // useEffect(() => {
  //   if(polygonStateRef.current && startSaving.current && graphOptions.length > 0) {
  //     debouncedUpdate();
  //   }
  // }, [startSaving.current, graphOptions, polygonStateRef.current]);
  useEffect(() => {
    // setDeleteStart(false);
    if (selectedButton !== "Polygon") {
      if (pointsRef.current.points.length > 0) {
        pointsRef.current.points = [];
      }
      if (pointsRef.current.shapeDrawer) {
        pointsRef.current.shapeDrawer = null;
        pointsRef.current.points = [];
      }
      polygonStateRef.current = false;
      setPolygonDone(true);
    }
    if(selectedButton !== "Label") {
      setActiveShape(false)
    }
    if(selectedButtonRef.current !== selectedButton && graphOptions.length > 0 && selectedButtonRef.current !== "") {
      debouncedUpdate();
      selectedButtonRef.current = selectedButton
    }
    selectedButtonRef.current = selectedButton
   
  }, [selectedButton]);

  useEffect(() => {
    if (pointsRef.current.points.length > 0) {
      pointsRef.current.points = [];
    }
    if (elementsRef.current.length > 0) {
      elementsRef.current = [];
    }
    if (pointsRef.current.shapeDrawer) {
      pointsRef.current.shapeDrawer = null;
      pointsRef.current.points = [];
    }
  }, [board]);
  useEffect(() => {
    if (!board || !selectedButton) return;
    const handleClick = (event) => {
      event.stopPropagation();
      if(boardResetting) setBoardResetting(false);
      polygonStateRef.current = true;
      // console.log(selectedButton, 'ButtonCLicked');

      //calculate mouse position on click
      const coords = board.getUsrCoordsOfMouse(event);
      const x = Math.round(coords[0]);
      const y = Math.round(coords[1]);
      const newPoint = { x, y };

      // getting objects under mouse

      const { clickedObject, objectsUnderMouse, isChild } = getObjectClicked(
        board,
        event,
        graphPoints
      );
      

      // Remove null or undefined results
      if (deleteStart) {
        if (!clickedObject) {
          setDeleteStart(false);
          return;
        }
        handleBoardDelete(
          board,
          clickedObject,
          graphPoints,
          setCustomGraphOptions,
          setDeleteStart,
          setDeletedElements,
          isChild
        );
        return;
      }
      if (selectedButton === "Label") {
        // console.log('labelClicked', clickedObject);
        clickedObject?.on("drag", (e) => {
          // console.log("clickedObjectDrag ");
          handleMovingObject(
            board,
            x,
            y,
            clickedObject,
            objectsUnderMouse,
            setCustomGraphOptions,
            graphPoints,
            graphSettings,
            isChild
          );
          setActiveShape(false);
          return;
        });
        clickedObject?.on("up", () => {
          clickedObject?.off("drag");
          // console.log("clickedObjectDrag ");
          handleBoardAddLabel(
            board,
            objectsUnderMouse[0],
            clickedObject,
            graphPoints,
            event,
            setLabelState,
            isChild
          );
        });
        return;
      }
      if (
        selectedButton !== "Move" &&
        objectsUnderMouse.length > 0 &&
        selectedButton !== "Polygon"
      ) {
        handleMovingObject(
          board,
          x,
          y,
          clickedObject,
          objectsUnderMouse,
          setCustomGraphOptions,
          graphPoints,
          graphSettings,
          isChild
        );

        return;
      }
      if (selectedButton === "Move") {
        handleMovingObject(
          board,
          x,
          y,
          clickedObject,
          objectsUnderMouse,
          setCustomGraphOptions,
          graphPoints,
          graphSettings,
          isChild
        );

        return;
      }
      // Check if we can add a new point
      if (selectedButton === "Point") {
        handlePointClick(
          board,
          x,
          y,
          graphSettings,
          activeTabIndex,
          setGraphOptions,
          selectedButton
        );
        return;
      }
      if (buttonsSettings[selectedButton].max === 2) {
        // Initialize generator if it doesn't exist

        handleTwoPointsShape(
          board,
          newPoint,
          graphSettings,
          activeTabIndex,
          setGraphOptions,
          pointsRef,
          elementsRef,
          selectedButton,
          questiontype
        );
        return;
      }

      if (selectedButton === "Polygon") {
        handlePolygonClick(
          board,
          newPoint,
          graphSettings,
          activeTabIndex,
          setGraphOptions,
          elementsRef,
          polygonStateRef,
          selectedButton,
          boardResetting,
          questiontype,
          polygonDone
        );
        // setPolygonDone(polygon);
        return;
      }
    };
    board.off("down", handleClick);
    // if (
    //   graphOptions.length > 0 &&
    //   userFinishedDrawing &&
    //   polygonStateRef.current
    // ) {
    //   console.log("graphOptions", graphOptions, polygonStateRef.current, userFinishedDrawing);
    //   debouncedUpdate();
    // }

    // Add event listeners
    board.on("down", handleClick);

    // Cleanup function to remove the event listener
    return () => {
      board.off("down", handleClick);

    };
  }, [
    board,
    selectedButton,
    boardResetting,
    graphOptions,
    questiontype,
  ]);
};

export default useBoardEvents;
