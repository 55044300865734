import React from 'react';

interface VerticalInputProps {
  isCheckBox?: boolean;
  isActive: boolean;
  handleItemClicked: (id: string) => void;
  item: any;
  children: React.ReactNode;
  otherStyle?: React.CSSProperties;
  label?: string; 
}

const VerticalInput: React.FC<VerticalInputProps> = ({
  isCheckBox = false,
  isActive,
  handleItemClicked,
  item,
  children,
  otherStyle = {},
  label,
}) => {
  return (
    <div
      id="checkbox"
      className="mt-5 mb-5 p-5 inline-flex flex-col-reverse justify-center items-center gap-3"
      style={{
        minHeight: '45px',
        border: '1px solid #eee',
        borderRadius: '6px',
        ...otherStyle,
      }}
    >
      {label && (
        <div
          className={`text-white mb-2 ${isActive ? 'bg-[#de2128] opacity-90' : 'bg-gray-500'} p-2 rounded-md`}
        >
          {label}
        </div>
      )}
      <input
        className="bg-[#DE2128]"
        type={isCheckBox ? 'checkbox' : 'radio'}
        checked={isActive}
        onChange={() => handleItemClicked(item.id)} // simplified onChange
      />
      {children}
    </div>
  );
};

export default VerticalInput;
