import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Question} from "../../obsService/types";


interface SelectedQuestionsState {
    questions: Question[];
}

const initialState: SelectedQuestionsState = {
    questions: []
};

const selectedQuestionsSlice = createSlice({
    name: 'selectedQuestions',
    initialState,
    reducers: {
        setSelectedQuestions: (state, action: PayloadAction<Question[]>) => {
            state.questions = action.payload;
        },
        cleanQuestions: (state, action: PayloadAction<Question[]>) => {
            return initialState;
        },
        removeQuestionById: (state, action: PayloadAction<string>) => {
            state.questions = state.questions.filter(question => question.id !== action.payload);
        },
        clearSelectedQuestions: (state) => {
            state.questions = [];
        },
        addQuestionToSelected: (state, action) => {
            const { questionsCreated } = action.payload;
            if (!Array.isArray(questionsCreated)) {
                console.error("questionsCreated is not an array", questionsCreated);
                return; // Exit the function or handle the error as needed
            }
            state.questions = [
                ...state.questions,
                ...questionsCreated
            ];
        }
    }
});

export const { setSelectedQuestions, cleanQuestions, removeQuestionById, addQuestionToSelected , clearSelectedQuestions } = selectedQuestionsSlice.actions;
export default selectedQuestionsSlice.reducer;
