import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // Allow event or no event
  disabled?: boolean;
  title?: string;
  margin?: string | boolean;
  icon?: ReactNode;
  className?: string;
  variant?: 'contained' | 'outlined';
  children?: ReactNode;
}

const StyledButton = styled.button<ButtonProps>`
  min-width: 136px;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${(props) =>
      props.disabled
          ? '#ccc'
          : props.variant === 'contained'
              ? 'var(--accent)'
              : 'white'};
  border: ${(props) =>
      props.disabled ? 'none' : props.variant === 'contained' ? 'none' : '1px solid var(--accent)'};
  color: ${(props) =>
      props.disabled
          ? '#ccc'
          : props.variant === 'contained'
              ? '#fff'
              : 'var(--accent)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: ${(props) => (props.margin ? (typeof props.margin === 'boolean' ? '0 5px' : props.margin) : '0')};
  outline: none;

  &:hover {
    background-color: ${(props) =>
        props.disabled
            ? '#ccc'
            : props.variant === 'contained'
                ? 'var(--light-accent)'
                : 'white'};
    color: ${(props) => (props.disabled ? 'grey' : 'var(--accent)')};
  }
`;

const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  margin,
  title,
  icon,
  className,
  variant = 'outlined',
  children,
  ...rest
  }) => {
  // Wrap onClick to make event optional
  const handleClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      // If the handler is passed, call it with the event
      onClick(event);
    } else if (onClick) {
      // Call it without an event if no event is passed
      onClick();
    }
  };

  return (
      <StyledButton
          type="button"
          onClick={handleClick}
          margin={margin}
          disabled={disabled}
          className={`${icon ? 'flex items-center gap-2 justify-center' : ''} ${className || ''}`}
          variant={variant}
          {...rest}
      >
        {icon} {title || children}
      </StyledButton>
  );
};

export default Button;
