import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import ResizableDivContainer from '../../AuthorQuestion/FillBlanks/SubChildren/ImageDragDrop/ResizableDivContainer';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import BackgroundImageContainerWithAltText from 'obsService/components/PreviewStudent/PreviewImageDragDrop/backgroundImageWithAltText.component';
import { EditableContentMultipleLine, EditableContent } from 'obsService/components/PreviewStudent/PreviewImageText/styles';
// import { EditableContent } from 'obsService/components/PreviewImageTextForReports/styles';
const PreviewImageWithText = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const validRes = currentQuestion?.correct_answer?.valid_response;
  const more_options = currentQuestion?.more_options;
  const multipleLine = more_options ? !!more_options?.layout?.multiple_line : false;

  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const initialDivs = validRes && Array.isArray(validRes?.value) ? validRes?.value.map((answer) => ({
    ...answer,
    content: '',
})) : [];
const [divs, setDivs] = useState([...initialDivs])
  useEffect(() => {
    if(divs.length === 0) setDivs(initialDivs)
}, [])
  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleResProperty = (moreOptions, index, propName, defaultValue = "") => {
    let val = moreOptions && moreOptions?.response_options[`${propName}`] ? 
        moreOptions?.response_options[`${propName}`] : 
        defaultValue;
  
    const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
    if(moreOptions && resOptionsIndividaul.length > 0 && resOptionsIndividaul) {
        if(index > -1) {
            const currentResOptions = resOptionsIndividaul.find((_item, idx) => idx == index);
            if(currentResOptions) {
                val = currentResOptions[`${propName}`] ? currentResOptions[`${propName}`] : val;
            }  
        }
    }
    
    return val;
  }
  const handleContentChange = (index: number, newContent: string) => {
    console.log('hello',index,newContent)
    if(showAnswer) return;
    console.log('still going',index,newContent)
    const updatedDivs = divs.map((div, i) =>
        i === index ? { ...div, content: newContent } : div
    );
    setDivs(updatedDivs);
};
const getInputType = (index: number) => {
  let inputType = "text";
  if(more_options && more_options?.response_options?.input_type) {
    inputType = more_options?.response_options?.input_type;
  }
  if(more_options && Array.isArray(more_options?.response_options_individaul)) {
    more_options?.response_options_individaul.forEach((op, idx) => {
      if(idx === index) {
        inputType = op?.input_type ? op.input_type : "text";
      }
    })
  }
  return inputType;
}
const studentAnswer = 
  showAnswer ? 
  {valid_response: validRes}
  :
  {valid_response: {value: divs}}

const getCurrentValue = (index: number) => {
  let value = '';
  if (studentAnswer) {
      const selectedAnswerValue = studentAnswer.valid_response.value;
      const studentAnswerPerIndex = selectedAnswerValue.find((item, idx) => idx === index);
      if (studentAnswerPerIndex) {
          value = studentAnswerPerIndex.content || '';
      }
  }
  return value;
};
  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <>
      <BackgroundImageContainerWithAltText

backgroundImageUrl={currentQuestion?.image?.source}
ariaLabel={currentQuestion?.image?.textHover}
width={`${currentQuestion?.image?.width ? currentQuestion?.image?.width : 400}px`}
height={`${currentQuestion?.image?.height ? currentQuestion?.image?.height : 400}px`}
title={currentQuestion?.image?.textHover} 
altText={currentQuestion?.image?.alt || ""}
>
          { currentQuestion?.correct_answer && 
            Array.isArray(currentQuestion?.correct_answer?.valid_response?.value) &&
            currentQuestion?.correct_answer.valid_response.value.map((div, index) => (
              <ResizableDivContainer
                key={index}
                div={div}
                // onDrag={(position) => {}}
                // onDrop={(e) => {}}
                onDelete={() => {handleContentChange(index, "")}}
              >
                {
                        multipleLine && getInputType(index) === "text" ? 
                        <EditableContentMultipleLine
                            multiple={true}
                            aria-multiline={true}
                            style = {more_options && more_options?.layout.fontSize ?
                                {fontSize:more_options?.layout.fontSize,} :
                                {fontSize: 'normal'}
                            }
                            value={ getCurrentValue(index) ||handleResProperty(more_options, index, 'placeholder', '')}
                            onChange={(e) => handleContentChange(index, e.target.value)}
                            type={getInputType(index)}
                            spellCheck={ more_options && more_options?.layout?.spellcheck ?
                                (more_options?.layout?.spellcheck == true) : false 
                            }
                        />
                        :
                        <EditableContent
                            style = {more_options && more_options?.layout.fontSize ?
                                {fontSize:more_options?.layout.fontSize,} :
                                {fontSize: 'normal'}
                            }
                            value={ getCurrentValue(index) ||handleResProperty(more_options, index, 'placeholder', '')}
                            onChange={(e) => handleContentChange(index, e.target.value)}
                            type={getInputType(index)}
                            spellCheck={ more_options && more_options?.layout?.spellcheck ?
                                (more_options?.layout?.spellcheck == true) : false 
                            }
                        />
                    }

                    

                {/* <div
                   style = {more_options && more_options?.layout.fontSize ? 
                    {fontSize:more_options?.layout.fontSize,} : 
                    {}
                  }
                >
                  {/* { showAnswer || editMode ? div.content :  */}
                  {/* { showAnswer ? div.content : 
                    handleResProperty(more_options, index, 'placeholder', '')
                  } */}
                  {/* {showAnswer || localShowAnswer || editMode ? div.content : 
                  (more_options && more_options?.response_options?.placeholder ? more_options?.response_options?.placeholder : '')
                  } }
                </div> */}
              </ResizableDivContainer>
            ))}
        </BackgroundImageContainerWithAltText>
      </>
    </PreviewWrapper>
  );
};

export default PreviewImageWithText;
