import styled from 'styled-components';
import { Colors } from '../../../../../utils/Colors';

export const DraggableOption = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: ${Colors.forestGreen2};
  color: black;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  cursor: move;
  user-drag: element;
  user-select: none;
`;

export const BackgroundImage = styled.div<{ width?: string; backgroundImageUrl: string; ariaLabel: string; title: string; height?: string }>`
  /* 
  height: 70vh;
  max-width: 100%; */
  //padding-top: 100%; /* This is the aspect ratio hack for maintaining the original image's aspect ratio */
  /* background-image: url(${(props) => props.backgroundImageUrl});
  //background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%; */
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  max-width: 100%;
  min-height: 40vh; 
  background-image: url(${(props) => props.backgroundImageUrl});
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%;
`;


export const DivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 140px;
  height: 70px;
  resize: both;
  //cursor: move;
  overflow: auto;
  background-color: ${Colors.forestGreen3};
  border-radius: 5px;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background: transparent;
  cursor: text;
`;
export const StyledMultiLineInput = styled.textarea`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background: transparent;
  cursor: text;
`;

export const DeleteIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;

  svg {
    color: ${Colors.forestGreen};
    font-size: 20px;
  }
`;

export const ColorButton = styled.button`
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 5px;
  cursor: pointer;
`;
