import { ResponsiveBar } from '@nivo/bar';
import { useCallback, useRef, useState } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight, FaEdit, FaTrash } from 'react-icons/fa';
import { getResponseFontSize } from 'utils/generalUtils';
import ModalEditBarValue from './modalEditBarValue.component';
import { AiFillCloseCircle } from 'react-icons/ai';
 
interface IPoint {id: string, x: string, y: string | number}
interface DotPlotGraphProps {
    activeTab: { value: any[], score: number | string },
    questionData: any,
    handleBarData: (newBarData: any[]) => void,
    disabled?: boolean,
}
 
export default function DotPlotGraph({ activeTab, questionData, handleBarData, disabled }: DotPlotGraphProps) {
    const template_response  = questionData?.template_response ? questionData?.template_response : {};
    const more_options = questionData?.more_options;
    const layout = more_options && more_options?.layout ? more_options?.layout : null;
    const fontSize = getResponseFontSize(more_options, true);
    
    const barData = activeTab?.value.map(point => ({ ...point, y: Number(point.y) }));
    const maxYValue = !isNaN(Number(template_response.max_y_value)) ? Number(template_response.max_y_value) : 10;
 
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragStartY, setDragStartY] = useState<number | null>(null);
    const initialBarValues = useRef([...barData]);
    const bulletRadius = 10 * (10 / maxYValue);
    const spacing = 5;

    const [hoveredBar, setHoveredBar] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editedBar, setEditedBar] = useState<string | null>(null);
    const [hoveredPointId, setHoveredPointId] = useState<string | null>(null);

    const handleEdit = (barValue: string) => {
        setEditedBar(barValue);
        setInputValue(barValue);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setInputValue('');
    };
    
    const handleDelete = (barValue: string) => {
        const newBarData = activeTab.value.filter((point: IPoint) => point.x != barValue);
        handleBarData(newBarData);
    };

    const handleOkForEditBar = () => {
        const newBarData = activeTab.value.map((point: IPoint) => {
            if(point.x === editedBar && inputValue){
                return {...point, x: inputValue}
            }else {
                return point
            }
        });
        handleBarData(newBarData);
        handleModalClose();
    }
 
    const [linePosition, setLinePosition] = useState({ x1: 0, y1: 260, x2: 300, y2: 260 });
 
    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (disabled) return;
        const rect = event.currentTarget.getBoundingClientRect();
        const y = event.clientY - rect.top - (bulletRadius * 2 + spacing * 2);
        setLinePosition({
            x1: 0,
            y1: y,
            y2: y,
            x2: rect.right
        });
    };
 
    const handleDragStart = useCallback((event, index) => {
        setDraggedIndex(index);
        setDragStartY(event.clientY);
        initialBarValues.current = [...barData];
    }, [barData]);
 
    const handleDragMove = useCallback((clientY) => {
            if (draggedIndex !== null && dragStartY !== null) {
                //    const  deltaY = Math.floor(((dragStartY - clientY) * 0.35)/10);
                const  deltaY = Math.floor(((dragStartY - clientY) * (0.35 * maxYValue/bulletRadius ))/maxYValue);
                const newBarData = activeTab.value.map((point, i) => {
                    if (i === draggedIndex) {
                        const calculatedValue = Math.min(
                            maxYValue,
                            Math.max(0, initialBarValues.current[i].y + deltaY)
                        );
                        return {
                            ...point,
                            y: calculatedValue,
                        };
                    }
                    return point;
                });
                handleBarData(newBarData);
            }
        },
        [draggedIndex, dragStartY, maxYValue, activeTab.value, handleBarData]
    );
 
    const handleMouseMoveWithDrag = (event: React.MouseEvent<HTMLDivElement>) => {
        // const rect = event.currentTarget.getBoundingClientRect();
        handleDragMove(event.clientY);
        handleMouseMove(event);
    };
 
    const handleDragEnd = useCallback(() => {
        setDraggedIndex(null);
        setDragStartY(null);
    }, []);

    const handleReorderPoints = (currentIndex: number, type: "left" | "right") => {
        const allPoints = activeTab?.value;
        let reorderedPoints = allPoints.filter((p: IPoint, index: number) => index != currentIndex );
        const currentPoint = allPoints.find((p: IPoint, idx) => idx == currentIndex);
        if(type == "right" && currentPoint) {
            reorderedPoints.splice(currentIndex + 1, 0, currentPoint);
        }else if(type == "left" && currentPoint) {
            reorderedPoints.splice(currentIndex - 1, 0, currentPoint);
        }
        handleBarData(reorderedPoints);
    }
 
    return (
        <div
            style={{
                border: '1px solid #D9D9D9',
                backgroundColor: '#f3f5f7',
                padding: '8px',
                marginBottom: '16px',
                width: '100%',
                height: '400px',
                userSelect: 'none',
                fontSize,
            }}
            onMouseMove={(e) => {
                handleMouseMoveWithDrag(e)
                handleMouseMove(e)
            }}
            onMouseUp={handleDragEnd}
        >
            <ResponsiveBar
                theme={{
                    text: {fontSize},
                    axis: {
                        legend: {text: {fontSize, height: 50},}
                    }
                }}
                data={barData}
                keys={["y"]}
                indexBy="x"
                margin={{top: 60, right: 130, bottom: 70, left: 90}}
                valueScale={{ type: "linear" }}
                minValue={0}
                maxValue={maxYValue}
                colors="#3182CE"
                animate={true}
                isInteractive={true}
                axisTop= {{
                    tickSize: 20,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: template_response?.chartTitle,
                    legendPosition: "middle",
                    legendOffset: -40,
                    renderTick: (props) => <h2 className='text-blue-500'>{props.value}</h2>
                }}
                axisBottom={{
                    tickSize: 10,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: template_response?.xLabel || '',
                    legendPosition: 'middle',
                    legendOffset: 58,
                    renderTick: (props) => (
                        <g
                            onClick={() => {
                                const currentPoint = activeTab.value.find((point: IPoint) => point.x == props.value);
                                if (currentPoint && (!!layout?.edit_point || !!layout?.delete_point) ) {
                                    setHoveredBar(props?.value);
                                }
                            }}
                        >
                          <text
                            x={props.x}
                            y={props.y + 20}
                            textAnchor="middle"
                            dominantBaseline="middle"
                          >
                            {props.value}
                          </text>
                          {hoveredBar === props.value && (
                            <g transform={`translate(${props.x - 40}, ${props.y + 30})`}>
                                <rect
                                    width={ !!layout?.delete_point && !!layout?.edit_point ? "60" : "40"}
                                    height="30"
                                    fill="white"
                                    stroke="gray"
                                    strokeWidth="0.5"
                                    rx="5"
                                    ry="5"
                                />
                             {
                                !!layout?.edit_point && 
                                    <FaEdit
                                        color='gray'
                                        className={`cursor-pointer absolute hover:opacity-70 ${!!layout?.delete_point ? 'ml-5' : ""}`}
                                        x={15}
                                        y={5}
                                        onClick={() => handleEdit(props.value)}
                                    />
                             }
                            
                             {
                                !!layout?.delete_point && 
                                    <FaTrash
                                        color="red"
                                        className={`cursor-pointer absolute ml-5 hover:opacity-70 ${!!layout?.edit_point ? 'ml-5' : ""}`}
                                        x={!!layout?.edit_point ? 40 : 15}
                                        y={5}
                                        onClick={() => handleDelete(props.value)}
                                    />    
                             }

                                <g
                                    transform="translate(-10, -10)"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setHoveredBar(null);
                                        }}
                                    >
                                        <AiFillCloseCircle
                                            color="red"
                                            className=' cursor-pointer hover:opacity-70'
                                        />
                                </g>
                            </g>
                          )}
                        </g>
                      ),
                }}
                axisLeft={null}
                enableGridX={layout && layout?.show_gridlines ? ["both", "x_only"].includes(layout.show_gridlines) : false}
                enableGridY={layout && layout?.show_gridlines ? ["both", "y_only"].includes(layout.show_gridlines) : false}
                padding={0.4}
                layers={[
                    'grid',
                    'axes',
                    ({ bars }) => (
                        <g>
                            {bars.map((bar, index) => {
                                const bulletCount = Math.round(bar.data.value); // Number of bullets for this value
                                const startY = bar.y + bar.height - (bulletRadius * 2 + spacing) * bulletCount;
                                const pointId = bar?.data?.data?.id;

                                return (
                                    <g key={bar.key}>
                                        {[...Array(bulletCount)].map((_, i) => (
                                            <circle
                                                key={i}
                                                cx={bar.x + bar.width / 2}
                                                cy={startY + (bulletRadius * 2 + spacing) * i}
                                                r={bulletRadius}
                                                fill="#3182CE"
                                                onMouseDown={(event) => handleDragStart(event, index)}
                                                style={{ cursor: 'grab' }}
                                                onMouseEnter={() => {
                                                    if(layout?.order_point){
                                                        setHoveredPointId(pointId);
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if(hoveredPointId) setHoveredPointId(null);
                                                }}
                                            />
                                        ))}
                                        {bulletCount === 0 && (
                                            <circle
                                                cx={bar.x + bar.width / 2}
                                                cy={bar.y + bar.height - bulletRadius}
                                                r={bulletRadius}
                                                fill="rgba(0,0,0,0.1)"
                                                onMouseDown={(event) => handleDragStart(event, index)}
                                                style={{ cursor: 'grab' }}
                                                onMouseEnter={() => {
                                                    if(layout?.order_point){
                                                        setHoveredPointId(pointId);
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if(hoveredPointId) setHoveredPointId(null);
                                                }}
                                            />
                                        )}
 
                                        {
                                            draggedIndex == index &&
                                            <line
                                                x1={linePosition.x1}
                                                y1={linePosition.y1}
                                                x2={linePosition.x2}
                                                y2={linePosition.y2}
                                                stroke="black"
                                                strokeWidth="1"
                                                strokeDasharray={"5,5"}
                                            />
                                        }

                                        {
                                            !(layout && layout?.show_gridlines && ["both", "y_only"].includes(layout?.show_gridlines)) &&
                                            <line
                                                x1={0}
                                                y1={260}
                                                x2={300 * bars.length}
                                                y2={260}
                                                stroke="black"
                                                strokeWidth="1"
                                            />
                                        }

                                        {hoveredPointId === pointId && !!layout?.order_point && (
                                        <>
                                        {
                                            index != 0 && (
                                                <g 
                                                    transform={`translate(${bar.x + bar.width / 2 - 40}, ${220})`}
                                                >
                                                    <FaArrowCircleLeft 
                                                        color = "black"
                                                        size={24}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {handleReorderPoints(index, "left")}}
                                                    />
                                                </g>
                                            )
                                        }
                                        {
                                            index != bars.length - 1 && (
                                                <g 
                                                    transform={`translate(${bar.x + bar.width / 2 + 20}, ${220})`}
                                                >
                                                    <FaArrowCircleRight 
                                                        color = "black"
                                                        size={24}
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {handleReorderPoints(index, "right")}}
                                                    />
                                                </g>
                                            )
                                        }
                                    </>
                                )}
                                    </g>
                                );
                            })}
                        </g>
                    ),
                ]}
            />

            <ModalEditBarValue
                showModal={showModal}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleCancel={handleModalClose}
                handleSave={handleOkForEditBar}
            />
        </div>
    );
}