import React, { useState } from 'react';

interface ControlButton {
  label: string;
  icon: JSX.Element;
  action: () => void;
  isDisabled: boolean;
}

interface ControlsProps {
  buttons: ControlButton[];
}

const Controls: React.FC<ControlsProps> = ({ buttons }) => {
  // State to track which button is clicked
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const handleButtonClick = (index: number, action: () => void) => {
    setClickedIndex(index); // Update the clicked button index
    action(); // Execute the button's action
  };

  return (
    <div className="text-right ml-auto">
      <div className="flex space-x-2 justify-end">
        {buttons.map((button, index) => (
          <button
            key={index}
            type="button"
            className={`mr-2 flex items-center px-2 py-2 border rounded-md transition-colors duration-200 ${
              clickedIndex === index
                ? 'bg-[#fa9b31] text-white border-[#fa9b31] hover:bg-[#f4c38b]' // Styles for clicked button
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50' // Default styles
            } ${button.isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={button.isDisabled}
            onClick={() => handleButtonClick(index, button.action)}
          >
            {button.icon}
            <span className="ml-2">{button.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Controls;