import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const ImageEditorContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ececec;
  width: 100%;
  height: 200%;
`;

export const AlternateContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ResponseButton = styled.div<{ type?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--red, #de2128);
  height: 37px;
  padding: 10px 10px;
  flex-shrink: 0;
  margin: 10px 0;
  color: white;
  width: fit-content;
  cursor: pointer;
  span {
    margin: 0 5px;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e8e8;
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  color: ${(props) => (props.isActive ? '#DE2128' : '#2C2C2C')};
  border-bottom: ${(props) => (props.isActive ? '2px solid red' : 'none')};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const Token = styled.span<{ isSelected?: boolean }>`
  padding: 3px;
  line-height: 30px;
  font-size: 18px;
  ${(props) =>
    props.isSelected
        ? `
      background-color: #007bff;
      color: white;
      border-radius: 3px;
      margin: 3px;
    `
        : null}
  cursor: pointer;
`;

export const TokenCorrect = styled.span<{ isSelected: boolean, isClickable: boolean, fontSize: string,showAnswer:boolean,userSelected:boolean }>`
  padding: 3px;
  line-height: 30px;
  font-size: ${(props) => props?.fontSize ? props.fontSize : '12px'};
  line-height:${(props) => props?.fontSize && ["xx-large", "x-large"].includes(props?.fontSize) ? 
    '2.4rem' : 
    '1.5rem'
  };
  ${(props) =>
    Boolean(props.isSelected) === true && props.showAnswer &&  Boolean(props.userSelected) === false? `
      background-color: green;
      color:white;
      border-radius: 3px;
      border: 1px solid green;
      margin: 2px 1px;
      cursor: pointer;
//       ::after {
//   content: '✓';
//   color: green;
//   left: 5px; /* Position the checkmark to the right */
//   top: 80%;
//   transform: translateY(-50%); /* Vertically center the checkmark */
//   font-size: 16px; /* Adjust the size of the checkmark */
// };
      :hover{
        opacity: 0.7;
        border: none;
      };

    ` 
    :  Boolean(props.isSelected) === false && props.showAnswer && Boolean(props.userSelected) === true?`
    background-color: #ffff7b;
    border-radius: 3px;
    border: 1px solid red;
    margin: 2px 1px;
    cursor: pointer;
    ::after {
content: ' ☓';
color: red;
left: 5px; /* Position the checkmark to the right */
top: 80%;
transform: translateY(-50%); /* Vertically center the checkmark */
font-size: 16px; /* Adjust the size of the checkmark */
};
    :hover{
      opacity: 0.7;
      border: none;
    };

  ` 
  :  Boolean(props.isSelected) === true && props.showAnswer && Boolean(props.userSelected) === true?`
  background-color: #ffff7b;
  border-radius: 3px;
  border: 1px solid green;
  margin: 2px 1px;
  cursor: pointer;
    ::after {
  content: '✓';
  color: green;
  left: 5px; /* Position the checkmark to the right */
  top: 80%;
  transform: translateY(-50%); /* Vertically center the checkmark */
  font-size: 16px; /* Adjust the size of the checkmark */
};
  :hover{
    opacity: 0.7;
    border: none;
  };

` 
:Boolean(props.isSelected) === true && !props.showAnswer? `
    background-color: #ffff7b;
    border-radius: 3px;
    border: 1px solid #cacaa1;
    margin: 2px 1px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
      border: none;
    };
  ` 
  : Boolean(props.isClickable) === true ? `
      cursor: pointer;
      :hover{
        opacity: 0.7;
        border: 1px solid #cacaa1;
      };
    ` 
    : `
    cursor: default;
    background-color: none;
    border: none;
    :hover{
      opacity: 1;
      border: none;
    };
    `
  }
`;
