import React from 'react';
import { EditTokenTypes } from '../../Charts/SubChildren/components/chartquestion.constants';
import { LineSentence, LineWord, Paragraph, Sentence, Word } from "./tokenHiglights.types";
import './tokenHighlight.css';
import { TokenCorrect } from '../styled';


const EditCorrectAnswer = ({questionData, handleData, activeTab,showAnswer}) => {
    console.log('activeTabactiveTab',activeTab);
    
    const initial_template = questionData?.template_response?.initial_template || "";
    const token_type = questionData?.template_response?.token_type;
    const token_templates = activeTab?.value;
    const more_options = questionData?.more_options;
    const fontSize = more_options && more_options?.layout.fontSize ? 
        more_options.layout.fontSize  : 
        '12px';
    const maxNumberOfResponses = more_options && (!isNaN(Number(more_options?.layout?.max_selection)) && more_options?.layout?.max_selection > 0) ?
        Number(more_options?.layout?.max_selection) : 
        -1;

    const toggleParagraphCorrect = (lineId: Number) => {
        const totalNumberOfResponses = token_templates.paragraphs.filter((p: Paragraph) => p?.isCorrect).length;
        
        const newParagraphs = token_templates.paragraphs.map((p: Paragraph) => {
            if(p.lineId == lineId) {
                if(Boolean(p.isCorrect) === false && maxNumberOfResponses >= 0 && totalNumberOfResponses >= maxNumberOfResponses ){
                    return p 
                }else {
                    return {...p, isCorrect: !p.isCorrect}
                }
            } 
            else return p
        });
        handleData({...token_templates, paragraphs: newParagraphs})
    }
    
    const toggleSentenceCorrect = (lineId: Number, sentenceId: Number) => {
        let totalNumberOfResponses = 0;
        token_templates?.sentences.forEach((ls: LineSentence) => {
            ls.lineSentences.forEach((s: Sentence) => {
                if(Boolean(s?.isCorrect) == true){
                    totalNumberOfResponses += 1;
                }
            })
        })
        const newSentences = token_templates.sentences.map((ls: LineSentence) => {
            if(ls.lineId == lineId){
                return {
                    ...ls, lineSentences: ls.lineSentences.map((s: Sentence) => {
                        if(s.sentenceId == sentenceId) {
                            if(Boolean(s.isCorrect) === false && maxNumberOfResponses >= 0 && totalNumberOfResponses >= maxNumberOfResponses ) {
                                return s
                            }else{
                                return {
                                    ...s, isCorrect: !s.isCorrect,
                                }
                            } 
                        }
                        else return s
                    })
                }
            }else return ls
        });
        handleData ({...token_templates, sentences: newSentences})
    }
    
    const toggleWordCorrect = (lineId: Number, wordId: Number) => {
        let totalNumberOfResponses = 0;
        token_templates?.words.forEach((lw: LineWord) => {
            lw.lineWords.forEach((w: Word) => {
                if(Boolean(w?.isCorrect) == true){
                    totalNumberOfResponses += 1;
                }
            })
        });

        const newWords = token_templates.words.map((lw: LineWord) => {
            return lw.lineId == lineId ? {
                ...lw, lineWords: lw.lineWords.map((w: Word) => {
                    if(w.wordId == wordId){
                        if(Boolean(w.isCorrect) === false && maxNumberOfResponses >= 0 && totalNumberOfResponses >= maxNumberOfResponses ) {
                            return w
                        }else{
                            return {
                                ...w, isCorrect: !w.isCorrect,
                            }
                        } 
                    }else {
                        return w;
                    }
                })
            } : lw
        });
        handleData({...token_templates, words: newWords})
    }

    return (
        <div 
            className='my-4 rounded-sm bg-gray-200 p-2 overflow-x-scroll'
            style={{
                fontSize, 
                lineHeight: ["xx-large", "x-large"].includes(fontSize) ? '2rem' : '1.5rem',
            }}
        >
            {
                token_type === EditTokenTypes.None && (
                    <div 
                        className='px-2'
                        dangerouslySetInnerHTML={{__html: initial_template}}
                    />
                )
            }

            {
                token_type === EditTokenTypes.Paragraph &&
                Array.isArray(token_templates?.paragraphs) &&
                token_templates?.paragraphs.map((p : Paragraph) => {
                    return(
                        <React.Fragment
                            key={p.lineId.toString()}
                        >
                            <TokenCorrect
                                isSelected={p.isCorrect}
                                isClickable={p.isToken}
                                showAnswer={showAnswer}
                                userSelected={p?.userSelected}
                                fontSize={fontSize}
                                onClick={() => {
                                    if(Boolean(p.isToken) === true){
                                        toggleParagraphCorrect(p.lineId)
                                    }
                                }}
                            >
                                {p.lineValue}
                            </TokenCorrect>
                            <br />
                        </React.Fragment>
                    )
                })
            }

            {
                token_type === EditTokenTypes.Sentence &&
                Array.isArray(token_templates?.sentences) &&
                token_templates?.sentences.map((ls : LineSentence) => {
                    return(
                        <p
                            key={ls.lineId.toString()}
                            className='mb-2'
                        >
                           {
                            Array.isArray(ls?.lineSentences) && 
                            ls.lineSentences.map((s: Sentence) => {
                                return(
                                    <TokenCorrect
                                        fontSize={fontSize}
                                        key={s.sentenceId.toString()}
                                        showAnswer={showAnswer}
                                        isSelected={s.isCorrect}
                                        isClickable={s.isToken}
                                        userSelected={s.userSelected}
                                        className=' hover:opacity-40'
                                        onClick={() => {
                                            if(Boolean(s?.isToken) === true){
                                                toggleSentenceCorrect(ls.lineId, s.sentenceId)}
                                            }
                                        }
                                    >
                                        {s?.sentenceValue}
                                    </TokenCorrect>
                                )
                            })
                           } 
                        </p>
                    )
                })
            }

            {
                token_type === EditTokenTypes.Word &&
                Array.isArray(token_templates?.words) &&
                token_templates?.words.map((lw : LineWord) => {
                    return(
                        <p
                            key={lw.lineId.toString()}
                            className='mb-2'
                        >
                           {
                            Array.isArray(lw?.lineWords) && 
                            lw.lineWords.map((w: Word) => {
                                console.log('ww',w);
                                
                                return(
                                    <TokenCorrect
                                        fontSize={fontSize}
                                        showAnswer={showAnswer}
                                        key={w.wordId.toString()}
                                        isSelected={w.isCorrect}
                                        isClickable={w.isToken}
                                        userSelected={w.userSelected}
                                        className=' hover:opacity-40'
                                        onClick={() => {
                                            if(Boolean(w?.isToken) === true){
                                                toggleWordCorrect(lw.lineId, w.wordId)}
                                            }
                                        }
                                    >
                                        {w?.wordValue}
                                    </TokenCorrect>
                                )
                            })
                           } 
                        </p>
                    )
                })
            }
        </div>
    );
};

export default EditCorrectAnswer;
