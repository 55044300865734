import axios from 'axios';
const headers = {
  Accept: 'application/json'
};
export const getSubjectsRequest = async () => {
  let data: any = null;
  let error: any = null;

  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/subjects`, {headers})
    .then((body) => (data = body.data))
    .catch((err) => (error = err.response.data.error));

  return { data, error };
};

export const getSubjectByIdRequest = async (subjectId: string) => {
  let data: any = null;
  let error: any = null;

  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/subjects/${subjectId}`, {headers})
    .then((body) => (data = body.data))
    .catch((err) => (error = err.response.data.error));

  return { data, error };
};
