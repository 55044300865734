import { useEffect, useRef } from "react";
import {
  buttonsSettings,
  drawLine,
  drawParabola,
  drawPoint,
  drawRay,
  drawSegment,
  drawSine,
  drawVector,
  generateTwoPointsShape,
  shapesRenderer,
} from "utils/graph/shapeDrawerUtil";

const useBoard = (
  boardRef,
  graphSettings,
  optionItems,
  customGraphOptions,
  activeTabIndex,
  graphPoints,
  correctAnswer,
  userFinishedDrawing
) => {
  // console.log(boardRef, 'ref')
  const boardInstanceRef = useRef(null);

  useEffect(() => {
    if (boardInstanceRef.current && optionItems) {
      boardInstanceRef.current = null; // Reset the board instance
    }
    // console.log(graphSettings, " Graph Settings");
    if (boardRef.current && !boardInstanceRef.current) {
      const board = JXG.JSXGraph.initBoard(boardRef.current, {
        boundingbox: [
          optionItems["canvas"]["x_min"],
          parseFloat(optionItems["canvas"]["y_max"]),
          optionItems["canvas"]["x_max"],
          optionItems["canvas"]["y_min"],
        ],
        axis: false,
        keepaspectratio: false,
        showNavigation: false,
        showCopyright: false,
        pan: { enabled: true },
        showInfobox: graphSettings?.more_options?.layout?.show_on_hover,
      });
      boardInstanceRef.current = board; // Store the board instance in boardInstanceRef
      board.create("grid", [], {
        majorStep: [
          graphSettings?.more_options?.grid?.x_distance > 0
            ? graphSettings?.more_options?.grid?.x_distance
            : 1,
          graphSettings?.more_options?.grid?.y_distance > 0
            ? graphSettings?.more_options?.grid?.y_distance
            : 1,
        ],
      });
      board.create(
        "axis",
        [
          [0, 0],
          [1, 0],
        ],
        {
          name: graphSettings?.more_options?.axis_x?.axis_label,
          withLabel: graphSettings?.more_options?.axis_x?.show_axis_label,
          label: {
            offset: [
              -(graphSettings?.more_options?.axis_x?.axis_label?.length * 5) ||
                -10,
              20,
            ],
            distance: 0,
            position: ".95fr right",
          },
          ticks: {
            visible: !graphSettings?.more_options?.axis_x?.hide_tricks,
            majorHeight: 10,
            minorHeight: 5,
            minorTicks: 0,
            drawLabels: graphSettings?.more_options?.axis_x?.draw_labels,
            strokeColor: "black",
            insertTicks: false,
            label: {
              visible: true,
              offset: [0, -10],
            },
            ticksDistance:
              graphSettings?.more_options?.axis_x?.ticks_distance || 1,
          },
          lastArrow: graphSettings?.more_options?.axis_x?.max_arrow,
          firstArrow: graphSettings?.more_options?.axis_x?.min_arrow,
        }
      );

      // Create Y-axis
      board.create(
        "axis",
        [
          [0, 0],
          [0, 1],
        ],
        {
          withLabel: graphSettings?.more_options?.axis_y?.show_axis_label,
          name: graphSettings?.more_options?.axis_y?.axis_label,
          label: {
            position: "rt",
            offset: [10, 0],
          },
          ticks: {
            visible: !graphSettings?.more_options?.axis_y?.hide_tricks,
            majorHeight: 10,
            minorHeight: 5,
            minorTicks: 0,
            drawLabels: graphSettings?.more_options?.axis_y?.draw_labels,
            insertTicks: false,
            label: {
              visible: true,
              offset: [-25, 0],
            },
            ticksDistance:
              graphSettings?.more_options?.axis_y?.ticks_distance || 1,
            strokeColor: "black",
          },
          lastArrow: graphSettings?.more_options?.axis_y?.max_arrow,
          firstArrow: graphSettings?.more_options?.axis_y?.min_arrow,
        }
      );
      // console.log(customGraphOptions, " Custom Graph Options");
      if (customGraphOptions.length > 0) {
        // setGraphPoints([]);
        console.log(customGraphOptions, 'items')
        const filteredOptions = customGraphOptions.filter(
          (option) => option.tab === activeTabIndex
        ).filter((option) => !option.hasParent);
        if (filteredOptions.length > 0) {
          const options = { visibilityOnBoard: true };
          shapesRenderer(
            board,
            filteredOptions,
            true,
            graphPoints,
            "draw",
            graphSettings?.more_options?.layout?.snapto,
            options
          );
          board.update();
        }
      }
      if (graphSettings?.more_options?.background_shapes) {
        if (graphSettings?.more_options?.background_shapes.length > 0) {
          shapesRenderer(
            board,
            graphSettings?.more_options?.background_shapes,
            false,
            graphPoints,
            "shaded",
            graphSettings?.more_options?.layout?.snapto,
            {
              strokeColor: "gray",
              borders: {
                strokeColor: "gray",
              },
              draggable: false,
              fixed: true,
              visibilityOnBoard:
                graphSettings?.more_options?.background_image?.display_points,
            }
          );
          board.update();
        }
      }

      if (correctAnswer.length > 0) {
        const options = {visibilityOnBoard: true };
        shapesRenderer(
          board,
          correctAnswer,
          false,
          graphPoints,
          "preveiw",
          graphSettings?.more_options?.layout?.snapto,
          options
        );
      }
      // Add background image if specified
      if (
        graphSettings?.more_options?.background_image?.src !== "" ||
        graphSettings?.more_options?.background_image?.src !== ""
      ) {
        // console.log('Adding background image');
        const src = graphSettings?.more_options?.background_image?.src;
        const width = Number(
          graphSettings?.more_options?.background_image?.width || 100
        );
        const height = Number(
          graphSettings?.more_options?.background_image?.height || 100
        );
        const xMax = optionItems["canvas"]["x_max"];
        const yMax = optionItems["canvas"]["y_max"];
        const imgWidth = ((xMax + xMax) * width) / 100;
        const imgHeight = ((yMax + yMax) * height) / 100;
        const startxPos =
          imgWidth / 2 - graphSettings?.more_options?.background_image?.x || 0;
        const startyPos =
          imgHeight / 2 - graphSettings?.more_options?.background_image?.y || 0;

        board.create(
          "image",
          [src, [-startxPos, -startyPos], [imgWidth, imgHeight]],
          {
            opacity:
              graphSettings?.more_options?.background_image?.opacity / 100 || 1,
            fixed: true,
            draggable: false,
          }
        );
      }
    }
  }, [
    boardRef,
    graphSettings,
    optionItems,
    activeTabIndex,
    customGraphOptions,
    correctAnswer,
  ]); // Ensure dependencies are correctly set

  return boardInstanceRef.current; // Return the board instance
};

export default useBoard;
