import { createSlice } from '@reduxjs/toolkit';
import { ChartQuestionTypes, ChemistryTypes, DrawingTypes } from 'pages/AuthorQuestion/Charts/SubChildren/components/chartquestion.constants';

//  type: 'mcs';
//   question: ''; // text
//   options: {
//     // json
//   };
//   correct_answer: ''; // text
//   attachment_type: ''; // image,audio,video,document
//   attachement: ''; // image,audio,video,document
//   // layout: {},
//   points: '1'; // integer
//   settings: {
//     multiple_response: false;
//   };

interface IUpdateSubQuestion {
  question: string;
  options: [];
  correct_answer: [];
  points: number;
  settings: [];
  subQuestions: object;
}

const initialState: IUpdateSubQuestion = {
  question: '',
  options: [],
  correct_answer: [],
  points: 0,
  settings: [],
  subQuestions: {},
};

const EditSubQuestionSlice = createSlice({
  name: 'editSubQuestion',
  initialState,
  reducers: {
    editVal: (state, action) => {
      const { key, val } = action.payload;
      state[key] = val;
    },
    setOldData: (state, action) => {
      const data = action.payload;
      state.question = data?.question;
      state.options = data?.options;
      state.correct_answer = data?.correct_answer;
      state.points = data?.points;
      state.settings = data?.settings;
    },
    EditSubQues: (state, action) => {
      state = action.payload;
      return state;
    },
    setSubQuestionEditVal: (state, action) => {
      const { key, val } = action.payload;
      // Check if the 'subQuestions' object exists in the state
      /*if (!state.subQuestions) {
        state.subQuestions = initialState.subQuestions;
      }*/
      // Check if the 'key' exists in 'subQuestions'
      if (key) {
        // If 'key' does not exist, create a new object with the 'key' and 'subKey'
        state.subQuestions = { [key]: val };
      }
    },
    editSubQuestionKeyValue: (state, action) => {
      const { key, value } = action.payload;
      if (key === 'cwdad' || key === 'cwdd' || key === 'cwt') {
        state.subQuestions[key] = value;
      }
    },
    setEditKeyValue: (state, action) => {
      const { key, subKey, value } = action.payload;
      console.log(initialState.subQuestions,' Entering Key');
      console.log(key, value, " Key Value");
      let index = null;
      // Check if the 'subQuestions' object exists in the state
      if (!state.subQuestions) {
        state.subQuestions = initialState.subQuestions;
      }
      // Check if the 'key' exists in 'subQuestions'
      if (!state.subQuestions[key]) {
        // If 'key' does not exist, create a new object with the 'key' and 'subKey'
        state.subQuestions[key] = { [subKey]: value };
      }
      // Handle image annotation upload (imup) case
      if (key === 'imup') {
        // Ensure all necessary keys are initialized
        if (!state.subQuestions[key]) {
          state.subQuestions[key] = {
            type: key,
            question: "",
            image: {},
            correct_answer: {
              valid_response: {
                score: 0,
                value: [],
              },
            },
            more_options: {},
          };
        }

        // Update image-related properties
        if (['textHover', 'width', 'alt', 'source', 'height'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {};
          }
          state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
          };
        }
        // Update points or correct_answer properties
        else if (['points', 'correct_answer'].includes(subKey)) {
          // Initialize correct_answer and valid_response if not defined
          if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {
              valid_response: {
                score: 0,
                value: [],
              },
            };
          }

          if (!state.subQuestions[key].correct_answer.valid_response) {
            state.subQuestions[key].correct_answer.valid_response = {
              score: 0,
              value: [],
            };
          }

          if (subKey === 'points') {
            // Update points
            state.subQuestions[key].correct_answer.valid_response.score = value;
          } else if (subKey === 'correct_answer') {
            // Update correct_answer
            state.subQuestions[key].correct_answer.valid_response.value = value;
          }
        }
        // Update more_options without losing other keys
        else if (subKey === 'more_options') {
          if (!state.subQuestions[key].more_options) {
            state.subQuestions[key].more_options = {};
          }
          state.subQuestions[key].more_options = {
            ...state.subQuestions[key].more_options,
            ...value,
          };
        }
        // Handle direct updates to the main key
        else if (subKey === '') {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            ...value,
          };
        }
        // General case for other subKeys
        else {
          state.subQuestions[key][subKey] = value;
        }
        return;
      }
      if (
        key === ChemistryTypes.CHEM_FORMULA ||
        key === 'clozechemistry' ||
        key === 'ClozeMath' ||
        key === 'ClozeMathWImage'
      ) {
        state.subQuestions[key] = value;
      }
      // Add type "cwdad" when the key is "clozeDragDrop"
      if (key === 'cwdad') {
        state.subQuestions[key].type = 'cwdad';
        state.subQuestions[key][subKey] = value;
      }

      // Add type "cwdad" when the key is "clozeDropDown"
      if (key === 'cwdd') {
        state.subQuestions[key].type = 'cwdd';
        state.subQuestions[key][subKey] = value;
        if (subKey === 'options' ) {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            [subKey]: JSON.parse(value),
          };
        }
      }
      // Add type "cwdad" when the key is "clozeWithText"
      if (key === 'cwt') {
        state.subQuestions[key].type = 'cwt';
        state.subQuestions[key][subKey] = value;
      }
      if (key === 'draw') {
        state.subQuestions[key].type = 'draw';
        if (['source', 'width', 'height', 'alt'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {};
          }
          state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
          };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'liwdd') {
        state.subQuestions[key].type = 'liwdd';

        if (['textHover', 'width', 'alt', 'source', 'height'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else if (['duplicate'].includes(subKey)) {
          if (!state.subQuestions[key].settings) {
            state.subQuestions[key].settings = {
              [subKey]: value,
            };
          } else
            state.subQuestions[key].settings = {
              ...state.subQuestions[key].settings,
              [subKey]: value,
            };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'liwt' || key === 'liwddo') {
        //label image with text
        state.subQuestions[key].type = 'liwt';
        if (['textHover', 'width', 'alt', 'source', 'height'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else if (['duplicate', 'points'].includes(subKey)) {
          if (!state.subQuestions[key].settings) {
            state.subQuestions[key].settings = {
              [subKey]: value,
            };
          }
        }
        state.subQuestions[key][subKey] = value;
      }
      if (key === 'shad') {
        if (!state.subQuestions[key].type)
          state.subQuestions[key].type = 'shad';
        if (
            [
              'row_count',
              'column_count',
              'cell_width',
              'cell_height',
              'shaded',
              'canvas_container_style',
              'image',
              'title',
              'alt',
              'lock_shaded_cell',
              'hidden'
            ].includes(subKey)
        ) {
          if (!state.subQuestions[key].options) {
            state.subQuestions[key].options = {};
          }
          state.subQuestions[key].options = {
            ...state.subQuestions[key].options,
            [subKey]: value,
          };
        }
            //else if (['source', 'alt'].includes(subKey)) { //'title'
            /*if (!state.subQuestions[key].image) {
              state.subQuestions[key].image = {};
            }
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };*/
            //}
            //else if (['border'].includes(subKey)) {
            /*if (!state.subQuestions[key].canvas_container_style) {
              state.subQuestions[key].canvas_container_style = {};
            }
            state.subQuestions[key].canvas_container_style = {
              ...state.subQuestions[key].canvas_container_style,
              [subKey]: value,
            };*/
        //}
        else if (['correct_answer'].includes(subKey)) {
          if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {};
          }

          state.subQuestions[key].correct_answer = {
            ...state.subQuestions[key].correct_answer,
            valid_response: {
              ...state.subQuestions[key].correct_answer.valid_response,
              value: value,
              score: state.subQuestions[key].correct_answer.valid_response?.score || "0", // Retain the score if it exists, else default to "0"
            },
          };
        } else if (['points'].includes(subKey)) {
          if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {};
          }

          state.subQuestions[key].correct_answer = {
            ...state.subQuestions[key].correct_answer,
            valid_response: {
              ...state.subQuestions[key].correct_answer.valid_response,
              score: value,
              value: state.subQuestions[key].correct_answer.valid_response?.value || [], // Retain the value if it exists, else default to an empty array
            },
          };
        }
        else if(subKey == ''){
          state.subQuestions[key] = value;
        }
        else if(['more_options'].includes(subKey)){
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            more_options: value
          };
        }
        else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if([
        ChartQuestionTypes.BARCHART,
        ChartQuestionTypes.HISTOGRAM,
        ChartQuestionTypes.LINECHART,
        ChartQuestionTypes.DOTPLOT,
        ChartQuestionTypes.LINEPLOT,
        DrawingTypes.TOKEN_HIGHLIGHT,
      ].includes(key)) {
        if(subKey) {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            [subKey]: value,
          };
        }else {
          state.subQuestions[key] = {...state.subQuestions[key], ...value}
        }
      }

      if (['taf', 'mcs', 'mcmr', 'mcbl','cms', 'cmi', 'cml', 'cmsnl'].includes(key)) {
        state.subQuestions[key] = {
          ...state.subQuestions[key],
          [subKey]: value,
        };
      }
      console.log(key, "editKey")
      if (
        [
          'machli',
          'class',
          'ordlis',
          'sortlis',
          'graph'
        ].includes(key)
      ) {
        state.subQuestions = { ...state.subQuestions, [key]: value };
      }
      if (key === 'fileup') { // Others
       
        //File Upload Section
          state.subQuestions[key].type = 'fileup';
          state.subQuestions[key][subKey] = value;
          if (subKey === 'options' || subKey === 'settings') {
            state.subQuestions[key] = {
              ...state.subQuestions[key],
              [subKey]: value,
            };
          }
          console.log(state.subQuestions[key][subKey]);

      }
      if (key === 'rating') { // Others
        console.log(' Entering Key');
        console.log(key, value, " Key Value");
        //File Upload Section
          state.subQuestions[key].type = 'rating';
          state.subQuestions[key][subKey] = value;
          if (subKey === 'options' ) {
            state.subQuestions[key] = {
              ...state.subQuestions[key],
              [subKey]: value,
            };
          }
          console.log(state.subQuestions[key][subKey]);

      }
      if (key === 'grid') {
        if (!state.subQuestions[key]) {
          state.subQuestions[key] = value;
          return;
        }

        if (!subKey) {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            ...value
          };
          return;
        }

        if (subKey === 'correct_answer') {
          state.subQuestions[key].correct_answer = value;
        } else if (subKey === 'more_options') {
          state.subQuestions[key].more_options = {
            ...state.subQuestions[key].more_options,
            ...value
          };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
    },
    resetData: () => initialState,
  },
});

export const {
  editVal,
  setOldData,
  EditSubQues,
  setSubQuestionEditVal,
  resetData,
  setEditKeyValue,
  editSubQuestionKeyValue,
} = EditSubQuestionSlice.actions;

export default EditSubQuestionSlice.reducer;
